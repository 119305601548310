<template>
    <div class="container">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-12 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<span>Cadastramento e gerenciamento de planos</span>
					<h4 class="font-24 font-md-32 m-0">
						Gerenciar planos
					</h4>
				</div>
			</div>
            <div class="col-12 d-flex justify-content-center">
                <input type="text" 
                    class="col-6 form-control"
                    v-model="search"
                    placeholder="Pesquisa">
                <router-link
                    class="ml-5 btn btn-primary"
                    :to="{ name: 'NewPlan' }">
                    cadastrar
                </router-link>
            </div>
			<div class="col-12 col-md mb-4">
                <DataTables
                    :headers="headers"
					:search="search"
                    v-model="all_plans"
					:tr-button="true"
					@by-click-on-tr="Show"
                >
                    <template v-slot:body="{ item }">
						<td scope="col" class="align-middle">
							<b class="font-18">{{ item.name.toUpperCase() }}</b>
						</td>
						<td scope="col" class="align-middle text-center">
							<b v-if="item.total_price == 0"> GRATUITO </b>
							<b v-else>R$ {{ ParsePrice(item.total_price) }}</b>
						</td>
						<td scope="col" class="align-middle text-center">
							<b>{{ item.active_subscribers ? item.active_subscribers : '-' }}</b>
						</td>
						<td scope="col" class="align-middle">
							<span 
                                class="badge font-16 p-2"
                                :class="{
                                    'badge-success': 	item.public,
                                    'badge-danger': 	!item.public
                                }"
                            >
                                {{ item.public ? 'público' : 'privado'}}
                            </span>
						</td>
						<td scope="col" class="align-middle">
                            {{ ParseDate(item.created_at) }}
                        </td>
					</template>
                </DataTables>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import DataTables from '../shared/DataTables.vue'
const _ = require('lodash')
export default {
  components: { DataTables },
    name: 'Plans',
    props: {

    },
    data () {
        return {
            search:             '',
            all_plans:          [],
            headers:            [
                { text: 'Nome', value: 'name', sortable: true },
                { text: 'Preço', value: 'total_price', align: 'center', sortable: true },
                { text: 'Assinantes ativos', value: 'active_subscribers', align: 'center',  sortable: true },
                { text: 'Visibilidade', value: 'public', sortable: true },
                { text: 'Data', value: 'created_at', sortable: true }
            ]
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async GetPlans()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/gateway/get-plans')
                .then(({data}) => {
                    vm.all_plans = _.orderBy(data.plans, 'name','asc')
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        Show(plan)
        {
            this.$router.push({ name: 'EditPlan', params: {
                plan_id: plan.id
            }})
        },
        ParsePrice(price)
        {
            if(price)
            {
                let new_price = parseFloat(price).toFixed(2)
    
                return `${new_price.replace('.', ',')}`
            }
            return ''
        },
        ParseDate(date)
        {
            if(date)
            {
                let new_date = date.substr(0, 10).split('-').reverse().join('/')

                return new_date
            }
            return ''
        }
    },
    beforeMount()
    {
        this.GetPlans()
    }
}
</script>

<style>

</style>