<template>
    <div class="container">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-12 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<h4 class="font-24 font-md-32 m-0">
						Editar o cupom
					</h4>
				</div>
                <CouponForm 
                    v-model="coupon" 
                    mode="edit"
                    :deletable="true"
                    @update="UpdateCoupon"
                    @delete="DeleteCoupon"
                />
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'

import CouponForm from './Form'

import moment from 'moment'

import { mapActions } from 'vuex'

export default {
    name: 'Panel',
    components: {
        CouponForm
    },
    props: {
        coupon_id:    {
            type:       [Number, String],
            default:    null,
            required:   true
        }
    },
    data() {
        return {
            coupon:   { 
                not_due:    false
            },
            customers: []
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async GetCoupon()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', 'gateway/get-coupon', {
                coupon_id: vm.coupon_id
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.coupon             = { ...data.coupon }
                        return
                    }

                    Swal.fire({
                        text:       'Houve um erro na hora de importar o cupom!',
                        icon:       'error',
                        title:      'Ops...'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async UpdateCoupon(param)
        {
            const vm = this

            if(!param.coupon) return;
            vm.coupon = param.coupon

            vm.StartLoading()

            return await window.api.call('post', '/gateway/update-coupon', {
                ...vm.coupon
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            text:       'Cupom atualizado com sucesso!',
                            title:      ' ',
                            toast:      true,
                            position:   'top',
                            icon:       'success',
                            timer:      5000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'Coupons' })

                        return
                    }

                    Swal.fire({
                        text:       'Houve um erro ao atualizar o cupom!',
                        icon:       'error',
                        title:      'Ops...'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async DeleteCoupon(params)
        {
            Swal.fire({
                title:              'Excluir este cupom?',
                text:               'Isso não revogará o desconto de quem já utilizou o cupom. Nem mesmo os descontos para os próximos faturamentos.',
                confirmButtonColor: '#f64e60',
                confirmButtonText:  'Sim, deletar!',
                cancelButtonText:   'Não, cancelar',
                showCancelButton:   true,
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        const vm = this

                        vm.StartLoading()

                        return await window.api.call('post', 'gateway/del-coupon', {
                            coupon_id:    vm.coupon_id
                        })
                            .then(({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        icon:       'success',
                                        title:      'Cupom excluído com sucesso!',
                                        toast:      true,
                                        position:   'top',
                                        timer:      5000,
                                        timerProgressBar: true
                                    })

                                    vm.$router.push({ name: 'Coupons' })
                                    
                                    return
                                }

                                Swal.fire({
                                    icon:       'error',
                                    title:      'Ops...',
                                    text:       'Houve um erro ao deletar o cupom!',
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        },
        ParseRandomCode(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
            }
            this.coupon.code = result.join('')
        }
    },
    beforeMount()
    {
        this.GetCoupon()
    }
}
</script>

<style lang="css" scoped>

textarea.description
{
    resize: none;
}

.new-benefit
{
    font-weight: 700;
}

a
{
    cursor: pointer;
}

a.trash-button.off
{
    display: none;
}

a.trash-button.on
{
    position: absolute;
    right: -1.5rem;
    top: 0.9rem;
}

i.fa.fa-times {
    font-size: 23px;
    font-weight: 400 !important;
    line-height: 1rem;
}


</style>