<template>
    <div>
        <transition name="fade" mode="out-in">
            <div class="col-12 d-flex"
                :class="form.subscriptions ? 'justify-content-around' : 'justify-content-between'">
                <div v-if="form.subscriptions"
                    class="col-3 d-flex flex-column align-items-center">
                    <h3 class="my-5 text-center">ASSINANTES</h3>
                    <div class="col-12">
						<div class="form-group position-relative mb-0 d-flex justify-content-center">
							<input 
								type="search" 
								placeholder="Buscar..." 
								class="form-control border-no pl-5"
								v-model="search"
							>
							<i class="far fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center"></i>
							<button class="btn btn-outline-danger btn-sm my-2 p-1"
                                @click="show_subscriptions == 'active' ? show_subscriptions = 'canceled' : show_subscriptions = 'active'"
                            >
                                <i class="fal fa-file-times font-32 mx-2"></i>
                            </button>
						</div>
						<div id="subscribers-card" 
                            class="card bg-light border-no overflow-hidden">
							<transition name="fade" mode="out-in">
								<div 
									class="card-body px-4 pb-3 pt-3"
								>
									<template v-if="filtered_subscriptions.length > 0">
										<div 
											v-for="subscriber in filtered_subscriptions"
											:key="subscriber.id"			
										>
                                            <div class="form-row subscriber-item m-0">
                                                <span  v-tippy="{ arrow: true }"
                                                    :content="subscriber.status == 'active' 
                                                    ? JSON.parse(subscriber.customer.vindi_json_data).name +` está assinando este plano.` 
                                                    : JSON.parse(subscriber.customer.vindi_json_data).name + ` cancelou este plano.`"
                                                >
                                                    <b>{{ JSON.parse(subscriber.customer.vindi_json_data).name }}</b> - 
                                                    <b :class="subscriber.status == 'active' ? 'text-success' : 'text-danger'">
                                                        {{ subscriber.status == 'active' ? 'A' : 'C' }}
                                                    </b>
                                                </span>
										    </div>
										</div>
									</template>
									<template v-else-if="show_subscriptions == 'canceled'">
										<div class="select-card with-border mt-3 d-flex justify-content-center">
											<p class="">
												Não há nenhum cancelamento.
											</p>											
										</div>
									</template>
									<template v-else>
										<div class="select-card with-border mt-3 d-flex justify-content-center">
											<label v-if="search">
												Não há resultados para "{{ search }}"
											</label>											
											<p class="">
												Não há nenhuma assinatura ativa.
											</p>											
										</div>
									</template>
								</div>
							</transition>
						</div>
					</div>
                </div>

                <!-- FORM -->
                <div class="d-flex flex-column align-items-center"
                    :class="form.subscriptions ? 'col-5' : 'col-8 offset-2'">
                    <div class="form col-10 d-flex flex-column mt-5"
                        ref="form">
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="name" class="font-20 col-4">Nome do plano:</label>
                                <input 
                                    type="text"
                                    id="name"
                                    ref="name"
                                    class="form-control col-8"
                                    :class="{ 'is-invalid': $v.form.name.$error }"
                                    v-model="$v.form.name.$model"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="price" class="font-20 col-4">Preço:</label>
                                <div class="input-group col-8 p-0">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">R$</span>
                                    </div>
                                    <money
                                        id="price"
                                        ref="price"
                                        class="form-control"
                                        :class="{ 'is-invalid': $v.form.total_price.$error }"
                                        v-model="$v.form.total_price.$model"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="recurring_accesses" class="font-20 col-4">Usuários recorrentes:</label>
                                <input 
                                    type="number"
                                    id="recurring_accesses"
                                    ref="recurring_accesses"
                                    class="form-control col-8"
                                    :class="{ 'is-invalid': $v.form.recurring_accesses.$error }"
                                    v-model="$v.form.recurring_accesses.$model"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="description" class="font-20 col-4">Descrição:</label>
                                <textarea 
                                    id="description"
                                    ref="description"
                                    class="form-control textarea-description"
                                    :class="{ 'is-invalid': $v.form.description.$error }"
                                    v-model="$v.form.description.$model"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label class="font-20 col-4" for="days">Periodicidade</label>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input d-none"
                                        name="periodicity"
                                        type="radio"
                                        id="days"
                                        value="days"
                                        v-model="form.interval">
                                    <label for="days" class="mr-4">Dia</label>
                                </div>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input d-none"
                                        name="periodicity"
                                        type="radio"
                                        id="months"
                                        value="months" 
                                        v-model="form.interval">
                                    <label for="months">Mês</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="interval_count" class="font-20 col-4">Intervalo:</label>
                                <input 
                                    type="number"
                                    id="interval_count"
                                    ref="interval_count"
                                    class="form-control col-8"
                                    :class="{ 'is-invalid': $v.form.interval_count.$error }"
                                    v-model="$v.form.interval_count.$model"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="billing_cycles" class="font-20 col-4">Ciclos:</label>
                                <input 
                                    type="number"
                                    id="billing_cycles"
                                    ref="billing_cycles"
                                    class="form-control col-8"
                                    :class="{ 'is-invalid': $v.form.billing_cycles.$error }"
                                    v-model="$v.form.billing_cycles.$model"
                                >
                            </div>
                        </div>
                        <div class="row"
                            v-if="form.total_price == 0">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="max_subscriptions" class="font-20 col-4">Limite assinatura:</label>
                                <input 
                                    type="number"
                                    id="max_subscriptions"
                                    ref="max_subscriptions"
                                    class="form-control col-8"
                                    :class="{ 'is-invalid': $v.form.max_subscriptions.$error }"
                                    v-model="$v.form.max_subscriptions.$model"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label class="font-20 col-4" for="public">Visibilidade</label>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input d-none"
                                        name="visibility"
                                        type="radio"
                                        id="public"
                                        :value="true"
                                        v-model="form.public">
                                    <label for="public" class="mr-4">Público</label>
                                </div>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input d-none"
                                        name="visibility"
                                        type="radio"
                                        id="private"
                                        :value="false" 
                                        v-model="form.public">
                                    <label for="private">Privado</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center justify-content-end col-12 flex-wrap"
                                ref="benefits_content"
                                id="benefits_content">
                                <label for="benefits" class="font-20 col-4">Benefícios:</label>
                                <div 
                                    v-for="(benefit, index) in benefits"
                                    :key="`benefit-${index}`"
                                    :id="`benefit-${index}`"
                                    class="p-0 col-8 mb-3 d-flex justify-content-center input-group  position-relative">
                                    <div class="input-group-prepend p-0 d-flex">
                                        <button class="btn"
                                            :class="benefit.have ? 'btn-outline-success' : 'btn-outline-danger'"
                                            @click="benefit.have = !benefit.have">
                                            <i class="fa"
                                                :class="benefit.have ? 'fa-check' : 'fa-times'"></i>
                                        </button>
                                    </div>
                                    <input 
                                        type="text"
                                        class="benefit form-control col-12"
                                        v-model="benefit.text"
                                    >
                                    <a class="trash-button off font-20"
                                        @click="RemoveBenefit(index, benefits)">
                                        <i class="fa fa-trash text-danger"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <a class="font-20 text-primary new-benefit"
                                    @click="AddBenefit(benefits)">
                                    <i class="fa fa-plus"></i>
                                    Novo benefício
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group mt-5 ml-3 mr-5 d-flex align-items-center col-12"
                                :class="mode === 'edit' ? 'justify-content-between' : 'justify-content-end'">
                                <button
                                    v-if="mode === 'edit'"
                                    class="btn btn-danger"
                                    @click="DeletePlan()">
                                    deletar
                                </button>
                                <button
                                    class="btn btn-primary"
                                    @click="SavePlan()">{{ mode == "edit" ? 'atualizar' : 'cadastrar' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            <!-- PLAN EXAMPLE -->
                <div class="form-example"
                    :class="form.subscriptions ? 'col-3' : 'col-4 offset-1'">
                    <h3 class="my-5 text-center">RESULTADO</h3>
                    <div class="card-form mt-5">
                        <div class="card-form-content">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="text-center text-uppercase">{{ form.name ? form.name : 'Básico' }}</h4>
                                </div>
                                <div class="col-12 text-center position-relative">
                                    <div class="d-flex card-price flex-row justify-content-center"
                                        v-if="form.total_price">
                                        <div class="currency">R$</div>
                                        <div class="amount">{{ ParsePrice(form.total_price) }}</div>
                                        <div class="per-month">/mês</div>
                                    </div>
                                    <div class="d-flex card-price flex-row justify-content-center"
                                        v-else>
                                        <div class="amount">GRATUITO</div>
                                    </div>
                                    <hr class="my-4"/>
                                </div>
                                <div class="col-12 px-4">
                                    <p class="text-left">
                                        <i class="far text-primary mr-1"
                                        :class="form.recurring_accesses > 1 ? 'fa-users' : 'fa-user'"></i> {{ form.recurring_accesses ? form.recurring_accesses > 8 ? 'permite' : 'somente' : 'somente'}} <b>{{ form.recurring_accesses ? form.recurring_accesses : 3 }} usuário{{ form.recurring_accesses ? form.recurring_accesses > 1 ? 's' : '' : 's'}} </b> por vez
                                    </p>
                                    <p class="text-left description-content">
                                        {{ form.description ? form.description : 'Aqui ficaria a descrição do plano' }}
                                    </p>
                                    <div v-if="benefits[0].text" class="benefits-content">
                                        <p class="text-left"
                                            v-for="(benefit, index) in benefits" :key="index"
                                            :class="benefit.have ? benefit.have : 'mr-0'">
                                            <i class="fas mr-1"
                                                :class="benefit.have ? 'fa-check text-success' : 'fa-times text-danger mr-2'"></i>{{ benefit.text }}
                                        </p>
                                    </div>
                                    <div v-else class="benefits-content">
                                        <p class="text-left">
                                            <i class="fas fa-check text-success mr-1"></i>Tem tal vantagem
                                        </p>
                                        <p class="text-left">
                                            <i class="fas fa-times text-danger mr-2"></i>Não tem outra vantagem
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import Swal from 'sweetalert2'
const _ = require('lodash')

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'PlanForm',
    props:  {
        value:      {
            type:       Object,
            default:    () => {}
        },
        mode:       {
            type:       String,
            default:    ''
        }
    },
    data() {
        return {
            search:                 '',
            show_subscriptions:     'active',
            form:   {
                name:               "",
                recurring_accesses: null,
                public:             true,
                interval:           'months',
                total_price:        0,
                interval_count:     1,
                billing_cycles:     12,
                max_subscriptions:  0,
                description:        "",
            },
            benefits:           [
                { text: "", have: true }
            ]
        }
    },
    validations: {
        form: 		{
            name: 		        {
                required
            },
            total_price:        {
                required
            },
            description:        {
                required
            },
            recurring_accesses: {
                required
            },
            interval_count:     {
                required
            },
            billing_cycles:     {
                required
            },
            max_subscriptions:  {
                requiredIf: requiredIf(function (form) {
                    return form.total_price == 0
                })
            },
		}
    },
    computed: {
        filtered_subscriptions()
        {  
            let subscriptions = _.orderBy(this.form.subscriptions, 'status', 'asc')
            if(this.show_subscriptions)
            {
                return subscriptions.filter(e => e.status == this.show_subscriptions)
            }

            if(this.search)
            {
                return subscriptions.filter(e => {
                    let id = `${e.id}`
                    return id.includes(this.search)
                })
            }

            return subscriptions
        }
    },
    watch:  {
        value: {
            handler(val)
            {
                this.form = val
                this.benefits = val.benefits
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        async SavePlan()
        {
            this.$v.form.$touch()

            if(!this.$v.form.$invalid)
            {
                this.form.benefits = this.benefits
                this.form.max_subscriptions = parseInt(this.form.max_subscriptions)
                this.$emit('save', {
                    plan: this.form
                })
            } else {
                window.helpers.focusInvalid(this.$v.form, this)
            }
        },
        async DeletePlan()
        {
            this.$emit('delete')
        },
        async AddBenefit(fieldType)
        {
            await fieldType.push({ text: "", have: false })

            const divs = this.$refs.benefits_content.childNodes

            divs.forEach((el, i) => {
                const children = el.childNodes
                if(i > 1)
                {
                    const a = [...children].forEach((c) => {
                        if(c.classList && c.classList.contains("off"))
                        {
                            c.classList.remove('off')
                            c.classList.add('on')
                        }
                    })
                }
            })
        },
        RemoveBenefit(index, fieldType) {
            fieldType.splice(index, 1);
        },
        ParsePrice(price)
        {
            return `${price.toFixed(2)}`.replace('.', ',')
        },
    },
    beforeMount() {
    }
}
</script>

<style lang="css" scoped>

#subscribers-card
{
    height: 300px;
}

.subscriber-item
{
    color: #000;
}

.textarea-description
{
    height: 100px;
    resize: none;
}

.new-benefit
{
    font-weight: 700;
}

a
{
    cursor: pointer;
}

a.trash-button.off
{
    display: none;
}

a.trash-button.on
{
    position: absolute;
    right: -1.5rem;
    top: 0.9rem;
}

i.fa.fa-times {
    font-size: 23px;
    font-weight: 400 !important;
    line-height: 1rem;
}

/* a.btn.btn-light.previous-button {
    position: absolute;
    top: -50px;
    left: -100px;
} */

</style>