<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex justify-content-center">
            <h2>Gerenciamento de seções</h2>
        </div>
        <div class="col-8 mt-4">
            <div class="col-12 d-flex justify-content-center">
                <input type="text" 
                    class="col-6 form-control"
                    v-model="search"
                    placeholder="Pesquisa">
                <router-link
                    class="ml-5 btn btn-primary"
                    :to="{ name: 'NewSection' }">
                    cadastrar
                </router-link>
            </div>
        </div>
        <div class="col-6 my-5">
            <DataTables
                v-model="sections"
                :headers="headers"
                :search="search"
                :tr-button="true"
                max_height="700px"
                @by-click-on-tr="Show"
                >

                <template v-slot:body="{ item }">
                    <td>
                        <b class="font-18">{{ item.descricao }}</b>
                    </td>
                    <td>
                        <p class="font-18">{{ ParseCategory(item.faq_category_id) }}</p>
                    </td>
                    <td>
                        {{ ParseDate(item.created_at) }}
                    </td>
                </template>
            </DataTables>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTables from './../../shared/DataTables'

const _ = require('lodash')

export default {
    name: "ManageSection",
    components: {
        DataTables
    },
    data () {
        return {
            sections:       [],
            headers:        [
                { text: 'Descricao', value: 'descricao', sortable: true },
                { text: 'Categoria', value: 'faq_category_id', sortable: true },
                { text: 'Data', value: 'created_at', sortable: true },
            ],
            search:         ""
        }
    },
    computed: {
        ...mapGetters('system', [
            'all_sections', 'all_categories'
        ])
    },
    watch: {
        all_sections: {
            immediate: true,
            deep: true,
            handler(val)
            {
                this.sections = _.orderBy(val, 'id', 'asc')
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'GetFaqItems'
        ]),
        Show(section)
        {
            this.$router.push({ name: 'EditSection', params: {
                section_id: section.id
            }})
        },
        ParseDate(date)
        {
            if(date)
            {
                let new_date = date.substr(0, 10).split('-').reverse().join('/')

                return new_date
            }
            return ''
        },
        ParseCategory(id)
        {
            return this.all_categories.filter(c => c.id === id)[0].descricao
        }
    },
    beforeMount()
    {
        this.GetFaqItems()
    }
}
</script>

<style>

</style>