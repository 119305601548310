<template>
	<div class="row">
		<div class="col-12 d-flex justify-content-end">
			<button type="button" name="" id="" class="btn btn-outline-success py-2 px-3"
				@click="GetFinancialProfile()"
			>
				<i class="fal fa-sync font-24"></i>
			</button>
		</div>
		<div class="row" v-if="financial_profile">
			<div class="col-12 mb-4 d-flex justify-content-center">
				<div class="card rounded-lg b-2 mb-3 col-5 mx-3 p-0">
					<div class="px-4 py-3">
						<div class=""> 
							<h5>
								Assinatura
								<small class="text-muted font" v-if="financial_profile.subscription" >
									#{{ financial_profile.subscription.vindi_subscription_id }}
								</small>
							</h5>
						</div>
						<div
							v-if="financial_profile.subscription"
							class=""
						>
							<div class="col">
								<p class="mb-0">
									<strong>Plano assinado: </strong>
									<router-link 
										:to="{ 
												name: 'EditPlan', 
												params: { 
													plan_id: financial_profile.subscription.plan.id 
												}
											}"
									>	
										{{ financial_profile.subscription.plan.name }} 
										<i class="fa fa-external-link" aria-hidden="true"></i>	
									</router-link>
								</p>
								<p class="mb-0">
									<strong>Próxima fatura: </strong>
									{{ ParseDate(financial_profile.subscription.next_billing_at) }}
								</p>
								<p class="mb-0">
									<strong>Desde: </strong>
									{{ ParseDate(financial_profile.created_at) }}
								</p>
								<p>
									<strong>Ciclos </strong>
									{{ financial_profile.subscription.bills.length }}/{{ financial_profile.subscription.plan.billing_cycles }}
								</p>
							</div>
						</div>
						<div
							v-else
							class="col"
						>
							<p class="m-0 text-center p-3">Nenhuma assinatura ativa</p>
						</div>
						<div class="d-flex justify-content-between">
							<div class="">
								<button type="button" class="btn btn-info p-3 px-4" data-toggle="modal" data-target="#modal-new-subscription">
									nova assinatura
								</button>
							</div>
							<div class="" v-if="financial_profile.subscription">
								<button 
									type="button" 
									class="btn btn-danger p-3 px-4"
									@click="CancelSubscription()"
								>
									cancelar
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card rounded-lg b-2 mb-3 col-5 mx-3 p-0">
					<div class="h-100 px-4 py-3 d-flex flex-column">
						<div class="text-muted">
							<h5>Perfil de pagamento</h5>
						</div>
						<div class="h-100 d-flex justify-content-center">
							<div class="d-flex align-items-center" v-if="financial_profile.payment_profile">
								<div class="col-8">
									<div class="text-right">
										<span class="font-16"><b>Cartão de Crédito</b></span><br> 
										{{ financial_profile.payment_profile.card_number_first_six.substring(0, 4) }} **** **** {{ financial_profile.payment_profile.card_number_last_four }} <br>
										venc. {{ ParseDate(financial_profile.payment_profile.card_expiration) }}
									</div>
								</div>
								<div class="col-auto">
									<button type="button" class="btn btn-danger btn-sm px-4 mx-2" @click="DeletePaymentProfile()">
										<i class="fal fa-trash font-20"></i>
									</button>
								</div>
							</div>
							<div class="col" v-else>
								<p class="m-0 text-center pt-5">Nenhum perfil de pagamento cadastrado</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-md-auto mt-md-0 mt-4 text-dark">
				
				<p class="text-dark mb-0">
					<b>Acessos recorrentes</b>
				</p>
				<template v-if="financial_profile && financial_profile.recurring_accesse">
					<div class="card-body pl-0">
						<div class="form-row align-items-center">

							<div class="col-2">
								<input 
									type="text" 
									class="form-control"
									v-model="new_recurring_accesse.recurring_accesses"
									v-mask="['###']"
								>
							</div>
							<div class="col-5">
								<tippy content="Incompativel se estiver usando Firefox/Internet Explorer" arrow>
									<input 
										type="datetime-local" 
										class="form-control"
										v-model="new_recurring_accesse.due_at"
									>
								</tippy>
							</div>
							<div class="col-2">
								<button type="button" 
									class="btn btn-outline-success py-2 px-3"
									style="border: none;"
									@click="UpdateRecurringAccess()"
								>
									<i class="fal fa-sync font-18"></i>
								</button>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="card rounded-lg mb-3">
						<div class="px-4 py-3">
							<div class="row">
								<div class="col">
									<p class="m-0"> Não foi possível consultar os acessos recorrentes deste usuário</p>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			
			<div class="col-12 col-md-auto mt-md-0 mt-4 text-dark">
				
				<p class="text-dark mb-0">
					<b>Registro de atividades</b>
				</p>
				<template v-if="financial_profile && financial_profile.recurring_accesse">
					<div class="card-body pl-0">
						<div class="form-row align-items-center">
							<button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target="#modalActivityLogs">Abrir histórico</button>
						</div>
					</div>
					
					<!-- MODAL HISTÓRICO -->
					<div class="modal fade" id="modalActivityLogs">
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
								<div class="modal-header px-5 py-4">
									<h4 class="font-20 m-0">Registro de atividades</h4>
								</div>
								<div class="modal-body px-4 pb-4">
									<div class="col-12 d-flex form-group">
										<input 
											type="text"
											class="form-control font-16"
											v-model="search"
											placeholder="Pesquisa de logs"
										>
									</div>
									<div
										v-for="activity in filtered_logs"
										:key="activity.id"
									>
										<div class="mb-0">
											<b>{{ activity.parse_date }}</b> - {{ activity.action }}
										</div>
										
									</div>

								</div>
								<div class="modal-footer">
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="card rounded-lg mb-3">
						<div class="px-4 py-3">
							<div class="row">
								<div class="col">
									<p class="m-0"> Não foi possível consultar o registro de atividades.</p>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			
			<div class="col-12 mt-4">
				<div class="mb-2 d-flex justify-content-between align-items-baseline">
					<p class="text-dark">
						<b>Faturas</b>
					</p>
					<button type="button" class="btn btn-info p-3 px-4">+fatura avulsa</button>
				</div>

				<template v-if="financial_profile.bills.length > 0">
					<div 
						class="row mb-3"
						v-for="bill in financial_profile.bills"
						:key="bill.id"
					>
						<div class="col-12">
							<div class="card rounded-lg">
								<div class="px-4 py-3">
									<div class="form-row align-items-center">
										<div class="col col-md-auto">
											<div>
												<b class="text-dark">{{ bill.period && bill.period.end_at ? ParseDate(bill.period.end_at) : null }}</b>
												<p class="">
													<small><a :href="bill.url" target="_blank">fatura #{{ bill.id }}</a></small>
												</p>	
											</div>

											<div>	
												<a class="btn btn-light px-2 py-1 m-0" 
													data-toggle="collapse" 
													:href="'#collapse-bill-full-info-' + bill.id">
													<i class="fa fa-arrow-down" aria-hidden="true"></i>
												</a>
											</div>
										</div>
										
										<div class="col-auto col-md d-flex flex-column">
											<p v-if="bill.due_at" class="ml-4">
												Vencimento: {{ ParseDate(bill.due_at) }}
											</p>
											<p class="m-0 font-20 text-dark mx-4">
												<b>R${{ ParsePrice(bill.amount) }}</b>
											</p>
										</div>
										<div class="col-12 col-md-auto my-3 my-md-0 text-center">
											<div class="font-14 badge badge-info mr-2"
												v-if="! bill.subscription_id" >
												FATURA AVULSA
											</div>
											
											<div class="font-14"
												:class="bill.bill_class">
											{{ bill.bill_status }}
											</div>
										</div>
										<div class="col-12 col-md-auto text-center">
											<button 
												class="btn btn-light-primary btn-sm"
												
												v-tippy='{ 
													arrow : true 
												}'
												:content="'nfs-e indisponível para a fatura' + bill.id" 
											>
												download nfs-e
											</button>
										</div>
									</div>

									<div class="form-row align-items-center">
										<div class="collapse" 
											:id="'collapse-bill-full-info-' + bill.id">
											<hr>
			
											<div class="mx-4 my-2 " v-html="GetBillDetails(bill)" >
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="card rounded-lg mb-3">
						<div class="px-4 py-3">
							<div class="row">
								<div class="col">
									<p class="m-0 text-center"> Não há faturas para exibir.</p>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>

			<!-- MODAL PARA NOVA ASSINATURA --> 
			<div class="modal fade" id="modal-new-subscription" >
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header px-5 py-4">
							<h4 class="font-20 m-0">Assinar plano</h4>
						</div>
						<div class="modal-body">
							<div class="px-4 pb-4">
								<div class="form-group">
									<label for="new_subscription_plan">Escolha um plano:</label>
									<select
										class="form-control" 
										name="new_subscription_plan" 
										id="new_subscription_plan"
										v-model="$v.new_subscription.plan_id.$model"
										:class="{ 'is-invalid': $v.new_subscription.plan_id.$error }"
									>
										<option :value="null" selected disable >Escolha um plano</option>
										<option 
											v-for="plan in all_plans"
											:key="plan.id"
											:value="plan.id"
										>
											{{ plan.public ? 'PUB' : 'PRIV' }} - {{ plan.name }} - R$ {{ plan.total_price }}
										</option>
									</select>
								</div>
								<div class="form-group">
									<label for="new_subscription_coupon">Cupom de desconto?</label>
									<input type="text"
										class="form-control" 
										id="new_subscription_coupon" 
										placeholder="Código do cupom (caso haja)"
										v-model="new_subscription.coupon_code"
                                        v-mask="['XXXXXXXXXXXXXXXXXXXX']"
										:disabled='!new_subscription.ignore_remaining_time_discount && financial_profile.remaining_time.response'
									>
								</div>
								<div class="form-check" v-if="financial_profile.remaining_time.response">
									<div class="d-inline-block form-group mr-4 mb-0 form-check">
										<input 
											type="checkbox" 
											id="ignore_remaining_time_discount" 
											checked
											v-model="new_subscription.ignore_remaining_time_discount"
											@change="IgnoreRemainingTimeDiscountCheck()"
										>
										<label for="ignore_remaining_time_discount">
											Ignorar o desconto proporcional por tempo de não uso?
										</label>
									</div>
								</div>
								<div class="mt-3">
									<p class="text-danger mb-0" v-if="financial_profile.subscription">
										A assinatura atual "{{ financial_profile.subscription.plan.name }}" será cancelada!
									</p>
									<p class="text-danger mb-0" v-if="financial_profile.remaining_time.response && !new_subscription.ignore_remaining_time_discount">
										O cliente utilizou por {{ financial_profile.remaining_time.remaining_time.used_days }} dia(s) e terá 
										um desconto de <b>R$ {{ financial_profile.remaining_time.remaining_time.total_discount }}</b>
									</p>
								</div>
							</div>
						
							<div class="d-flex justify-content-between px-4 pb-4">
								<button
									type="button" 
									class="btn btn-light p-3 px-4" 
									data-dismiss="modal"
								>	
									cancelar
								</button>
								<button
									type="button" 
									class="btn btn-info p-3 px-4"
									@click="StoreNewSubscription()"
								>	
									assinar
								</button>
							</div>					  
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="col-12 mt-2" v-else>
			<p class="text-dark">
				<b>Informações:</b>
			</p>
			
			<div class="col-12 mt-5">
				<p class="m-0 text-center text-danger">{{ load_message_error }}</p>
			</div>
		</div>
	</div>

</template>

<script>
	import Swal from 'sweetalert2'
	import moment from 'moment'
	import { mapActions, mapGetters } from 'vuex'
	import { tippy } from "vue-tippy";
	
	const _ 		= require('lodash')
	
	const dateParser = (x) => { return moment(x).format('D/MM/Y H:m'); } 

	const planSelectedValidation = (value) => {
		if (value == null) return false
		return true
	} 

	export default {

		name: 'Financial',
		components: {
			
		},
		props: 	{
			organization_id: 	{
				type: 		[Number, String],
				default: 	null,
				required: 	true
			},
			providers: 			{
				type: 				Array,
				default: 			() => []
			},
			is_registered:		{
				type:				Boolean,
				default:			false
			}
		},
		data () {
			return {
				search:					'',
				default_avatar: 		window.defaultAvatar,
				fees: 					[],
				edit:					false,
				modal_opened:			false,
				all_plans: 				[],
				mode:					'new',
				load_message_error:		'O perfil financeiro desta organização não pôde ser encontrado.',
				financial_profile: 		null,
				new_subscription:		{
					plan_id: 						null,
					ignore_remaining_time_discount: false,
					coupon_code:					null
				},
				new_recurring_accesse:	{
					recurring_accesses: 0,
					due_at: 			null,
				}
			}
		},
		validations: {
			new_subscription: 		{
				plan_id: 		        {
					planSelectedValidation
				}
			}
		},
		computed: {
			responsibles()
			{
				let responsibles = this.providers.filter( p => p.responsible )
				return _.orderBy(responsibles, [(r) => r.provider.name], 'asc')
			},
			non_responsibles()
			{
				let non_responsibles = this.providers.filter( p => !p.responsible )
				return _.orderBy(non_responsibles, [(r) => r.provider.name], 'asc')
			},
			filtered_logs()
			{
				if(this.search)
				{
					let search = this.search.toLowerCase()
					return this.financial_profile.activity_logs.filter(l => {
						return l.action.toLowerCase().includes(search) || l.parse_date.includes(search)
					})
				}

				return this.financial_profile.activity_logs
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async GetFinancialProfile() {
				const vm = this

				vm.StartLoading()

				// PERFIL FINANCEIERO DA ORGANIZACAO
				await window.api.call('post', '/gateway/get-financial-profile', {
					organization_id: vm.organization_id
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.financial_profile = data.financial_profile

							vm.financial_profile.activity_logs = data.financial_profile.activity_logs.map(l => {
								return {
									...l,
									parse_date: vm.ParseFullDate(l.created_at)
								}
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})

				if(vm.financial_profile)
				{
					vm.new_recurring_accesse.recurring_accesses = 	vm.financial_profile.recurring_accesse.recurring_accesses
					vm.new_recurring_accesse.due_at				=	moment(vm.financial_profile.recurring_accesse.due_at).format('yyyy-MM-DDThh:mm')
				}

				vm.AddBillsBadge()

				return 
			}, 
			async StoreNewSubscription() { 

				this.$v.new_subscription.$touch()

				if(this.$v.new_subscription.$error) return 
				
				const vm = this

				var res;
				await Swal.fire({
						html:	`Você deseja mesmo assinar um novo plano para esta organização?`,
						icon:	'warning',
						confirmButtonText:	'Sim, assinar!',
						showCancelButton:	true,
						cancelButtonText:	'não',
						cancelButtonColor:	'#d33',
					})
					.then((result) => {
						res = result.isConfirmed
					})
				if (!res) return false;

				this.StartLoading()

				return window.api.call('post', '/gateway/subscribe', {
						organization_id: 				this.organization_id,
						plan_id:						this.new_subscription.plan_id,
						ignore_remaining_time_discount: this.new_subscription.ignore_remaining_time_discount,
						coupon_code:					this.new_subscription.coupon_code
					})
					.then(({data}) => {
						if(data.response)
						{
							this.GetFinancialProfile()
							window.jQuery('#modal-new-subscription').modal('hide')
							Swal.fire({
									title:				`Plano assinado com sucesso!!`,
									icon:				'success',
									toast:				true,
									position:			'top',
									timer:				5000,
									timerProgressBar: 	true,
									showConfirmButton:	false
								})
						}else{

							Swal.fire({
								title: 		'Opss...',
								text: 		'A operação não foi concluída com sucesso: ' + data.message,
								icon: 		'error'
							})

						}
					})
					.finally(() => {
						this.FinishLoading()
					})

			},
			async CancelSubscription() {
				const vm = this
				
				var res;
				await Swal.fire({
						text:	'Realmente deseja cancelar essa assinatura?',
						icon:	'warning',
						confirmButtonText:	'cancelar assinatura',
						showCancelButton:	true,
						cancelButtonText:	'não',
						cancelButtonColor:	'#d33',
					})
					.then((result) => {
						res = result.isConfirmed
					})
				
				if (!res) return false;

				this.StartLoading()

				return window.api.call('post', '/gateway/cancel-subscription', {
						organization_id: 		this.organization_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.GetFinancialProfile()

							Swal.fire({
									title:				`Assinatura cancelada com sucesso!!`,
									icon:				'success',
									toast:				true,
									position:			'top',
									timer:				5000,
									timerProgressBar: 	true,
									showConfirmButton:	false
								})
						}else{

							Swal.fire({
								title: 		'Opss...',
								text: 		'Houve um erro ao concluir, contate o suporte',
								icon: 		'error'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})

			},
			async UpdateRecurringAccess() {
				const vm = this

				this.StartLoading()
				
				return window.api.call('post', '/gateway/customer/recurring-access', {
						customer_id:				this.organization_id,
						recurring_accesses: 		this.new_recurring_accesse.recurring_accesses,
						due_at: 					moment(this.new_recurring_accesse.due_at).format('yyyy-MM-DD hh:mm')
					})
					.then(({data}) => {
						if(data.response)
						{
							this.GetFinancialProfile()

							Swal.fire({
									title:				`Acessos recorrentes atualizado!`,
									icon:				'success',
									toast:				true,
									position:			'top',
									timer:				5000,
									timerProgressBar: 	true,
									showConfirmButton:	false
								})
						}else{

							Swal.fire({
								title: 		'Opss...',
								text: 		'Houve um erro ao concluir, contate o suporte',
								icon: 		'error'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})

			},
			SaveResponsible(workgroup_id, decision)
			{
				if(!decision && this.responsibles.length === 1)
				{
					Swal.fire({
						icon: 		'error',
						title: 		'Eita!',
						text: 		'Precisa existir pelo menos 1 responsavel pela organização.'
					})

					return
				}

				this.StartLoading()

				return window.api.call('post', '/save-responsible', {
						decision: 		decision,
						workgroup_id: 	workgroup_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.$parent.GetOrganizationData()

						}else{

							Swal.fire({
								title: 		'Opss...',
								text: 		'Houve um erro ao concluir, contate o suporte',
								icon: 		'error'
							})
						}
					})
					.finally(() => {

						if(decision)
						{
							window.jQuery('#modal-responsibles').modal('hide')
						}		

						this.FinishLoading()
					})
			},
			async GetSubscriptions()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/gateway/get-active-subscription', {
					organization_id: vm.organization_id
				})
					.then(({data}) => {
						if(data.response)
						{
							vm.active_plan.name 	= data.subscription.plan.name
							vm.active_plan.users 	= data.subscription.plan.recurring_accesses
							vm.active_plan.id 		= data.subscription.plan_id
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async GetPaymentProfile()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('post', '/gateway/get-payment-profile', {
					organization_id: vm.organization_id
				})
					.then(({data}) => {
						if(data.response)
						{
							const p = data.payment_profile
							vm.card.holder_name 	= p.holder_name
							vm.card.number 			= vm.ParseCardNumber(p.card_number_first_six, p.card_number_last_four)
							vm.card.due_date		= vm.ParseDueDate(p.card_expiration)
							vm.mode					= 'edit'
							return
						}

						vm.mode = 'new'
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async DeletePaymentProfile()
			{
				const vm = this

				Swal.fire({
					title:		'Cuidado!',
					text:		'Realmente deseja excluir o perfil de pagamento?',
					icon:		'warning',
					showCancelButton: true,
					confirmButtonText:	'Sim, excluir',
					cancelButtonText:	'Não, cancelar'
				})
					.then(async (result) => {
						if(result.isConfirmed)
						{
							vm.StartLoading()

							return await window.api.call('post', '/gateway/delete-payment-profile', {
								organization_id: this.organization_id
							})
								.then(async ({data}) => {
									if(data.response)
									{
										Swal.fire({
											title:		'Perfil de pagamento excluído com sucesso',
											icon:		'success',
											toast:		true,
											position:	'top',
											timer:		4000,
											showConfirmButton: false,
											timerProgressBar: true
										})

										await this.GetFinancialProfile()
										
										return
									}

									Swal.fire({
										text:		'Ocorreu um erro ao excluir o perfil de pagamento, contate o suporte.',
										icon:		'error',
										title:		'Ops...'
									})
								})
								.finally(() => {
									vm.FinishLoading()
								})
						}
					})
			},
			async LoadPlans() {
				const vm = this
				// PLANOS  
				await window.api.call('post', '/gateway/get-plans')
					.then(({data}) => {
						vm.all_plans = _.orderBy(data.plans, 'total_price','asc')
					})
			},
			ParseDate(date)
			{
				if(!date)
				{
					return 'Não há data vinculada'
				}

				return date.substr(0, 10).split('-').reverse().join('/')
			},
			ParseFullDate(date)
			{
				if(!date)
				{
					return 'Não há data vinculada'
				}

				return moment(date).format('DD/MM/yyyy hh:mm')
			},
			ParseDueDate(date)
			{
				return date.substr(0, 7).split('-').reverse().join('/')
			},
			ParsePrice(price)
			{
				return `${parseFloat(price).toFixed(2)}`.replace('.', ',')
			},
			ParseCardNumber(first, last)
			{
				if(first && last)
				{
					return first.substr(0, 4) + ' **** **** ' + last
				}
				return ''
			},
			UpdateCard(val)
			{
				this.edit_card = val.card
			},
			OpenModal()
			{
				window.jQuery('#modal-credit-card').modal('show')

				this.modal_opened = true

				if(this.mode == 'new') this.edit = true

				window.jQuery('body').addClass('modal-open')
			},
			CloseModal()
			{
				window.jQuery('#modal-credit-card').modal('hide')

				this.modal_opened = false

				window.jQuery('body').removeClass('modal-open')
			},
			Redirect()
			{
				const vm = this
				
				vm.$router.push({ 
					name: 'ChangeOrganizationPlan', 
					params: { 
						organization_id: vm.organization_id,
						plan_id: vm.active_plan.id ? vm.active_plan.id : null
					}
				})
			},
			IgnoreRemainingTimeDiscountCheck() { 
				const vm = this
				if (! vm.new_subscription.ignore_remaining_time_discount ) {
					vm.new_subscription.coupon_code = null
				}
			},
			AddBillsBadge() {
				const vm = this
				 
				if (vm.financial_profile) {
					vm.financial_profile.bills = vm.financial_profile.bills.map((b) => {
								switch(b.status)
								{
									case 'paid': 
									{
										b.bill_class 	= 'badge badge-success'
										b.bill_status 	= 'PAGO'
										break
									}
									case 'canceled': 
									{
										b.bill_class 	= 'badge badge-danger'
										b.bill_status 	= 'CANCELADO'
										break
									}
									default : 
									{
										b.bill_class 	= 'badge badge-warning'
										b.bill_status 	= 'PENDENTE'
										break
									}
								}
								return b
							})
				}
			},
			GetBillDetails(bill) {

				const bill_data = JSON.parse(bill.vindi_json_data)

				const blacklist_attributes = [ // Lista da galera que nao vai aparecer.
					'pricing_range_id', 'installments', 'code', 'seen_at', 'pricing_schema', 'quantity', 'product_item',
					'description', 'product', 'print_url', 'gateway_response_code' ,'gateway_authorization' ,'gateway_transaction_id', 
					'gateway_response_fields' ,'fraud_detector_score' ,'fraud_detector_status', 'fraud_detector_id', 'gateway',
					'allow_as_fallback' ,'token', 'metadata' ,'payment_condition', 'transaction_type'
				]

				return this.ObjectToHtmlList(bill_data, blacklist_attributes)

			},
			ObjectToHtmlList(objeto, blacklist = []) {
				let output =  '<ul class="text-dark" style="padding-left: 25px;">'
				for (const key in objeto) {
					if (blacklist.includes(key)) continue;

					let element = objeto[key];
					
					if ( element instanceof Object ) { 
						element = this.ObjectToHtmlList(element, blacklist)
					} 	

					output +=  '<li>' + (this.TranslateAttribute(key, element)) + '</li>'
				}
				output +=  "</ul>\n"

				return output;
			},
			TranslateAttribute(key, value) {

				const vazio = '<small class="text-muted">vazio</small>'
				
				let dicionario = {
					'id':						{ label: 'ID' },
					'status': 					{ label: 'Situação', type: (x) => { switch (x) { case 'paid': return 'pago'; case 'success': return 'successo'; case'rejected': return 'rejeitado'; case 'pending': return 'pendente'; case 'canceled': return 'cancelado'; default: return x; } } },
					'discount': 				{ label: 'Desconto' },
					'charges': 					{ label: 'Cobranças' },
					'bill_items': 				{ label: 'Itens' },
					'discount_type':			{ label: 'Tipo de desconto' },
					'percentage':				{ label: 'Porcentagem' },
					'cycles':					{ label: 'Ciclos' },
					'cycle':					{ label: 'Ciclo' },
					'attempt_count':			{ label: 'Tentativas' },
					'last_transaction':			{ label: 'Ultima transação' },
					'gateway_message':			{ label: 'Mensagem do gateway' },
					'holder_name':				{ label: 'Titular' },
					'registry_code':			{ label: 'Codigo de registro' },
					'bank_branch':				{ label: 'Banco bandeira' },
					'bank_account':				{ label: 'Banco conta' },
					'card_expiration':			{ label: 'Expiracao do cartao', type: dateParser  },
					'card_number_first_six':	{ label: 'Primeiros 6 dígitos'  },
					'card_number_last_four':	{ label: 'Ultimos 4 dígitos'  },
					'payment_method':			{ label: 'Metodo de pagamento'  },
					'public_name':				{ label: 'Nome publico'  },
					'name':						{ label: 'Nome'  },
					'type':						{ label: 'Tipo'  },
					'payment_profile':			{ label: 'Perfil de pagamento'  },
					'plan':						{ label: 'Plano	'  },
					'period':					{ label: 'Período' },
					'subscription':				{ label: 'Assinatura'  },
					'payment_company':			{ label: 'Companhia'  },
					'customer':					{ label: 'Cliente'  },
					'email':					{ label: 'E-mail', type: (x) => { return `<a href='mailto:${x}' target='_blank'>${x}</a>` } },
					'start_at': 				{ label: 'Inicio em', type: dateParser },
					'end_at':	 				{ label: 'Termino em', type: dateParser },
					'duration':	 				{ label: 'Duracao', type: (x) => { return ( x / 24 / 60 / 60 ).toFixed(0) + ' dia(s)' }},
					'created_at': 				{ label: 'Criado em', type: dateParser },
					'updated_at': 				{ label: 'Atualizado em', type: dateParser },
					'billing_at': 				{ label: 'Faturado em', type: dateParser },
					'next_attempt': 			{ label: 'Faturado em', type: dateParser },
					'paid_at': 					{ label: 'Pago em', type: dateParser },
					'due_at': 					{ label: 'Vence em', type: dateParser },
					'url': 	{
						label: 'Link pagamento',
						type: (x) => { return `<a href='${x}' target='_blank'>Clique para acessar</a>` } 
					},
					'amount': 	{
						label: 'Valor',
						type: (x) => { return (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, })).format( parseFloat(x) ); }
					}
				}

				let objeto 	= (key in dicionario) ? dicionario[key] : null;

				let index 	= objeto ? objeto.label : key;
				let val 	= objeto && objeto.type && value ? objeto.type(value) : value ?? vazio;

				return `<b>${index}: </b>${val}`;
			}
		},
		beforeMount(){
			// this.GetFinancialProfile()
			// this.LoadPlans()
					
		}
	}


</script>

<style lang="css" scoped>
	#modalActivityLogs .modal-dialog
	{
		max-width: 800px;
	}

	#modalActivityLogs .modal-dialog .modal-body
	{
		height: 600px;
		overflow-y: scroll;
	}

	.avatar-content
	{
		width: 45px;
		height: 45px;
	}

	.avatar-content img
	{
	    width: 100%;
	    height: 100%;
	    object-fit: cover;
	    border-radius: 50%;
	    border: 1px solid #ccc;
	}
</style>