<template>
    <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import helpers from '../../helpers'
import Swal from 'sweetalert2'

export default {
    name: 'CreateTestOrganization',
    props: {
        account_id: {
            type: [String, Number],
            required: true,
            default: null
        }
    },
    data() {
         return {

         }
    },
    methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
        CreateTestOrganization()
        {
            this.StartLoading()

            return window.api.call('get', `/create-test-organization/${this.account_id}`)
                .then((data) => {
                    if (data.status === 200) {
                        Swal.fire({
                            title: 'Organização criada!',
                            icon: 'success'
                        }).then(() => {
                            this.$router.push({ name: 'Organizations' })
                        })
                    } else {
                        Swal.fire({
                            title: 'Houve um erro, verifique as requisições',
                            icon: 'error'
                        }).then(() => {
                            this.$router.push({ name: 'Organizations' })
                        })
                    }
                })
                .finally(() => {
                    this.FinishLoading()
                })
        }
    },
    beforeMount() 
    {
       this.CreateTestOrganization()
    }
}
</script>

<style lang="css" scoped>
</style>