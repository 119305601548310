<template>
    <div class="col-12">
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="col-12 d-flex justify-content-center">
                <h2 class="">Criar um novo artigo</h2>
            </div>
            <ArticleForm 
                v-model="article"
                :mode="'new'"
                @save="UpdateArticle"
            />
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import ArticleForm from './components/Form'

import Swal from 'sweetalert2'

export default {
    name: "NewArticle",
    components: {
        ArticleForm
    },
    data () {
        return {
            article: {
                titulo:             '',
                faq_section_id:     null,
                faq_category_id:    null,
                faq_author_id:      null,
                slug:               '',
                texto:              '',
                status:             1,
                agendamento:        ''
            }
        }
    },
    computed: {
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading', 'GetFaqItems'
        ]),
        async UpdateArticle(val)
        {
            this.article = val

            await this.SaveArticle()
        },
        async SaveArticle()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/faq/save-article', {
                ...vm.article
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            icon:       'success',
                            title:      'Artigo salvo com sucesso!',
                            toast:      true,
                            position:   'top',
                            timer:      4000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'ManageArticles' })

                        return
                    }

                    Swal.fire({
                        icon:       'error',
                        title:      'Erro ao salvar o artigo.',
                        toast:      true,
                        position:   'top',
                        timer:      3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        }
    },
    beforeMount() {

    }
    
}
</script>

<style>

</style>