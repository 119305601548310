
window.defaultAvatar 		= require('./assets/img/profile.png')

window.statesBR 			= [
	{"id":1,"initials":"AC","name":"Acre"},
	{"id":2,"initials":"AL","name":"Alagoas"},
	{"id":3,"initials":"AM","name":"Amazonas"},
	{"id":4,"initials":"AP","name":"Amapá"},
	{"id":5,"initials":"BA","name":"Bahia"},
	{"id":6,"initials":"CE","name":"Ceará"},
	{"id":7,"initials":"DF","name":"Distrito Federal"},
	{"id":8,"initials":"ES","name":"Espírito Santo"},
	{"id":9,"initials":"GO","name":"Goiás"},
	{"id":10,"initials":"MA","name":"Maranhão"},
	{"id":11,"initials":"MG","name":"Minas Gerais"},
	{"id":12,"initials":"MS","name":"Mato Grosso do Sul"},
	{"id":13,"initials":"MT","name":"Mato Grosso"},
	{"id":14,"initials":"PA","name":"Pará"},
	{"id":15,"initials":"PB","name":"Paraíba"},
	{"id":16,"initials":"PE","name":"Pernambuco"},
	{"id":17,"initials":"PI","name":"Piauí"},
	{"id":18,"initials":"PR","name":"Paraná"},
	{"id":19,"initials":"RJ","name":"Rio de Janeiro"},
	{"id":20,"initials":"RN","name":"Rio Grande do Norte"},
	{"id":21,"initials":"RO","name":"Rondônia"},
	{"id":22,"initials":"RR","name":"Roraima"},
	{"id":23,"initials":"RS","name":"Rio Grande do Sul"},
	{"id":24,"initials":"SC","name":"Santa Catarina"},
	{"id":25,"initials":"SE","name":"Sergipe"},
	{"id":26,"initials":"SP","name":"São Paulo"},
	{"id":27,"initials":"TO","name":"Tocantins"}
]

window.weekDays 		= [
	{ name: 'Domingo', initials: 'Dom', value: 1},
	{ name: 'Segunda', initials: 'Seg', value: 2},
	{ name: 'Terça', initials: 'Ter', value: 3},
	{ name: 'Quarta', initials: 'Qua', value: 4},
	{ name: 'Quinta', initials: 'Qui', value: 5},
	{ name: 'Sexta', initials: 'Sex', value: 6},
	{ name: 'Sabado', initials: 'Sab', value: 7}
]

window.year 			= [
	{ name: 'Janeiro', initials: 'Jan', value: 1 },
	{ name: 'Fevereiro', initials: 'Fev', value: 2 },
	{ name: 'Março', initials: 'Mar', value: 3 },
	{ name: 'Abril', initials: 'Abr', value: 4 },
	{ name: 'Maio', initials: 'Mai', value: 5 },
	{ name: 'Junho', initials: 'Jun', value: 6 },
	{ name: 'Julho', initials: 'Jul', value: 7 },
	{ name: 'Agosto', initials: 'Ago', value: 8 },
	{ name: 'Setembro', initials: 'Set', value: 9 },
	{ name: 'Outubro', initials: 'Out', value: 10 },
	{ name: 'Novembro', initials: 'Nov', value: 11 },
	{ name: 'Dezembro', initials: 'Dez', value: 12 }
]

window.classifications 	= [
    { text: 'Não Urgente', value: 1 },
    { text: 'Pouco Urgente', value: 2 },
    { text: 'Urgente', value: 3},
    { text: 'Muito Urgente', value: 4},
    { text: 'Emergência', value: 5}
]

window.creditCards		= [
	{ id: 1, name: 'alipay', pretty: 'Alipay', svg: 'alipay', status: false },
	{ id: 2, name: 'american-express', pretty: 'American Express', svg: 'american-express', status: true },
	{ id: 3, name: 'diners-club', pretty: 'Diners Club', svg: 'diners-club', status: true },
	{ id: 4, name: 'discover', pretty: 'Discover', svg: 'discover', status: true },
	{ id: 5, name: 'elo', pretty: 'Elo', svg: 'elo', status: false },
	{ id: 6, name: 'hipercard', pretty: 'Hiper Card', svg: 'hipercard', status: false },
	{ id: 7, name: 'jcb', pretty: 'JCB', svg: 'jcb', status: true },
	{ id: 8, name: 'maestro', pretty: 'Maestro', svg: 'maestro', status: false },
	{ id: 9, name: 'mastercard', pretty: 'MasterCard', svg: 'mastercard', status: true },
	{ id: 10, name: 'visa', pretty: 'Visa', svg: 'visa', status: true },
	{ id: 11, name: 'unionpay', pretty: 'UnionPay', svg: 'unionpay', status: true },
]