<template>
	<div class="container">
		<div class="d-flex justify-content-center mt-3">
			<div class="card p-5 w-100">
				<p class="text-danger" v-if="never_configured">
					Essa configuração nunca foi alterada, então é tudo visível para o usuário.
				</p>
				<div>
					<ul>
						<li class="mb-2 list-unstyled">
							<div class="d-flex align-items-center justify-content-between">
								<div class="form-check form-check-inline">
									<input
										id="select_all"
										class="form-check-input"
										type="checkbox"
										v-model="select_all"
									>
									<label 
										class="form-check-label"
										for="select_all"
									>
										SELECIONAR TUDO
									</label>
								</div>

								<div class="d-flex">
									<button @click="Save" class="btn btn-success" type="button">
										Salvar
										<i class="fas fa-save"></i>
									</button>
									<button @click="Force" class="ml-2 btn btn-secondary" type="button">
										Forçar
										<i class="fas fa-sync"></i>
									</button>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<hr>

				<div class="d-flex flex-wrap flex-column modules-container">
					<ul
						v-for="module in modules"
						:key="module.id"
					>
						<li class="mb-2 list-unstyled">
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="checkbox"
									:id="'ChkModule_' + module.id"
									:name="'ChkModule_' + module.id"
									:value="module.id"
									v-model="modular_view.modules"
								>
								<label class="form-check-label" :for="'ChkModule_' + module.id">
									{{ module.name }}
								</label>
							</div>

							<div
								class="mb-3 collapse"
								:id="'collapse_' + module.id"
								:class="{
									'show': modular_view.modules && modular_view.modules.includes(module.id)
								}"
							>

								<ul
									v-for="modality in module.modalities"
									:key='modality.id'
								>
									<li class="list-unstyled ml-4 mb-2">
										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="checkbox"
												:id="'ChkModality_' + modality.id"
												:name="'ChkModality_' + modality.id"
												:value="modality.id"
												v-model="modular_view.modalities"
											>
											<label class="form-check-label" :for="'ChkModality_' + modality.id">
												{{ modality.name }}
											</label>
										</div>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

export default {

	name: 'ModularView',
	data() {
		return {
			modules: [],
			modular_view: {
				modules: [],
				modalities: []
			},
			never_configured: false,
			select_all: false
		}
	},
	props: {

		organization_id: {
			type: [ Number, String ],
			default: null,
			required: true
		}

	},
	computed: {
		modalities() {
			const modalities = []

			for (const module of this.modules) {
				modalities.push(...module.modalities)
			}

			return modalities
		}
	},
	watch: {
		select_all: {
			handler(val) {
				window.jQuery(".form-check-label").each(function () {
					const input = window.jQuery(this).prev()

					const label = window.jQuery(this)

					if(input.prop("checked") !== val) {
						label.click()
					}
				})
			}
		}
	},
	methods: {

		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		async Load() {
			const vm = this

			vm.StartLoading();

			return await window.api.call('post', '/get-modular-view', { organization_id: this.organization_id })
				.then(({data}) => {
					if(data.response) {
						this.modules = data.modules
						this.modular_view = data.account.instance?.config?.modular_view || null

						if(data.account.instance?.config?.modular_view == null) {
							this.modular_view = {
								modules: [],
								modalities: []
							}

							this.never_configured = true;
						} else {
							this.never_configured = false;
						}

						if(this.modular_view.modules.length == this.modules.length &&
							this.modular_view.modalities.length == this.modalities.length) {
							this.select_all = true
						}

					} else {
						Swal.fire({
							icon: 'error',
							html: 'Houve um erro: ' + data.message
						})
					}
				}).finally((err) => {
					vm.FinishLoading()
				});
		},
		Save() {

			const vm = this

			vm.StartLoading();

			window.api.call('post', '/save-modular-view', {
				organization_id: this.organization_id,
				modular_view: this.modular_view
			}).then(( { data } ) => {

					if ( data.response ) {

						Swal.fire({
							icon: 'success',
							title: 'Visão modular atualizada!',
							timer: 5000,
							timerProgressBar: true,
							toast: true ,
							position: 'top',
						})

						this.Load()

					} else {

						Swal.fire({
							icon: 'error',
							html: 'Houve um erro: ' + data.message
						})

					}

				}).finally((err) => {
					vm.FinishLoading()
				});

		},
		Force() {
			Swal.fire({
				icon: 'warning',
				title: 'Atenção!',
				text: 'Tem certeza que deseja forçar este padrão para todas as outras organizações? Esta ação não poderá ser desfeita.',
				showCancelButton: true,
				confirmButtonText: 'Sim, forçar',
				cancelButtonText: 'Não, cancelar'
			})
				.then(async (result) => {
					if(result.isConfirmed) {
						return await window.api.call('post', '/force-modular-view', {
							organization_id: this.organization_id
						})
							.then(({data}) => {
								if(data.response) {
									Swal.fire({
										icon: 'success',
										title: 'Padrão alterado com sucesso',
										timer: 4000,
										toast: true,
										position: 'top',
										timerProgressBar: true
									})

									return
								}

								Swal.fire({
									icon: 'error',
									title: 'Ops...',
									text: 'Ocorreu um erro ao executar a ação para forçar o padrão para todas as organizações, nenhum dado foi alterado.'
								})
							})
					}
				})
		}
	},
	beforeMount() {
		this.Load();
	}

}
</script>

<style>
	.w-400 {
	    width: 400px;
	}

	.modules-container {
		height: 550px;
	}
</style>