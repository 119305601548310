<template>
    <div class="col-12 form my-5 d-flex flex-column align-items-center">
            <div class="col-8 header d-flex flex-column align-items-center">
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-3" for="descricao">Descricao: </label>
                    <input 
                        id="descricao"
                        type="text"
                        class="form-control col-9 my-1 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.descricao.$error }"
                        v-model="$v.form.descricao.$model">
                </div>
                <div class="form-group row col-6 d-flex align-items-center justify-content-center position-relative">
                    <label class="font-18 col-3" for="icone">Ícone FA: </label>
                    <div class="col-9 p-0">
                        <input 
                            id="icone"
                            type="text"
                            class="form-control col-12 my-1 py-3 font-16"
                            :class="{ 'is-invalid': $v.form.icone.$error }"
                            v-model="$v.form.icone.$model"
                            placeholder="Exemplo: address-book">
                        <a class="ml-3 mt-2 text-primary" href="https://fontawesome.com/icons?d=gallery&p=2" target="_blank">fontawesome.com</a>
                    </div>
                    <div class="position-absolute icon-fa">
                        <i v-if="$v.form.icone.$model"
                            class="text-primary fal"
                            :class="`fa-${$v.form.icone.$model}`"></i>
                        <i v-else class="text-primary fal fa-address-book"></i>
                    </div>
                </div>
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-3" for="slug">Slug: </label>
                    <input 
                        id="slug"
                        type="text"
                        class="form-control col-9 my-1 py-3 font-16"
                        :value="slugged_description"
                        disabled>
                </div>
                <div class="form-group row col-6 d-flex my-5"
                    :class="mode == 'edit' ? 'justify-content-between' : 'justify-content-end'">
                    <button class="btn btn-danger"
                        v-if="mode == 'edit'"
                        @click="DeleteCategory">
                        excluir
                    </button>
                    <button class="btn btn-primary"
                        @click="SaveCategory">
                        salvar
                    </button>
                </div>
            </div>
        </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import { required } from 'vuelidate/lib/validators'

export default {
    name: "CategoryForm",
    props:  {
        value:  {
            type:       Object,
            default:    () => {}
        },
        mode:   {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            form: {
                descricao:          '',
                icone:              '',
                slug:               '',
            }
        }
    },
    validations: {
        form: {
            descricao:  {
                required
            },
            icone:      {
                required
            }
        }
    },
    computed: {
        ...mapGetters('system', [
            'all_categories'
        ]),
        slugged_description()
        {
            if(this.form.descricao)
            {
                return window.helpers.Slugify(this.form.descricao)
            }

            return ''
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val)
            {
                this.form = { ...val }
            }
        }
    },
    methods: {
        SaveCategory()
        {
            this.$v.form.$touch()

            if(!this.$v.form.$error)
            {
                this.form.slug = this.slugged_description
                this.$emit('save', this.form)
            }
        },
        DeleteCategory()
        {
            this.$emit('delete')
        }
    },
    beforeMount()
    {
        
    }
}
</script>

<style lang="css" scoped>
.icon-fa
{
    right: -30px;
    top: 0px;
    font-size: 2rem;
}
</style>