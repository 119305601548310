<template>
	<div>
		<header class="ps-t-0 ps-l-0 ps-r-0 px-4 py-3">
   			<div class="row justify-content-center justify-content-md-between align-items-center">
        		<div class="col-auto mb-2">
					<router-link
						:to="{ name: 'Home' }">
						<img :src="img.logo" width="150">		
					</router-link>
				</div>

		        <div class="col-12 col-md-auto">
		            <div class="form-row align-items-center justify-content-end pr-md-3">
		                <div class="col-4 col-md-auto mb-3">

		                    <router-link 
								class="btn btn-block px-3 btn-light"
								:to="{ name: 'Home' }"
							>
								<i class="far fa-home mx-2"></i>
							</router-link>
		                </div>
						
		                <div class="col-4 col-md-auto mb-3">
							<router-link  
								class="btn btn-block px-3 btn-light"
								:to="{ name: 'OnlineAccounts' }"
							>
								<i class="far fa-users mx-2"></i>
							</router-link>
						</div>
		                <div class="col-4 col-md-auto mb-3">
							<button 
								type="button" 
								class="btn btn-block px-3 btn-light"
								@click="ChangeTheme()"
							>
								<i class="fal mx-2" :class="{
									'fa-sun': theme == 'dark',
									'fa-moon': theme == 'light',
								}"></i>
							</button>
						</div>
			            <div class="col-4 col-md-auto mb-3">
							<button 
								type="button" 
								class="btn btn-block px-3 btn-light"
								@click="Logout"
							>
								<i class="far fa-sign-out mx-2"></i>
							</button>
						</div>
					</div>
				</div>

				<div class="col-12"
					v-if="$route.name != 'Home'">
					<button 
						class="btn btn-light previous-button"
						@click="Previous"
					>
						<i class="far fa-chevron-left mr-3"></i> voltar
					</button>
				</div>
			</div>
		</header>
	</div>

</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 		'Navbar',
		data () {
			return {
				img: 		{
					logo: 				require('../../assets/img/logo_original.png')
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'admin',
			]),
			...mapGetters('system', [
				'previous'
			]),
			theme() {
				return this.admin?.theme ?? 'dark'
			}
		},
		methods: {
			...mapActions('auth', [
				'Logout', 'ToggleTheme'
			]),
			Previous()
			{
				this.$router.go(-1)
			},
			ChangeTheme() {
				this.ToggleTheme()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>