import Vuex from './vuex'

class Api {
    constructor () {}

    call (requestType, url, data = null, opts = {}) {
	    return new Promise((resolve, reject) => {
	        window.axios[requestType](url, data, opts)
	            .then(response => {
	                resolve(response)
	            })
	            .catch(({response}) => {
	                if ([400, 401, 418].indexOf(response.status) > -1) 
	                {	               
	                	new Vuex().dispatch('auth/Logout')
	                		.finally(() => {
	                			reject(response)
	                		})
	                }

	                resolve(response)
	            });
	    });
	}
}

export default Api;