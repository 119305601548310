<template>
    <div class="container">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-12 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<span>Cadastramento e gerenciamento de cupons</span>
					<h4 class="font-24 font-md-32 m-0">
						Gerenciar cupons
					</h4>
				</div>
			</div>
            <div class="col-12 d-flex">
                <div class="col-11 d-flex justify-content-center">
                    <input type="text" 
                        class="col-6 form-control"
                        v-model="search"
                        placeholder="Pesquisa">
                    <router-link
                        class="ml-5 btn btn-primary"
                        :to="{ name: 'NewCoupon' }">
                        novo cupom
                    </router-link>
                </div>

                <div class="col-6 position-relative">
                    <div class="col-1 d-flex justify-content-end">

                        <button 
                            type="button" 
                            class="btn "
                            :class="only_deleted ? 'btn-primary' : 'btn-danger'"
                            @click="GetCoupons(!only_deleted)"

                            @mouseover="tooltip = true"
                            @mouseleave="tooltip = false"
                        > 
                            <i  class="fa" 
                                aria-hidden="true"
                                :class="only_deleted ? 'fa-list' : 'fa-trash'"
                            ></i>
                        </button>

                    </div>
                
                    <div v-if="tooltip" class="tooltip-only-deleted">
                        Mostrar cupons os excluídos e os existentes
                    </div>
                </div>

            </div>
			<div class="col-12 col-md mb-4">
                <DataTables
                    :headers="headers"
					:search="search"
                    v-model="all_coupons"
					:tr-button="true"
					@by-click-on-tr="Show"
                >
                    <template v-slot:body="{ item }">
						<td>
							<b class="font-18">{{ item.name.toUpperCase() }}</b>
						</td>
						<td>
							<b class="font-18">{{ ParseDiscount(item) }}</b>
						</td>
						<!-- <td>
							{{ item.code }}
						</td> -->
						<td scope="col" class="align-middle"> 
							<span 
                                class="badge font-16 p-2"
                                :class="{
                                    'badge-info': 	    ( item.max_usages == null ),
                                    'badge-success': 	( item.usages < item.max_usages ),
                                    'badge-danger': 	( item.usages >= item.max_usages )
                                }"
                            >
                                {{ item.usages }}/{{ item.max_usages == null ? '∞' : item.max_usages }}
                            </span>
						</td>
						<td>
                            {{ item.cycles ? item.cycles : '-' }}
                        </td>
						<td>
                            <span 
                                class="badge font-16 p-2"
                                :class="{
                                    'badge-success': 	( item.customer_id ),
                                    'badge-danger': 	!( item.customer_id )
                                }"
                            >
                               {{ item.customer_id ? 'Sim' : 'Não' }}
                            </span>
                        </td>
						<td>
                            {{ ParseDate(item.created_at) }}
                        </td>
					</template>
                </DataTables>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import DataTables from '../shared/DataTables.vue'
export default {
  components: { DataTables },
    name: 'Coupons',
    props: {

    },
    data () {
        return {
            only_deleted:       true,
            search:             '',
            tooltip:            false,
            all_coupons:        [],
            headers:            [
                { text: 'Nome', value: 'name', sortable: true },
                { text: 'Desconto', value: 'discount', sortable: true },
                // { text: 'Code', value: 'code', sortable: true },
                { text: 'Qnt. de uso', value: 'usages', sortable: true },
                { text: 'Ciclos', value: 'cycles', sortable: true },
                { text: 'Exclusivo', value: 'exclusive', sortable: true },
                { text: 'Data', value: 'created_at', sortable: true }
            ]
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async GetCoupons(only_deleted = false)
        {
            const vm = this

            vm.StartLoading()

            this.only_deleted = only_deleted 
            if ( only_deleted ) {
                return await window.api.call('post', '/gateway/coupons-deleted')
                    .then(({data}) => {
                        vm.all_coupons = data.coupons
                    })
                    .finally(() => {
                        vm.FinishLoading()
                    })
            } else { 
                return await window.api.call('post', '/gateway/coupons')
                    .then(({data}) => {
                        vm.all_coupons = data.coupons
                    })
                    .finally(() => {
                        vm.FinishLoading()
                    })
            }
        },
        Show(coupon)
        {
            this.$router.push({
                name: 'EditCoupon',
                params: {
                coupon_id: coupon.id
                }
            })
        },
        ParseDate(date)
        {
            if(date)
            {
                let new_date = date.substr(0, 10).split('-').reverse().join('/')

                return new_date
            }
            return ''
        },
        ParseDiscount(item)
        {
            switch (item.discount_type) {
                case 'amount':
                    return 'R$ ' + parseFloat(item.amount).toFixed(2);
                case 'percentage':
                    return item.percentage + '%'
                default:
                    return '???'
            }
        }
    },
    beforeMount()
    {
        this.GetCoupons()
    }
}
</script>

<style scoped>

.tooltip-only-deleted
{
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    position: absolute;
    background-color: #d0d0d0;
    top: -74px;
    right: 370px;
    color: #333;
    font-weight: 600;
    transition: ease 5s;
    width: 251px;
    padding: 10px;
}

</style>