<template>
	<div class="col-12 d-flex flex-column align-items-center">
		<div class="d-flex justify-content-center">
            <h2>Gerenciamento de Usuários</h2>
        </div>
		<div class="col-8 mt-4">
            <div class="col-12 d-flex justify-content-center">
                <input type="text" 
                    class="col-6 form-control"
                    v-model="search"
                    placeholder="Pesquisa">
                <router-link
                    class="ml-5 btn btn-primary"
                    :to="{ name: 'NewUser' }">
                    cadastrar
                </router-link>
            </div>
        </div>

		<div class="col-8">
			<DataTables 
				v-model="users"
				:headers="headers"
				:search="search"
				:tr-button="true"
				max_height="700px"
				@by-click-on-tr="Show"
			>
				<template v-slot:body="{ item }">
					<td>
						<b class="font-18">{{ item.name }}</b>
					</td>
					<td>
						<p class="font-18">{{ item.username }}</p>
					</td>
					<td>
						<p class="font-18">{{ ParseDate(item.created_at) }}</p>
					</td>
				</template>
			</DataTables>
		</div>
	</div>
</template>

<script>
	import DataTables from './../shared/DataTables'

	import { mapActions } from 'vuex'

	const _ = require('lodash')

	import Swal from 'sweetalert2'

	export default {

		name: 'Index',
		components: {
			DataTables
		},
		data () {
			return {
				users:			[],
				headers:		[
					{ text: 'Nome', value: 'name', sortable: true },
					{ text: 'Username', value: 'username', sortable: true },
					{ text: 'Data de cadastro', value: 'created_at', sortable: true }
				],
				search:			''
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async GetUsers()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('get', '/get-users')
					.then(({data}) => {
						if(data.response)
						{
							vm.users = _.orderBy(data.users, ['status', 'name'], ['desc', 'asc'])

							return
						}

						Swal.fire({
							title:		'Eita!',
							text:		'Houve um erro ao buscar os usuários cadastrados',
							icon:		'error'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			Show(user)
			{
				this.$router.push({ name: 'EditUser', params: {
					user_id: user.id
				}})
			},
			ParseDate(date)
			{
				if(date)
				{
					let new_date = date.substr(0, 10).split('-').reverse().join('/')

					return new_date
				}
				return ''
			}
		},
		beforeMount() {
			this.GetUsers()
		}
	}
</script>

<style lang="css" scoped>
.user-avatar
{
	width: 50px;
	height: 50px;
	border: 1px solid var(--primary);
	padding: 0.1rem;
	border-radius: 50%;
}
</style>