<template>
	<div class="col-12 d-flex flex-column align-items-center">
		<div class="d-flex justify-content-center">
			<h2>Gerenciamento de Integrações</h2>
		</div>
		<div class="col-8 mt-4">
			<div class="col-12 d-flex justify-content-center">
				<input type="text" 
					class="col-6 form-control"
					v-model="search"
					placeholder="Pesquisa">
				<router-link
					class="ml-5 btn btn-primary"
					:to="{ name: 'NewIntegration' }">
					nova integração
				</router-link>
			</div>
		</div>

		<div class="col-8">
			<DataTables 
				v-model="accounts"
				:headers="headers"
				:search="search"
				:tr-button="true"
				max_height="700px"
				@by-click-on-tr="Show"
			>
				<template v-slot:body="{ item }">
					<td>
						<b class="font-16">{{ item.name }}</b>
					</td> 
					<td class="align-middle">
						<span class="badge font-16 p-2 align-middle"
							:class="{ 
								'badge-success': item.status,
								'badge-danger': !item.status
							}"
						> 
							{{ item.status ? 'ativo' : 'inativo' }}
						</span> 
					</td>
					<td class="align-middle">
						<span class="font-16">{{ ParseDate(item.created_at) }}</span>
					</td>
				</template>
			</DataTables>
		</div>
	</div>
</template>

<script>
	import DataTables from './../shared/DataTables'

	import { mapActions } from 'vuex'

	import Swal from 'sweetalert2'

	import _ from 'lodash'

	export default {

		name: 'Index',
		components: {
			DataTables
		},
		data () {
			return {
				accounts:			[],
				headers:		[
					{ text: 'Nome', value: 'name', sortable: true }, 
					{ text: 'Status', value: 'status', sortable: true },
					{ text: 'Data de cadastro', value: 'created_at', sortable: true }
				],
				search:				''
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async GetAccounts()
			{
				const vm = this

				vm.StartLoading()

				return await window.api.call('get', '/get-integration-accounts')
					.then(({data}) => {
						if(data.response)
						{
							vm.accounts = data.accounts
							return
						}

						Swal.fire({
							title:		'Eita!',
							text:		'Houve um erro ao buscar os usuários cadastrados',
							icon:		'error'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			Show(account)
			{
				this.$router.push({ name: 'EditIntegration', params: {
					account_id: account.id
				}})
			},
			ParseDate(date)
			{
				if(date)
				{
					let new_date = date.substr(0, 10).split('-').reverse().join('/')

					return new_date
				}
				return ''
			}
		},
		beforeMount() {
			this.GetAccounts()
		}
	}
</script>

<style lang="css" scoped>
.user-avatar
{
	width: 50px;
	height: 50px;
	border: 1px solid var(--primary);
	padding: 0.1rem;
	border-radius: 50%;
}
</style>