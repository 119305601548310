<template>
  	<div class="container mh-550 px-0 d-flex justify-content-center">
		<div v-if="!content_loaded" class="spinner-border text-theme mt-3" role="status"></div>
		<div v-else-if="plans.length" class="col-12 col-md-8 px-0 d-flex flex-column align-items-start">
			<div class="col-auto px-0 scroll-x scroll-transparent d-flex mx--5 mx-md-0">
				<ul class="nav nav-pills nav-theme w-md-100 my-2 my-md-3 px-5">
					<li class="nav-item">
						<a class="nav-link pb-md-4 px-md-4 text-center active" data-toggle="tab" href="#professional">Profissional</a>
					</li>
					<li class="nav-item">
						<a class="nav-link pb-md-4 px-md-4 text-center" data-toggle="tab" href="#corporate">Corporativo</a>
					</li>
				</ul>
			</div>
		
			<div class="col px-0 tab-content d-flex justify-content-center mb-4">
				<!-- PROFISSIONAL -->
				<div id="professional" class="tab-pane col-12 px-0 mt-3 active">
					<div class="col-12 px-0 d-flex justify-content-center align-items-center">
						<div class="col px-0 mr-3">
							<div class="form-group m-0">
								<label for="licensed_plans">Planos</label>
								<select v-model="selected_professional_plan" class="form-control" id="licensed_plans">
									<option :value="null" disabled :selected="!selected_professional_plan">- Nenhum -</option>
									<option v-for="plan in licensed_plans"
										:value="plan.price_id"
										:key="plan.id"
										:selected="plan.price_id === selected_professional_plan"
									>
										{{ plan.nickname }} - {{ ParsePrice(plan.unit_amount) }}
									</option>
								</select>
							</div>
						</div>
						<div class="d-flex justify-content-end align-items-center">
							<button
								class="mt-4 btn btn-primary"
								@click="Subscribe('professional')"
							>
								Salvar
							</button>
						</div>
					</div>

					<h5 class="mt-4 mb-0">
						Assinatura atual:
					</h5>
					<div v-if="professional_subscription" class="col-12 p-0 col-12 px-0 mt-2 d-flex flex-column align-items-center">
						<div class="col-12 border p-3_5">
							<div class="col-12 px-0 d-flex align-items-start justify-content-between">
								<div class="col-10 px-0">
									<div class="col-12 px-0 d-flex align-items-center">
										<h6 class="m-0">
											Licença {{ professional_subscription.plan.nickname }} ({{ ParseInterval(professional_subscription.plan.interval) }})
										</h6>
										<small class="ml-2 badge"
											:class="{
												'badge-success': professional_subscription.status === 'active',
												'badge-info': professional_subscription.status === 'trialing',
												'badge-danger': professional_subscription.status === 'past_due',
											}"
										>
											{{
												professional_subscription.status === 'active' ? 'Ativa' :
												professional_subscription.status === 'trialing' ? 'Em teste' :
												professional_subscription.status === 'past_due' ? 'Atrasada' :
												professional_subscription.status === 'incomplete' ? 'Incompleta' :
												professional_subscription.status === 'incomplete_expired' ? 'Expirada' :
												professional_subscription.status === 'paused' ? 'Pausada' : 'Cancelada'
											}}
										</small>
									</div>
									<p class="m-0">
										{{ ParsePrice(professional_subscription.plan.per_unit) }}/{{ ParsePeriod(professional_subscription.plan.interval) }}
									</p>
								</div>
								<div class="col-2 px-0 d-flex justify-content-end">
									<span v-if="1 === 2"
										class="link"
									>
										Alterar
									</span>
								</div>
							</div>
						</div>
						<div v-if="professional_subscription && (professional_subscription.status !== 'canceled' &&
							professional_subscription.status !== 'incomplete_expired')" class="col-12 border p-0 mt-4">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Data e hora da assinatura
								</p>
								<p class="m-0 text-dark">
									<b>{{ professional_subscription.start_date }}</b>
								</p>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Próxima data de cobrança
								</p>
								<p class="m-0 text-dark">
									<b>{{ professional_subscription.next_invoice }}</b>
								</p>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Último pagamento
								</p>
								<p v-if="professional_subscription.last_invoice && 
									professional_subscription.last_invoice.date &&
									professional_subscription.last_invoice.total"
									class="m-0 text-dark"
								>
									<span>
										<b>{{ professional_subscription.last_invoice.date }}</b>
									</span>
									<span class="ml-3">
										<b>{{ ParsePrice(professional_subscription.last_invoice.total) }}</b>
									</span>
								</p>
								<p v-else class="m-0 text-dark">
									<span>
										<b>Ainda não houve</b>
									</span>
								</p>
							</div>
						</div>
					</div>
					<div v-else class="col-12 p-0 col-12 px-0 mt-2">
						Não possui.
					</div>
				</div>

				<!-- CORPORATIVO -->
				<div id="corporate" class="tab-pane col-12 px-0 mt-3">
					<div class="col-12 px-0 d-flex flex-column justify-content-center align-items-center">
						<!-- PRICE -->
						<div class="col-12 px-0 d-flex align-items-center justify-content-between">
							<div class="col-10 px-0 form-group m-0">
								<label for="metered_plans">Planos</label>
								<select v-model="selected_corporate_plan" class="form-control" id="metered_plans">
									<option :value="null" disabled :selected="!selected_corporate_plan">- Nenhum -</option>
									<option v-for="plan in metered_plans"
										:value="plan.price_id"
										:key="plan.id"
										:selected="plan.price_id === selected_corporate_plan"
									>
										{{ plan.nickname }} - {{ ParsePrice(plan.unit_amount) }}
									</option>
								</select>
							</div>
							<div class="d-flex justify-content-end align-items-center">
								<button
									class="mt-4 btn btn-primary"
									@click="Subscribe('corporate')"
								>
									Salvar
								</button>
							</div>
						</div>
						<!-- ORGANIZATION -->
						<div class="col-12 px-0">
							<div class="col-10 px-0 form-group m-0">
								<label for="organization">Organização</label>
								<select v-model="selected_corporate_organization_id" class="form-control" id="organization">
									<option :value="null" disabled :selected="!selected_corporate_organization_id">- Nenhum -</option>
									<!-- TODO: Resolver a não exibição da organização selecionada -->
									<option v-for="(organization) in organizations"
										:value="organization.account_requester_id"
										:key="organization.account_requester_id"
									>
										{{ organization.requester.name }}
									</option>
								</select>
							</div>
						</div>
					</div>

					<h5 class="mt-4 mb-0">
						Assinatura atual:
					</h5>
					<div v-if="corporate_subscription" class="col-12 p-0 col-12 px-0 mt-2 d-flex flex-column align-items-center">
						<div class="col-12 border p-3_5">
							<div class="col-12 px-0 d-flex align-items-center justify-content-between">
								<div class="col-10 px-0">
									<div class="col-12 px-0 d-flex align-items-center">
										<h6 class="m-0">
											Licença {{ corporate_subscription.plan.nickname }} ({{ ParseInterval(corporate_subscription.plan.interval) }})
										</h6>
										<small class="ml-2 badge"
											:class="{
												'badge-success': corporate_subscription.status === 'active',
											}"
										>
											Ativa
										</small>
									</div>
									<p class="m-0">
										R$ {{ ParsePrice(corporate_subscription.plan.per_unit) }}/{{ corporate_subscription.plan.unit_name }}/{{ ParsePeriod(corporate_subscription.plan.interval) }}
									</p>
								</div>
							</div>
						</div>
						<div class="col-12 border p-0 my-5">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Usos durante o período
								</p>
								<p class="m-0 text-dark">
									<b>{{ corporate_subscription.usage.total }} laudo(s)</b>
								</p>
							</div>
							<hr class="my-0">
							<div class="col-12 p-3_5 d-flex align-items-center justify-content-between">
								<p class="m-0">
									Próxima data de cobrança
								</p>
								<p class="m-0 text-dark">
									<b>{{ corporate_subscription.next_invoice }}</b>
								</p>
							</div>
						</div>
					</div>
					<div v-else class="col-12 p-0 col-12 px-0 mt-2">
						Não possui.
					</div>
				</div>
			</div>
		</div>
		<div v-else class="col-12 px-0 mt-3 d-flex justify-content-center align-items-center">
			<p class="text-danger">
				Não foi possível carregar os dados sobre assinaturas.
			</p>
		</div>
  	</div>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'Subscriptions',
	props: {
		account: {
			type:			Object,
			default:		() => {}
		},
		is_registered: {
			type: 			Boolean,
			required: 		true,
			default:		false
		}
	},
	data() {
		return {
			content_loaded: 			false,
			subscriptions: 				[],
			plans:						[],
			selected_professional_plan: null,
			professional_subscription:	null,
			selected_corporate_plan: 	null,
			selected_corporate_organization_id: null,
			corporate_subscription:		null,
		}
	},
	computed: {
		has_at_least_one_subscription() {
			return this.subscriptions.length > 0
		},
		has_more_than_one_subscription() {
			return this.subscriptions.length > 1
		},
		licensed_plans() {
			return this.plans.filter(p => p.usage_type === 'licensed')
		},
		metered_plans() {
			return this.plans.filter(p => p.usage_type === 'metered')
		},
		organizations() {
			return _.orderBy(this.account.requester.filter(o => o.status), (r) => r.requester.name, 'asc')
		},
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		ParsePeriod(period) {
			let new_period = ''

			switch(period) {
				case 'month': new_period = 'mês'
					break;
				case 'year': new_period = 'ano'
					break;
			}

			return new_period
		},
		ParseInterval(interval) {
			let new_interval = ''

			switch(interval) {
				case 'month': new_interval = 'Mensal'
					break;
				case 'year': new_interval = 'Anual'
					break;
			}

			return new_interval
		},
		ParsePrice(price) {
			return price ? (price / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'Isento'
		},
		ParseDate(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		ParseDateTime(date) {
			return moment(date).format('DD/MM/YYYY HH:mm');
		},
		GetBrand(brand) {
			const item = this.all_cards.find((e) => e.name === brand.toLowerCase())

			if(item) {
				let finalBrand = require(`@/assets/svg/brands/${item.svg}.svg`)

				return finalBrand
			}

			return false
		},
		async GetPlans() {
			return await window.api.call('get', '/gateway/get-prices')
				.then(({data}) => {
					this.plans = data.prices;
				})
		},
		async GetSubscriptions() {
			const vm = this

			vm.content_loaded = false;
			
			return await window.api.call('get', `/gateway/subscriptions/${vm.account.id}`)
				.then(({data}) => {
					if(data.response) {
						vm.subscriptions = data.subscriptions;
	
						vm.professional_subscription = vm.subscriptions.find(s => s.plan.type === 'licensed') || null
						vm.selected_professional_plan = vm.professional_subscription ? vm.professional_subscription.plan.id : null

						vm.corporate_subscription = vm.subscriptions.find(s => s.plan.type === 'metered') || null
						vm.selected_corporate_plan = vm.corporate_subscription ? vm.corporate_subscription.plan.id : null
					}
				})
				.finally(() => {
					vm.content_loaded = true;
				})
		},
		async Subscribe(type) {
			const vm = this

			const selected_plan = type === 'corporate' ? vm.selected_corporate_plan : vm.selected_professional_plan
			const subscription = type === 'corporate' ? vm.corporate_subscription : vm.professional_subscription

			if(!selected_plan || subscription && (selected_plan === subscription.plan.id)) {
				Swal.fire({
					title: 		'Atenção',
					text: 		`Selecione um ${!selected_plan ? 'plano válido' : 'plano diferente do atual'} para continuar`,
					icon: 		'warning',
				})

				return
			}

			let isCancelCurrentSubscription = false;

			if(subscription) {
				await Swal.fire({
					title: 		'Atenção',
					text: 		'O usuário possui uma assinatura ativa, deseja alterar para o novo plano?',
					icon: 		'warning',
					showCancelButton: true,
					confirmButtonText: 'Sim',
					cancelButtonText: 'Não',
				})
					.then((result) => {
						isCancelCurrentSubscription = result.isConfirmed;
					})
			}

			if(subscription && isCancelCurrentSubscription) {
				if(!await vm.CancelCurrentSubscription()) {
					Swal.fire({
						title: 		'Ops...',
						text: 		'Erro ao cancelar plano atual',
						icon: 		'error',
					})
					
					return;
				}
			}

			vm.StartLoading();

			return await window.api.call('post', '/gateway/subscribe', {
				account_id: vm.account.id,
				price_id: selected_plan,
				organization_id: vm.selected_corporate_organization_id,
				trial_period_days: 0
			})
				.then(({data}) => {
					if(data.response) {
						Swal.fire({
							title: 		'Plano assinado com sucesso',
							icon: 		'success',
							toast: 		true,
							position:	'top',
							timerProgressBar: true,
							timer: 		3000,
						})

						vm.GetSubscriptions();

						return
					}

					Swal.fire({
						title: 		'Ops...',
						text: 		data.message || 'Erro ao assinar o novo plano',
						icon: 		'error',
					})
				})
				.finally(() => {
					vm.FinishLoading();
				})
		},
		async CancelCurrentSubscription() {
			const vm = this
			
			vm.StartLoading();

			return await window.api.call('post', '/gateway/cancel-subscription', {
				account_id: vm.account.id,
				subscription_id: vm.professional_subscription.id,
			})
				.then(({data}) => {
					return data.response;
				})
				.finally(() => {
					vm.FinishLoading();
				})
		},
		async StoreCustomer() {
			return await window.api.call('post', '/gateway/store-customer', {
				account_id: 	this.account.id,
				name: 			this.account.name,
				email: 			this.account.email,
			})
				.then(({data}) => {
					if(data.response) {
						return
					}

					Swal.fire({
						title: 		'Ops...',
						text: 		'Erro ao cadastrar o cliente no gateway de pagamento',
						icon: 		'error',
					})
				})
		}
	},
	async beforeMount() {
		if(!this.is_registered && this.account.account_type === 1) {
			if(!this.account.name || !this.account.email) {
				Swal.fire({
					title: 		'Atenção',
					text: 		'Preencha o nome e/ou e-mail do usuário para cadastrá-lo no gateway de pagamento',
					icon: 		'warning',
				})

				this.content_loaded = true;
				return
			}

			await this.StoreCustomer();
		}

		await this.GetPlans();
		await this.GetSubscriptions();
	}
}
</script>

<style lang="css" scoped>
.p-3_5 {
	padding: 1.3rem !important;
}

.container {
	overflow: auto;
}

.mh-550 {
	max-height: 550px !important;
}
</style>