<template>
    <div class="col-12">
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="col-12 d-flex justify-content-center">
                <h2 class="">Cadastrar um novo autor</h2>
            </div>
            <AuthorForm 
                v-model="author"
                :mode="'new'"
                @save="UpdateAuthor"
            />
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import AuthorForm from './components/Form'

import Swal from 'sweetalert2'

export default {
    name: "NewAuthor",
    components: {
        AuthorForm
    },
    data () {
        return {
            author: {
                nome:           '',
                cargo:          '',
                avatar:         null,
            }
        }
    },
    computed: {
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading', 'GetFaqItems'
        ]),
        async UpdateAuthor(val)
        {
            this.author = val

            await this.SaveAuthor()
        },
        async SaveAuthor()
        {
            const vm = this

            const formData = new FormData()

            Object.keys(vm.author).map((key, index) => {
                if(key != 'avatar' && vm.author[key])
                {
                    formData.append(key, vm.author[key])
                }
            })

            if(vm.author.avatar)
            {
                formData.append('avatar', vm.author.avatar, 'avatar.jpg')
            }

            vm.StartLoading()

            return await window.api.call('post', '/faq/save-author', formData)
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            icon:       'success',
                            title:      'Autor salvo com sucesso!',
                            toast:      true,
                            position:   'top',
                            timer:      4000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'ManageAuthors' })

                        return
                    }

                    Swal.fire({
                        icon:       'error',
                        title:      'Erro ao salvar o autor.',
                        toast:      true,
                        position:   'top',
                        showConfirmButton: false,
                        timer:      3000,
                        timerProgressBar: true
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        }
    },
    beforeMount() {

    }
    
}
</script>

<style>

</style>