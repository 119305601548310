import Swal from 'sweetalert2'

class Helpers {
	constructor () 
	{}

	ValidaCPF (cpf) 
	{
		if(!cpf) return false;

		cpf 		= cpf.replace(/[^\d]+/g,'');

		if(cpf == ''){
			return false;
		} 

		let numeros, digitos, soma, i, resultado, digitos_iguais;

		digitos_iguais = 1;

		if (cpf.length < 11){
			return false;
		}

		for (i = 0; i < cpf.length - 1; i++)
		{
			if (cpf.charAt(i) != cpf.charAt(i + 1))
			{
				digitos_iguais = 0;
				break;
			}
		}

		if (!digitos_iguais)
		{
			numeros 	= cpf.substring(0,9);
			digitos 	= cpf.substring(9);
			soma 		= 0;

			for (i = 10; i > 1; i--)
			{
				soma += numeros.charAt(10 - i) * i;
			}

			resultado 	= soma % 11 < 2 ? 0 : 11 - soma % 11;

			if (resultado != digitos.charAt(0)){
				return false;
			}

			numeros 	= cpf.substring(0,10);
			soma 		= 0;

			for (i = 11; i > 1; i--)
			{
				soma += numeros.charAt(11 - i) * i;
			}

			resultado 	= soma % 11 < 2 ? 0 : 11 - soma % 11;

			if (resultado != digitos.charAt(1)){
				return false;
			}

			return true;

		}else{
			return false;
		}
	}

	ValidaCNPJ (cnpj)
	{
 
		cnpj 		= cnpj.replace(/[^\d]+/g,'');

		if(cnpj == '')
		{
			return false;
		} 

		if (cnpj.length != 14){
			return false;
		}

		if (cnpj == "00000000000000" || 
			cnpj == "11111111111111" || 
			cnpj == "22222222222222" || 
			cnpj == "33333333333333" || 
			cnpj == "44444444444444" || 
			cnpj == "55555555555555" || 
			cnpj == "66666666666666" || 
			cnpj == "77777777777777" || 
			cnpj == "88888888888888" || 
			cnpj == "99999999999999")
		{
			return false;
		}

		let i;
		let tamanho 		= cnpj.length - 2;
		let numeros 		= cnpj.substring(0,tamanho);
		let digitos 		= cnpj.substring(tamanho);
		let soma 			= 0;
		let pos 			= tamanho - 7;

		for (i = tamanho; i >= 1; i--) 
		{
			soma 		+= numeros.charAt(tamanho - i) * pos--;

			if (pos < 2)
			{
				pos 	= 9;
			}
		}

		let resultado 			= soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(0)){
		return false;
		}
		
		tamanho 		= tamanho + 1;
		numeros 		= cnpj.substring(0,tamanho);
		soma 			= 0;
		pos 			= tamanho - 7;

		for (i = tamanho; i >= 1; i--) 
		{
			soma 		+= numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
			{
				pos 		= 9;
			}
		}

		resultado 			= soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(1))
		{
			return false;
		}
		
		
		return true;
	}

	RemoveAcento (strToReplace)
	{

		let str_acento 		= "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ"
		let str_sem_acento 	= "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC"
		let nova 			= ""

		for (let i = 0; i < strToReplace.length; i++) 
		{
			if (str_acento.indexOf(strToReplace.charAt(i)) != -1) {
				nova += str_sem_acento.substr(str_acento.search(strToReplace.substr(i, 1)), 1);
			} else {
				nova += strToReplace.substr(i, 1);
			}
		}
		return nova;
	}

	Slugify(string){

		let slugged_string 		= string.toLowerCase()
			.replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
			.replace(/[èÈéÉêÊëË]+/g, 'e')
			.replace(/[ìÌíÍîÎïÏ]+/g, 'i')
			.replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
			.replace(/[ùÙúÚûÛüÜ]+/g, 'u')
			.replace(/[ýÝÿŸ]+/g, 'y')
			.replace(/[ñÑ]+/g, 'n')
			.replace(/[çÇ]+/g, 'c')
			.replace(/[ß]+/g, 'ss')
			.replace(/[Ææ]+/g, 'ae')
			.replace(/[Øøœ]+/g, 'oe')
			.replace(/[%]+/g, 'pct')
			.replace(/\s+/g, '-')
			//.replace(/[^\w\-]+/g, '')
			//.replace(/\-\-+/g, '-')
			.replace(/^-+/g, '')
			.replace(/-+$/g, '');

		return slugged_string
	}

	TodaySplited(type = 'obj')
	{
		let data 	= new Date()

		switch(type)
		{
			case 'obj':

				return {
					ano: 		data.getFullYear(),
					mes: 		(data.getMonth() + 1).toString().padStart(2,'0'),
					dia: 		data.getDate().toString().padStart(2,'0'),
					hora: 		data.getHours().toString().padStart(2,'0'),
					minuto: 	data.getMinutes().toString().padStart(2,'0'),
					segundo: 	data.getSeconds().toString().padStart(2,'0')
				}

			case 'arr':

				return [
					data.getFullYear(),
					(data.getMonth() + 1).toString().padStart(2,'0'),
					data.getDate().toString().padStart(2,'0'),
					data.getHours().toString().padStart(2,'0'),
					data.getMinutes().toString().padStart(2,'0'),
					data.getSeconds().toString().padStart(2,'0')
				]
		}	
	}

	SlugifyWithDiff(string){
		let date 				= new Date()
		let diff 				= String(date.getMinutes()) + String(date.getHours()) + String(date.getSeconds()) + String(date.getMonth())

		let slugged_string 		= this.Slugify(string)

		return diff + '-' + slugged_string
	}

	GetDifferenceBetweenTimes(init_time, end_time, split = null)
	{
		if(!split)
		{
			split 	= this.TodaySplited('arr')
		}

		let init_split 	= init_time.split(':')

		let init 		= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(init_split[0]),parseInt(init_split[1]))
		
		let end_split 	= end_time.split(':')

		let end 		= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(end_split[0]),parseInt(end_split[1]))

		if(this.TimeToMsec(end_time) < this.TimeToMsec(init_time))
		{
			end 			= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(end_split[0]),parseInt(end_split[1]))
			end.setDate(end.getDate() + 1)
		}

		let msec 			= end - init 

		return {
			total: 	this.MsecToTime(msec),
			msec: 	msec
		}
	}

	OneIsBiggerThanTwoOrEqual(init_time, end_time, split = null)
	{
		if(!split)
		{
			split 	= this.TodaySplited('arr')
		}

		let init_split 	= init_time.split(':')

		let init 		= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(init_split[0]),parseInt(init_split[1]))
		
		let end_split 	= end_time.split(':')

		let end 		= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(end_split[0]),parseInt(end_split[1]))

		return init >= end 
	}

	MinutesToTime(min)
	{
		let hh 			= Math.floor(min / 60);
		let mm 			= Math.floor(min % 60);

		return `${(hh * 1).toString().padStart(2, '0')}h${mm.toString().padStart(2, '0')}min`
	}

	MsecToTime(msec)
	{
		let hh 			= Math.floor(msec / 1000 / 60 / 60);
		msec 			-= hh * 1000 * 60 * 60;
		let mm 			= Math.floor(msec / 1000 / 60);
		msec 			-= mm * 1000 * 60;
		let ss 			= Math.floor(msec / 1000);
		msec 			-= ss * 1000;

		return `${(hh * 1).toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`
	}

	TimeToMsec(time)
	{
		let split 		= time.toString().split(':')

		time 			= parseInt(split[0]) * 1000 * 60 * 60
		time 			= time + parseInt(split[1]) * 1000 * 60

		return time.toString()
	}

	GetEndTime(init_time,minutes)
	{	
		return this.MsecToTime(this.TimeToMsec(init_time) + (minutes * 1000 * 60))
	}


	GetIntervalBlocksParsedToCalendar(diff, weekday)
	{	
		let interval_msec			= diff.msec / parseInt(weekday.blocks)
		let interval_minutes 		= Math.floor(interval_msec / 1000 / 60)
		let temp 					= this.TimeToMsec(weekday.init_time)
		let interval_arr 			= []

		for (let i = 0; i < parseInt(weekday.blocks); i++) 
		{
			interval_arr.push({ 
				value: 		i + 1,
				in_use: 	0,
				name: 		this.MsecToTime(temp),
				end: 		this.MsecToTime(temp + ((interval_minutes - 1) * 1000 * 60)),
				duration: 	interval_minutes
			})

			temp 				= temp + interval_msec
		}

		return interval_arr
	}

	GenerateBlockSchedule(init, end, diff_msec)
	{
		const split 		= this.TodaySplited('arr')
		const init_split 	= init.split(':')

		let hora 			= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(init_split[0]),parseInt(init_split[1]))
		hora.setMinutes(hora.getMinutes() - 5)

		let times 			= []
		
		for (let i = 0; i < (diff_msec / 60 / 1000 / 5); i++)
		{	
			let temp 			= hora.setMinutes(hora.getMinutes() + 5)

			temp 				= new Date(temp)

			temp 				= (temp.getHours().toString().padStart(2,'0') + ':' + temp.getMinutes().toString().padStart(2,'0')).toString()

			times.push({
				id: 		(i + 1),
				time: 		temp,
				duration: 	'00:05',
				is_block: 	false,
				owner: 	 	null,
				selected: 	false
			})

			temp 				= temp.split(':')

			hora 				= new Date(split[0],(parseInt(split[1]) - 1),split[2],parseInt(temp[0]),parseInt(temp[1]))
		}

		return times
	}

	ParseDate(date)
	{
		let data 	= new Date(date)

		return `${data.getDate().toString().padStart(2,'0')}/${(data.getMonth() + 1).toString().padStart(2,'0')}/${data.getFullYear()} às ${data.getHours().toString().padStart(2,'0')}:${data.getMinutes().toString().padStart(2,'0')}`
	}

	FormatDate(date) 
	{
		if (!date) return null

		const [year, month, day] = date.split('-')

		return `${day}/${month}/${year}`
	}

	GetPatientAndIdDoc(patient)
	{
		let temp			= patient

		if(temp.outros)
		{
			temp.tipo_id	= 'OUTROS'
			temp.numero_id	= temp.outros
		}

		if(temp.sus)
		{
			temp.tipo_id	= 'SUS'
			temp.numero_id	= temp.sus
		}

		if(temp.rg)
		{
			temp.tipo_id	= 'RG'
			temp.numero_id	= (temp.rg + temp.rg_uf).toString().toUpperCase()
		}

		if(temp.cpf)
		{
			temp.tipo_id	= 'CPF'
			temp.numero_id	= temp.cpf
		}

		return temp
	}

	Base64ToFile(base64, mime, name) 
	{
		mime 			= mime || ''
		let sliceSize 	= 1024

		base64 			= base64.replace('data:image/png;base64,','');
		base64 			= base64.replace('data:image/webp;base64,','');
		base64 			= base64.replace('data:image/jpg;base64,','');
		base64 			= base64.replace('data:image/jpeg;base64,','');

		let byteChars 	= window.atob(base64)
		let byteArrays 	= []

		for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) 
		{
			let slice 			= byteChars.slice(offset, offset + sliceSize)

			let byteNumbers 	= new Array(slice.length)

			for (let i = 0; i < slice.length; i++) 
			{
				byteNumbers[i] 			= slice.charCodeAt(i)
			}

			let byteArray 		= new Uint8Array(byteNumbers)

			byteArrays.push(byteArray)
		}

		return new File(byteArrays , name , { type: mime })
	
	}

	focusInvalid(structure, element) {
		const arr = Object.keys(structure)
		
		for(let key in arr)
		{
			const input = arr[key]

			if(structure[input].$error) {
				element.$refs[input].focus();

				break;
			}
		}
	}

	errorMessage(title, message, timer, {...params}) {
		return Swal.fire({
			icon: 'error',
			title: title ? title : 'Ops...',
			text: message,
			timer: timer ? timer : false,
			timerProgressBar: timer ? true : false,
			...params
		})
	}

	errorMessageTop(message, timer, {...params}) {
		return Swal.fire({
			icon: 'error',
			title: ' ',
			text: message,
			timer: timer ? timer : false,
			timerProgressBar: timer ? true : false,
			...params,
			toast: true,
			position: 'top'
		})
	}

	successMessage(title, message, timer, {...params}) {
		return Swal.fire({
			icon: 'success',
			title: title ? title : 'Sucesso!',
			text: message,
			timer: timer ? timer : false,
			timerProgressBar: timer ? true : false,
			...params
		})
	}

	successMessageTop(message, timer, {...params}) {
		return Swal.fire({
			icon: 'success',
			title: ' ',
			text: message,
			timer: timer ? timer : false,
			timerProgressBar: timer ? true : false,
			...params,
			toast: true,
			position: 'top'
		})
	}

	ParseDateCreatedAt(date)
	{
		if(date)
		{
			let new_date = date.substr(0, 10).split('-').reverse().join('/')

			return new_date
		}
		return ''
	}
}

export default Helpers;