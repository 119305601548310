<template>
	
	<div>
 
		<div class="container-fluid p-0 my-3">
			
			<div class="text-center">

				<h4 class="d-inline">Pessoas cadastradas</h4>
<!-- 
				<div class="d-inline float-right">

					<a class="p-4 text-success" href="#" @click.prevent="AddPerson()">
						<i class="fa fa-plus" aria-hidden="true"></i>
					</a>

				</div> -->

			</div>

		</div>

		<div class="card rounded-lg bg-light border-no overflow-auto p-3" id='person-list'>
			<div v-for="person in persons" :key="person.id" >
				<div class="my-4 d-flex align-items-center justify-content-between">
					<div>	
						<p class="m-0"><b>{{ person.name }}</b> {{ person.schema }}</p>
						<small class="text-muted">{{ ParseDate(person.created_at) }} ● {{ person.id }}</small>
					</div>
					<div>
						<button type="button" class="btn btn-outline-danger py-2 px-3" @click.prevent="DeletePerson(person)">
							<i class="fas fa-trash"></i>
						</button>
					</div>
				</div>

			</div>

			<div v-if="persons.length == 0" class="d-flex justify-content-center align-items-center">
				
				Nenhuma pessoa cadastrada

			</div>

		</div>

	</div>

</template>

<script>

import moment from 'moment'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
	name: 'IntegrationPersons',
	data() {
		return {
			persons: []
		}	
	},
	props: {
		integration_account: { 
			type: Object,
			default: () => {}
		}
	},
	watch: {
		integration_account: {
			immediate: true,
			deep: true,
			handler(val) {
				this.persons = (Array) (val.persons)[0]
			}
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		ParseDate(date) {
			return moment(date).format('DD/MM/Y H:m')
		},
		DeletePerson(person) {

			const vm = this

			Swal.fire({
				title: 'Deletar essa pessoa?',
				html: `Tenha certeza de que está deletando a pessoa <b>${person.name}</b>`,
				icon: 'question',
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: 'Sim, deletar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {  

				if (result.isConfirmed ) { 

					vm.StartLoading()

					window.api.call('post', 'delete-person', {
						person_id: person.id
					}).then(({data}) => {
							
						if ( ! data.response ) {
							
							Swal.fire({
								title: 'Ops!',
								text: 'Nao foi possível deletar a pessoa: ' + data.message, 
								icon: 'error'
							})
							return 
						}

						Swal.fire({ 
							text: 'Deletado!',
							icon: 'success',
							position: 'top',
							toast: true,
							timer: 5000
						})
						
						this.persons.splice(this.persons.indexOf(person), 1);

					}).finally((err) => {
						this.FinishLoading()
					});

				}

			})

		},
		AddPerson() {

			Swal.fire

		}
	}
}
</script>

<style lang="css" scoped>
	#person-list {
		color: var(--color-font);
		height: 300px;
		width: 510px;
	}
</style>