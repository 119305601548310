<template>
	<div class=""
		:class="{ 
			'col-12 px-0 container': account_type === 1,
			'container-large': account_type === 2
	 	}">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-10 px-0 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<span>{{ account_type === 1 ? 'Contas' : 'Organizações' }}</span>
					<h4 class="font-24 font-md-32 m-0">
						Todas as {{ account_type === 1 ? 'contas' : 'organizações' }}
					</h4>
				</div>
			</div>
			<div class="d-flex justify-content-center"
				:class="{ 
					'col-12 mt-3 align-items-stretch': account_type === 1,
					'col-8 my-3 align-items-center': account_type === 2
				}">
				<div class="col-9 pb-2" :class="{ 'px-0': account_type === 1 }">
					<div class="col mb-4 mb-md-0" :class="{ 'px-0': account_type === 1 }">
						<div class="form-group m-0 position-relative">
							<input 
								type="search" 
								class="form-control pl-5" 
								placeholder="Buscar..."
								v-model="search"
							>
							<i 
								class="far fa-search font-14 position-absolute ps-l-0 ps-b--3 ml-3 ps-v-center"
							></i>
						</div>
					</div>
				</div>
				<div v-if="account_type === 1" class="col-2 px-3 d-flex justify-content-center align-items-start">
					<div class="col-8 px-0 d-flex justify-content-end align-items-stretch">
						<button
							class="btn btn-primary col px-3"
							@click="OpenModal('new-account')"
						>
						<i class="far fa-plus mr-3"></i>
							Novo
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md mb-4">
				<DataTables
					:headers="headers"
					:search="search"
					v-model="accounts"
					:tr-button="true"
					max_height="700px"
					@by-click-on-tr="Show"
					:scrollUpdate="true"
					@load-table="UpdateTable"
				>
					<template v-slot:body="{ item }">
						<td style="width: 524px">
							<b>{{ item.name.toUpperCase() }}</b>
						</td>
						<td v-if="account_type === 2">
							{{ item.owner }}
						</td>
						<td v-if="account_type === 2"
							scope="col" class="align-middle"
						>
							{{ item.subscription ? item.subscription.plan.name : '-' }}
						</td>
						<td scope="col" class="align-middle" style="width: 104px">
							<span 
                                class="badge font-16 p-2"
                                :class="{
                                    'badge-success': 	item.status,
                                    'badge-danger': 	!item.status
                                }"
                            >
                                {{ item.status ? 'ativo' : 'inativo'}}
                            </span>
						</td>						
						<td>{{ item.document_number }}</td>						
						<td>{{ ParseDate(item.created_at) }}</td>
					</template>
				</DataTables>
			</div>
		</div>
		<div v-if="account_type === 1" 
			class="modal fade" 
			id="modal-new-account"
		>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header px-5 py-4">
						<h4 class="text-center m-0">Novo usuário</h4>
						<button type="button" class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" data-dismiss="modal">
							<i class="fas fa-times"></i>
						</button>	
					</div>
					<div class="modal-body">
						<div class="col-12 d-flex">
							<div class="col-6 pl-0 d-flex flex-column justify-content-between align-items-center data-form">
								<h6 class="col px-0 mb-2">
									<b>Conta</b>
								</h6>
								<div class="form-group col px-0 pb-4 mb-0">
									<label for="name">Nome</label>
									<input 
										type="text"
										class="form-control p-3"
										:class="{ 'is-invalid': $v.account.name.$error }"
										id="name"
										placeholder="Nome completo"
										v-model="$v.account.name.$model"
									>
									<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.name.required">
										Obrigatório
									</div>
								</div>
								<div class="form-group col px-0 pb-4 mb-0">
									<label for="document_number">CPF</label>
									<input 
										type="phone"
										class="form-control p-3"
										:class="{ 'is-invalid': $v.account.document_number.$error }"
										id="document_number"
										placeholder="111.222.333-44"
										v-mask="'###.###.###-##'"
										v-model="$v.account.document_number.$model"
									>
									<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.document_number.required">
										Obrigatório
									</div>
									<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-else-if="!$v.account.document_number.CPFValidation">
										CPF Inválido
									</div>
								</div>
								<div class="form-group col px-0 pb-4 mb-0">
									<label for="email">E-mail</label>
									<input 
										type="email"
										class="form-control p-3"
										:class="{ 'is-invalid': $v.account.email.$error }"
										id="email"
										placeholder="teste@exemplo.com.br"
										v-model="$v.account.email.$model"
									>
									<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.email.required">
										Obrigatório
									</div>
									<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-else-if="!$v.account.email.email">
										E-mail Inválido
									</div>
								</div>

								
								<div class="form-group col px-0 pb-4 mb-0 mt-2 d-flex align-items-center">
									<input 
										type="checkbox"
										id="is_doctor"
										class="col-1"
										v-model="account.is_doctor"
									>
									<label for="is_doctor" class="m-0 px-0">É médico</label>
								</div>

								<transition name="scalling">
									<div v-if="account.is_doctor" id="doctor_form" class="col px-0">
										<div class="form-group col px-0 pb-4 mb-0">
											<label for="title">Título</label>
											<select 
												id="title" 
												name="title"
												class="form-control p-3"
												:class="{ 'is-invalid': $v.account.title.$error }"
												v-model="$v.account.title.$model"
											>
												<option value="" selected>Selecione...</option>
												<option value="Dr.">Dr.</option>
												<option value="Dra.">Dra.</option>
												<option value="Sr.">Sr.</option>
												<option value="Sra.">Sra.</option>
											</select>
											<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.title.required">
												Obrigatório
											</div>
										</div>
										<div class="form-group col px-0 pb-4 mb-0">
											<label for="name_stamp">Nome</label>
											<input 
												type="text"
												id="name_stamp"
												class="form-control p-3"
												:class="{ 'is-invalid': $v.account.name_stamp.$error }"
												v-model="$v.account.name_stamp.$model"
											>
											<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.name_stamp.required">
												Obrigatório
											</div>
										</div>
										<div class="form-group col px-0 pb-4 mb-0">
											<label for="crm">CRM</label>
											<input 
												type="phone"
												id="crm"
												class="form-control p-3"
												:class="{ 'is-invalid': $v.account.crm.$error }"
												v-mask="['######']"
												v-model="$v.account.crm.$model"
											>
											<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.crm.required">
												Obrigatório
											</div>
										</div>
										<div class="form-group col px-0 pb-4 mb-0">
											<label for="uf">UF</label>
											<select
												id="uf"
												name="uf"
												class="form-control p-3"
												:class="{ 'is-invalid': $v.account.uf.$error }"
												v-model="$v.account.uf.$model"
											>
												<option value="" selected>Selecione...</option>
												<option v-for="(uf, index) in ufs" 
													:value="uf.initials" :key="index">{{ uf.initials }} - {{ uf.name }}</option>
											</select>
											<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.uf.required">
												Obrigatório
											</div>
										</div>
										<div class="form-group col px-0 pb-4 mb-0">
											<label for="specialty">Especialidade</label>
											<input 
												type="text"
												id="specialty"
												class="form-control p-3"
												:class="{ 'is-invalid': $v.account.specialty.$error }"
												v-model="$v.account.specialty.$model"
											>
											<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.account.specialty.required">
												Obrigatório
											</div>
										</div>
									</div>
								</transition>


								<hr>
								<h6 class="col px-0 mb-2">
									<b>Organização</b>
								</h6>
								<div class="form-group col px-0 pb-4 mb-0">
									<label for="org_name">Nome</label>
									<input 
										type="text"
										class="form-control p-3"
										:class="{ 'is-invalid': $v.organization.name.$error }"
										id="org_name"
										placeholder="Nome da Organização"
										v-model="$v.organization.name.$model"
									>
									<div class="invalid-feedback position-absolute ps-l-0 ps-b-0" v-if="!$v.organization.name.required">
										Obrigatório
									</div>
								</div>
							</div>
							<div class="col-6 pr-0 d-flex flex-column justify-content-start align-items-start subscription-form">
								<h6 class="col px-0 mb-2">
									<b>Assinatura</b>
								</h6>
								<div class="form-group col px-0 pb-4 mb-0">
									<label for="subscription">Plano</label>
									<select
										id="subscription"
										class="form-control p-3"
										v-model="subscription.price_id"
									>
										<option v-for="(price) in prices"
											:value="price.id"
											:key="price.id"
										>
											{{ price.nickname }}
										</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer mt-3">
						<button 
							type="button"
							class="btn btn-primary py-3"
							@click="SaveAccount()"
						>
							Salvar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	import { required, email, requiredIf } from 'vuelidate/lib/validators'
	import Swal from 'sweetalert2'
	const _ = require('lodash')

	import Helpers from './../../helpers'
	const helpers = new Helpers();

	const CPFValidation = (value, vm) => helpers.ValidaCPF(value)

	export default {

		name: 'Index',
		props: {
		},
		data () {
			return {
				search: 	'',
				accounts: 	[],
				headers: 	[
					{ order: 1, text: 'Nome', value: 'name', sortable: true },
					{ order: 4,  text: 'Status', value: 'status', sortable: true },
					{ order: 5,  text: 'CPF/CNPJ', value: 'document_number', sortable: true },
					{ order: 6,  text: 'Criação', value: 'created_timestamp', sortable: true}
				],
				ufs:			window.statesBR,
				type:			1,
				account:		{
					name:				'',
					document_number:	null,
					email:				'',
					is_doctor:			false,
					title:				'',
					name_stamp:			'',
					crm:				null,
					uf:					'',
					specialty:			'',
				},
				organization:	{
					name:				''
				},
				subscription:	{
					price_id:			null,
				},
				prices: 		[],
			}
		},
		computed: {
			...mapGetters('auth', [
				'admin'
			]),
			...mapGetters('system', [
				'online_accounts'
			]),
			account_type() {
				return this.$route.name == 'Accounts' ? 1 : 2
			},
			selected_price() {
				if(this.prices && this.subscription.price_id) {
					return this.prices.find(price => price.id == this.subscription.price_id)
				}
				
				return null;
			},
			filtered_online_accounts() {
				return _.orderBy(this.online_accounts.filter(acc => acc.type === 'account'), 'name');
			}
		},
		validations() {
			let obj = {
				account: {
					name: 				{
						required
					},
					document_number: 	{
						required,
						CPFValidation
					},
					email: 				{
						required,
						email
					},
					title: 				{
						required: requiredIf(() => this.account.is_doctor)
					},
					name_stamp: 		{
						required: requiredIf(() => this.account.is_doctor)
					},
					crm: 				{
						required: requiredIf(() => this.account.is_doctor)
					},
					uf: 				{
						required: requiredIf(() => this.account.is_doctor)
					},
					specialty: 			{
						required: requiredIf(() => this.account.is_doctor)
					},
				},
				organization: {
					name: 				{
						required
					}
				}
			}

			return obj;
		},
		watch: {
			selected_price: {
				handler(val) {
					if(val) {
						this.type = this.selected_price.usage_type === 'metered' ? 2 : 1
					}
				}
			},
			'account.is_doctor': {
				handler(val) {
					if(val) {
						this.account.name_stamp = this.account.name
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetPrices() {
				this.StartLoading()

				return window.api.call('get', '/gateway/get-prices')
					.then(({data}) => {
						if(data.response) {
							this.prices = data.prices

							if(this.prices.length > 0) {
								this.subscription.price_id = this.prices[0].id
							}
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			GetAccounts()
			{
				this.StartLoading()

				return window.api.call('post', '/get-accounts', {
					account_type: this.account_type
				})
					.then(({data}) => {
						if(data.response) {
							this.accounts 	= _.orderBy(data.accounts, ['status', 'name'], ['desc', 'asc'])

							if(this.account_type === 2) {
								this.headers.push({ order: 2, text: 'Criado por', value: 'owner', sortable: true },)
								this.headers.push({ order: 3, text: 'Plano', value: 'subscription.plan.name', sortable: true })
							}

							this.headers = _.orderBy(this.headers, [(e) => e.order], 'asc')
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			Show(account)
			{
				this.$router.push({ name: 'ShowAccountProfile', params: {
					account_id: 	account.id
				}})
			},
			async UpdateTable(limit)
        	{
				const vm = this

				if(limit > vm.accounts.length)
				{
					Swal.fire({
						title: 				"Não há mais contas para exibir",
						toast: 				true,
						position: 			'top',
						icon:				'info',
						timer:				4000,
						timerProgressBar:	true
					})
				}
			},
			ParseDate(date)
			{
				if(date)
				{
					let new_date = date.substr(0, 10).split('-').reverse().join('/')

					return new_date
				}
				return ''
			},
			SaveAccount() {
				const vm = this

				vm.$v.$touch()

				if(vm.$v.$invalid) {
					return
				}

				vm.StartLoading()

				return window.api.call('post', '/create-account', {
					type:			vm.type,
					account: 		vm.account,
					organization: 	vm.organization,
					subscription: 	vm.subscription,
				})
					.then(async ({data}) => {
						if(data.response) {
							await vm.CloseModal('new-account')
							await vm.GetAccounts()

							return
						}
						
						Swal.fire({
							title: 				"Erro ao criar conta",
							toast: 				true,
							position: 			'top',
							icon:				'error',
							timer:				4000,
							timerProgressBar:	true
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			OpenModal(id) {
				window.jQuery(`#modal-${id}`).modal('show');

				if(id === 'new-account') {
					this.GetPrices()
				}
			},
			CloseModal(id) {
				window.jQuery(`#modal-${id}`).modal('hide');
			}
		},
		beforeMount()
		{
			this.GetAccounts()
		}
	}
</script>

<style lang="css" scoped>

input#is_doctor {
	height: 1.2rem;
}
.modal-dialog {
	max-width: 800px;
}

.modal-body {
	max-height: 538px;
	overflow: auto;
}

#doctor_form {
	/* overflow: hidden; */
	transform-origin: top;
}

.scalling-enter-active {
	animation: scalling .5s;
}
.scalling-leave-active {
	animation: scalling .5s reverse;
}

@keyframes scalling {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

hr {
	border: 0;
	clear: both;
	display: block;
	width: 100%;
	background-color: var(--hr);
	height: 1px;
}

.data-form {
	border-right: 1px solid var(--hr);
}

.subscription-form .col {
	flex-grow: unset !important;
}

.form-group {
	height: 102px;
}
</style>