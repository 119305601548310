export default {
	request_loading:		0,
	modules: 				[],
	modalities: 			[],
	all_articles:			[],
	all_authors:			[],
	all_categories:			[],
	all_sections:			[],
	online_accounts:		[],
	menu_options: 			[
		{ role: 1, icon: 'fal fa-users', text: 'Usuários', route: 'Accounts'},
		{ role: 2, icon: 'fal fa-hospital', text: 'Organizações', route: 'Organizations'},
		{ role: 3, icon: 'fal fa-users-crown', text: 'Usuários ERP', route: 'Users'},
		// { role: 4, icon: 'fal fa-database', text: 'Templates', route: 'TemplateManagement'},
		{ role: 5, icon: 'fal fa-ballot-check', text: 'Planos', route: 'Plans'},
		{ role: 6, icon: 'fal fa-question-circle', text: 'FAQ', route: 'FAQPanel'},
		{ role: 7, icon: 'fal fa-ticket-alt', text: 'Cupons', route: 'Coupons'},
		{ role: 8, icon: 'fas fa-th', text: 'Menus', route: 'Menus'},
		{ role: 9, icon: 'far fa-lightbulb-on', text: 'Integração', route: 'Integration'},
		{ role: 10, icon: 'far fa-archive', text: 'Migração', route: 'MoveTemplate'},
	]
} 