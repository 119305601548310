<template>
	<div>
		<p class="text-center">Aqui você pode definir um template médico como padrão ou até restaurar um template médico ao padrão da organização.</p>
		<template v-if="providers.length > 0">
			<div class="form-group position-relative mb-0">
				<input 
					type="search" 
					class="form-control border-no pl-5" 
					placeholder="Buscar..."
					v-model="search"
				>
				<i class="far fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center"></i>
			</div>
			<template v-if="filtered_templates.length > 0">
				<div 
					class="border px-4 py-3 mt-3 rounded-lg"
					v-for="template in filtered_templates"
					:key="template.id"
				>
					<div class="row justify-content-md-between align-items-center">
						<div class="col mb-4 mb-md-0">
							<p class="m-0 text-dark"><b>{{ template.provider.name.toUpperCase() }}</b></p>
							<small>Template: <b>{{ template.workgroup_schema.toUpperCase() }}</b></small>							
						</div>
						<div class="col-auto">
							<button
								class="btn btn-light-primary btn-sm px-3 mr-2"
								@click="ConfirmDecision('set-default', template)"
							> 
								definir como padrão
							</button>
							<button
								class="btn btn-light-info btn-sm px-3" 
								@click="ConfirmDecision('restore-template', template)"
							> 
								restaurar 
							</button>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="border px-4 py-3 mt-3 rounded-lg">
					<div class="row justify-content-md-between align-items-center">
						<div class="col mb-4 mb-md-0 text-center">
							Sua busca por "{{ search }}" retornou sem resultado.
						</div>
					</div>
				</div>			
			</template>
		</template>
		<template v-else>
			<div class="border px-4 py-3 mt-3 rounded-lg">
				<div class="row justify-content-md-between align-items-center">
					<div class="col mb-4 mb-md-0 text-center">
						Não há templates para exibir.
					</div>
				</div>
			</div>	
		</template>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Templates',
		props: {
			providers: 			{
				type: 				Array,
				default: 			() => []
			},
			organization: 		{
				type: 				Object,
				default: 			() => {}
			}
		},
		data () {
			return {
				search: 			''
			}
		},
		computed: {
			filtered_templates()
			{
				const search 		= helpers.RemoveAcento(this.search.toLowerCase())

				return this.providers.filter( provider => {
					return helpers.RemoveAcento(provider.provider.name.toLowerCase()).includes(search)
				})
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),	
			ConfirmDecision(type, template)
			{
				Swal.fire({
					icon: 				'question',
					title: 				`${ type == 'set-default' ? 'Definir como padrão da organização' : 'Restaurar template ao padrão'}`,
					text: 				'Esta ação é IRREVERSIVEL e não poderá ser desfeita, deseja mesmo continuar ?',
					showConfirmButton: 	true,
					showCancelButton: 	true,
					confirmButtonText: 	'sim',
					cancelButtonText: 	'não',
					customClass: 		{
						confirmButton: 		'bg-danger',
					},
				}).then(async (res) => {

					if (res.isConfirmed)
					{
						switch(type)
						{
							case 'set-default':
								this.SetDefault(template)
							break
							case 'restore-template':
								this.RestoreTemplate(template)
							break
						}
					}
				})
			},
			SetDefault(template)
			{
				this.StartLoading()

				const obj 	= {
					source: 	{
						account_id: 		template.account_requester_id,
						schema: 			template.workgroup_schema,
						provider_id: 		template.account_provider_id,
						type: 				'WK'
					},
					options: 	{
						template: 					true,
						schedules_and_proceedings: 	false,
						configurations: 			false,
						flow: 						false
					},
					destiny: 	{
						account_id: 		this.organization.id,
						schema: 			this.organization.instances[0].config.schema_name,
						type: 				'PJ',
						only_set_default: 	true
					}
				}

				return window.api.call('post', '/set-default-template', obj)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 		'success',
								title: 		'Procedimento Concluido !',
								text: 		'Template padrão setado com sucesso.'
							})

						}else{

							Swal.fire({
								icon: 		'error',
								text: 		'Houve um erro ao concluir, contate o suporte.',
								title: 		'Eita!'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			RestoreTemplate(template)
			{
				this.StartLoading()

				const obj 	= {
					destiny: 	{
						account_id: 		template.account_requester_id,
						schema: 			template.workgroup_schema,
						provider_id: 		template.account_provider_id,
						type: 				'WK',
						only_restore: 		true
					},
					options: 	{
						template: 					true,
						schedules_and_proceedings: 	false,
						configurations: 			false,
						flow: 						false
					},
					source: 	{
						account_id: 		this.organization.id,
						schema: 			this.organization.instances[0].config.schema_name,
						type: 				'PJ',
					}
				}

				return window.api.call('post', '/restore-from-default-template', obj)
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 		'success',
								title: 		'Procedimento Concluido !',
								text: 		'Template restaurado com sucesso.'
							})

						}else{

							Swal.fire({
								icon: 		'error',
								text: 		'Houve um erro ao concluir, contate o suporte.',
								title: 		'Eita!'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			}
		}
	}
</script>

<style lang="css" scoped>
</style>

