<template>
	<div class="login-container">
		<Loading/>
		<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 col-ul-3 d-flex justify-content-center align-content-center">
			<div class="col-12">
				<div class="form-row align-items-center">
					<div class="col-12 text-center">
						<img :src="logo" width="250">
					</div>
					<div class="col-12">
						<div class="form-group">
							<label>CPF</label>
							<input 
								type="text" 
								class="form-control"
								v-mask="['###.###.###-##']"
								:class="{ 'is-invalid': $v.form.username.$error }"
								v-model.trim="$v.form.username.$model"
							>
							<div class="invalid-feedback">
								Obrigatório
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label>Senha</label>
							<input 
								type="password" 
								class="form-control"
								:class="{ 'is-invalid': $v.form.password.$error }"
								v-model.trim="$v.form.password.$model"
								@keyup.enter="Auth"
							>
							<div class="invalid-feedback" v-if="!$v.form.password.minLength">
								Precisa ter pelo menos 6 digitos
							</div>
							<div class="invalid-feedback" v-else-if="!$v.form.password.required">
								Obrigatório
							</div>
						</div>
					</div>
					<div class="col">
						<a href="#" class="text-primary">Recuperar senha</a>
					</div>
					<div class="col-auto">
						<button
							type="button"
							class="btn btn-primary px-5"
							@click="Auth"
						>
							entrar
							<i class="fas fa-arrow-right pl-3"></i>
						</button>
					</div>
				</div>
			</div>
		</div>		
	</div>
</template>

<script>
	import Loading from '../layout/Loading'
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import { required, minLength } from 'vuelidate/lib/validators'
	export default {

		name: 'Index',
		components: {
			Loading
		},
		data () {
			return {
				logo: 		require('../../assets/img/logo_original.png'),
				form: 		{
					username: 		'',
					password: 		''
				}
			}
		},
		validations: {
			form: 		{
				username: 	{
					required
				},
				password: 	{
					required,
					minLength: minLength(6)
				}
			}
		},
		methods: {
			...mapActions('auth', [
				'Login', 'LogoutContent'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async Auth()
			{
				this.$v.form.$touch()

				if(!this.$v.form.$invalid)
				{
					this.StartLoading()

					await this.LogoutContent()

					await this.Login(this.form)
						.then((data) => {
							if(data.response) {
								this.$router.push({ name: 'Home' })
							} else {
								if(data.message == 'inactive_user') {
									Swal.fire({
										icon: 	'error',
										title: 	'Eita!',
										text: 	'Usuário inativo!'
									})
								} else {
									Swal.fire({
										icon: 	'error',
										title: 	'Eita!',
										text: 	'Usuário e/ou senha incorretos, tente novamente.'
									})
								}
							}
						})
						.catch((data) => {
							Swal.fire({
								icon: 	'error',
								title: 	'Eita!',
								text: 	'Ocorreu um erro ao logar, tente novamente mais tarde.'
							})
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			}
		}
	}
</script>

<style lang="css" scoped>

@media (min-width: 2000px) {
	/* ul - ULTRA LARGE */
	.col-ul-3 {
		max-width: 20%;
	}
}

.login-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #1F2128 !important;
}
</style>