const requests 	= (state) => {
	return state.request_loading
}

const menu_options = (state) => {
	return state.menu_options
}

const modules 	= (state) => {
	return state.modules
}

const modalities 	= (state) => {
	return state.modalities
}

const all_articles	= (state) => {
	return state.all_articles
}

const all_authors	= (state) => {
	return state.all_authors
}

const all_categories	= (state) => {
	return state.all_categories
}

const all_sections	= (state) => {
	return state.all_sections
}

const online_accounts		= (state) => {
	return state.online_accounts
}

export {
	requests,
	menu_options,
	modules,
	modalities,
	all_articles,
	all_authors,
	all_categories,
	all_sections,
	online_accounts
}