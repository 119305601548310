<template>
	<div class="container" v-if="account">
		<div class="row justify-content-center">
			<div class="col-12">

				<div class="row">
					<div class="col-12 col-md-auto text-center">
						<div class="d-inline-block mt-2 mb-4">							
							<button type="button" class="btn btn-light py-5">
								<div class="mx-5 my-3 font-48">
									{{ account.name.substring(0, 1) }}
								</div>
							</button>							
						</div>
					</div>	
					<div class="col">
						<span v-if="account.is_organization">Organização</span>
						<span v-else>Conta de</span>
						<h4 class="font-24 font-md-32 mb-4">{{ account.name }}</h4>

						<div class="row" v-if="account.is_organization">
							<div class="col-12 max-150">
								<small>SCHEMA</small>
							</div>
							<div class="col text-dark font-normal">
								{{ account.instances[0].config.schema_name }}
							</div>
						</div>
						<div class="row">
							<div class="col-12 max-150">
								<small>DOC. {{account.account_type == 2 ? ` CNPJ` : ` CPF`}}</small>
							</div>
							<div class="col text-dark font-normal">
								{{ account.document_number }}
							</div>
						</div>
						<div class="row">
							<div class="col-12 max-150">
								<small>CRIAÇÃO</small>
							</div>
							<div class="col text-dark font-normal">
								{{ ParseDate(account.created_at) }}
							</div>
						</div>
						<div class="row">
							<div class="col-12 max-150">
								<small>EDIÇÃO</small>
							</div>
							<div class="col text-dark font-normal">
								{{ ParseDate(account.updated_at) }}
							</div>
						</div>
						<div class="row" v-if="!account.is_organization">
							<div class="col-12 max-150">
								<small>E-MAIL VALIDADO</small>
							</div>
							<div class="col text-dark font-normal">
								{{ account.email_verified_at ? ParseDate(account.email_verified_at) : '-' }}
							</div>
						</div>
						<div class="row">
							<div class="col-12 max-150">
								<small>STATUS</small>
							</div>
							<div class="col text-dark font-normal">
								<div 
									class="badge"
									:class="{
										'badge-success': account.status,
										'badge-danger': !account.status
									}"
								>
									{{ account.status ? 'ATIVA' : 'INATIVA'}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="scroll-x scroll-transparent d-flex mx--5 mx-md-0">
					<ul class="nav nav-pills nav-theme w-md-100 justify-content-md-center mb-2 mb-md-3 px-5">
						<!-- USER OPTIONS -->
						<li class="nav-item">
							<a 
								class="nav-link pb-md-4 px-md-4"
								data-toggle="tab" 
								href="#info"
								ref="info"
								:class="{ 'active': !account.is_organization }"
							>	
								Dados
							</a>
						</li>
						<li class="nav-item"
							v-if="!account.is_organization">
							<a 
								class="nav-link pb-md-4 px-md-4"
								data-toggle="tab" 
								href="#password"
								ref="password"
							>	
								Senha
							</a>
						</li>
						<li class="nav-item"
							v-if="!account.is_organization">
							<a 
								class="nav-link pb-md-4 px-md-4"
								data-toggle="tab" 
								href="#organizations"
								ref="organizations"
							>	
								Organizações
							</a>
						</li>
						<!-- ORGANIZATION OPTIONS -->
						<li class="nav-item"
							v-if="1 === 2 && account.is_organization">
							<a 
								class="nav-link pb-md-4 px-md-4"
								data-toggle="tab" 
								href="#financial"
								ref="financial"
							>
								Financeiro
							</a>
						</li>
						<li class="nav-item"
							v-if="account.is_organization">
							<a 
								class="nav-link pb-md-4 px-md-4"
								:class="{ 'active': account.is_organization }"
								data-toggle="tab" 
								href="#employees"
								ref="employees"
							>
								Colaboradores
							</a>
						</li>
						<li class="nav-item"
							v-if="account.is_organization">
							<a 
								class="nav-link pb-md-4 px-md-4"
								data-toggle="tab"
								href="#profiles"
								ref="profiles"
							>
								Perfis
							</a>
						</li>
						<li class="nav-item"
							v-if="account.is_organization">
							<a 
								class="nav-link pb-md-4 px-md-4"
								data-toggle="tab"
								href="#places"
								ref="places"
							>
								Locais
							</a>
						</li>
						<li class="nav-item"
							v-if="account.is_organization">
							<a class="nav-link nav-link pb-md-4 px-md-4" data-toggle="tab" href="#templates"
								ref="templates">Templates</a>
						</li>
						<li class="nav-item"
							v-if="account.is_organization">
							<a class="nav-link px-2 pb-4 text-center" data-toggle="tab" href="#advanced"
								ref="advanced">Papel timbrado</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#status"
								ref="status">Status</a>
						</li>
						<li v-if="!account.is_organization"
							class="nav-item">
							<a
								class="nav-link pb-md-4 px-md-4 text-center"
								data-toggle="tab"
								href="#subscriptions"
								ref="subscriptions"
							>
								Assinaturas
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4 text-center" data-toggle="tab" href="#modular_view"
								ref="modular_view">Visão modular</a>
						</li>
					</ul>
				</div>

				<div class="tab-content">
					<div id="info" class="tab-pane"
						:class="{ 'active': !account.is_organization }">
						<Form
							ref="form_organization"
							:is_registered="is_registered"
							:is_organization="account.is_organizationss"
							:account="account"
							:account_id="account_id"
							@submit="SaveAccountData"
						/>
					</div>
					<div id="password" class="tab-pane"
						v-if="!account.is_organization">
						<div class="py-5 max-500 text-center mx-auto">
							<h3 class="font-24 mb-5">
								Resetar a senha do usuário.
							</h3>
							<p>Será enviado um e-mail para o usuário contendo um link para a redefinição da senha.</p>
							<button 
								class="btn btn-light-secondary"
								@click="ResetUserPassword"
							>
								resetar
							</button>
						</div>
					</div>
					<div id="organizations" class="tab-pane"
						v-if="!account.is_organization">
						<div class="py-2 max-700 text-center mx-auto">
							<h3 class="font-24 mb-3">
								Organizações.
							</h3>
							<p>Lista de organizações em que esta conta está vinculada.</p>
							<div class="form-group position-relative mb-1 d-flex justify-content-center">
								<input 
									type="search" 
									placeholder="Buscar..." 
									class="form-control border-no pl-5"
									v-model="organizations_search"
								>
								<i class="far fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center"></i>
							</div>
							<div id="organizations-card" 
								class="card bg-light border-no overflow-auto">
								<transition name="fade" mode="out-in">
									<div 
										class="card-body px-4 pb-3 pt-3 d-flex flex-column"
									>
										<template v-if="filtered_organizations.length > 0">
											<div 
												v-for="(organization, index) in filtered_organizations"
												:key="organization.requester.id"			
											>
												<div class="m-0 organizations-item mt-3">
													<router-link
														:teste="organization.requester.id"
														class="d-flex align-items-center"
														:to="{ name: 'ShowAccountProfile', params: {
															account_id: organization.requester.id
														}}"
													>
														<p class="text-left m-0 p-4 flex-grow-1">
															{{ index + 1 }} - <b class="text-uppercase">{{ organization.requester.name }}</b> - desde {{ ParseDate(organization.created_at) }}
														</p>
														<span class="mr-4 item-arrow">
															<i class="py-2 px-3 far fa-chevron-right"></i>
														</span>
													</router-link>
												</div>
											</div>
										</template>
										<template v-else>
											<div class="select-card with-border mt-3 d-flex justify-content-center">
												<label v-if="organizations_search">
													Não há resultados para "{{ organizations_search }}"
												</label>											
												<p v-else>
													Não há nenhuma organização vinculada.
												</p>											
											</div>
										</template>
									</div>
								</transition>
							</div>
						</div>
					</div>
					<div id="financial" class="tab-pane"
						v-if="account.is_organization">
						<Financial
							:is_registered="is_registered"
							:organization_id="account_id"
							@end-load="ResetData"
							:providers="providers"
						/>
					</div>
					<div id="employees" class="tab-pane"
						:class="{ 'active': account.is_organization }">
						<Employees 
							:providers="providers"
							:organization_id="account_id"
							:permission="true"
						/>
					</div>
					<div id="profiles" class="tab-pane" >
						<Profiles
							:organization_id="account_id"
						/>
					</div>
					<div id="places" class="tab-pane" >
						<Places
							:organization_id="account_id"
						/>
					</div>
					<div id="status" class="tab-pane" >
						<div class="py-5 max-500 text-center mx-auto">
							<h3 class="font-24 mb-5">
								Você pode desativar e reativar temporariamente esta {{ account.is_organization ? 'organização' : 'conta' }} sempre que precisar.
							</h3>
							<button 
								class="btn"
								:class="{
									'btn-light-success': !account.status,
									'btn-light-danger': account.status
								}"

								@click="ToggleAccountStatus"
							>
								{{ account.status ? `desativar` : `reativar`}} {{ account.is_organization ? 'organização' : 'conta' }}
							</button>
						</div>
					</div>
					<div id="templates" class="tab-pane">
						<Templates
							:providers="providers"
							:organization="account"
						/>
					</div>
					<div id="advanced" class="tab-pane" >
						<template v-if="account.instances[0] && account.instances[0].config">
							<Advanced
								:organization_id="account_id"
								:logo="account.instances[0].config.logo_url"
								:footer_sup="account.instances[0].config.rodape_sup"
								:footer_inf="account.instances[0].config.rodape_inf"
							/>
						</template>
					</div>
					<div id="subscriptions" class="tab-pane"
						v-if="!account.is_organization">
						<Subscriptions
							:is_registered="is_registered"
							:account="account"
						/>
					</div>
					<div id="modular_view" class="tab-pane" >
						<ModularView
							:organization_id="account_id"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../helpers'
	const helpers 		= new Helpers()

	const _ = require('lodash')

	import Form from './components/Form'
	import Employees from './components/Employees'
	import Profiles from './components/Profiles'
	import Places from './components/Places'
	import Advanced from './components/Advanced'
	import Financial from './components/Financial'
	import Templates from './components/Templates'
	import ModularView from './components/ModularView'
	import Subscriptions from './components/Subscriptions'

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Panel',
		props: 	{
			account_id: 	{
				type: 		[String, Number],
				required: 	true,
				default: 	null
			}
		},
		components: 	{
			Form,
			Employees,
			Profiles,
			Advanced,
			Financial,
			Templates,
			Places,
			ModularView,
			Subscriptions
		},
		data () {
			return {
				account: 				null,
				providers: 				[],
				user_roles:				[],
				is_registered:			false,
				organizations_search: 	''
			}
		},
		computed: {
			...mapGetters('auth', [

			]),
			filtered_organizations()
			{
				if(this.organizations_search)
				{
					return this.account.requester.filter(e => {
						let search = this.organizations_search.toLowerCase()
						return e.requester.name.toLowerCase().includes(search)
					})
				}

				return this.account.requester
			}
		},
		watch: {
			roles: {
				handler(val)
				{
					this.user_roles = _.orderBy(val, 'asc')
				},
				immediate: true
			},
			account_id: {
				immediate: true,
				handler(val)
				{
					if(val)
					{
						this.ResetData()
						this.GetAccountData()
					}
				}
			}

		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			async GetAccountData()
			{
				this.StartLoading()

				return await window.api.call('post','/get-account-management', {
						account_id: this.account_id
					})
					.then(async ({data}) => {

						if(data.response)
						{
							this.account				 	= data.account
							this.account.complement			= data.account.complement == 'nenhum' ? '' : data.account.complement
							this.providers  				= data.account.provider
							this.is_registered				= data.account.is_registered

						}else{

							Swal.fire({
								title: 	'Eita!', 
								text: 	'Houve um erro ao obter as informações.',
								icon: 	'error'
							})

							this.$router.push({ name: 'Accounts' })
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			async ToggleAccountStatus()
			{
				const basicMessage = `Realmente deseja ${this.account.status ? 'des' : 're'}ativar${!this.account.is_organization ? ` a conta de ${this.account.name.toUpperCase()}` : ''}?`
				const messageOrg = `Se desativar a organização a seção de Financeiro e pagamentos do seu Plano serão impactados!<br>${basicMessage}`

				Swal.fire({
					icon: 		'warning',
					title:		'Atenção!',
					html:		this.account.is_organization ? messageOrg : basicMessage,
					showCancelButton: true,
					confirmButtonColor: this.account.status ? '#F64E60' : '#168d60',
					confirmButtonText:  `Sim, ${this.account.status ? 'des' : 're'}ativar`,
					cancelButtonText:  'Cancelar'

				})
					.then(async (result) => {
						if(result.isConfirmed)
						{
							this.StartLoading()

							return await window.api.call('post', '/toggle-account-status',{
									account_id: 	this.account_id,
									account_type: 	this.account.is_organization ? 2 : 1
								})
								.then(({data}) => {
									if(data.response)
									{
										this.account.status 	= data.status

										Swal.fire({
											title: 	'Status alterado com sucesso.',
											icon: 	'success',
											toast: 	 true,
											position: 'top'
										})

									}else{

										Swal.fire({ 
											title: 	'Opss...',
											text: 'Houve um erro ao concluir, contate o suporte.',
											icon: 'error'
										})
									}
								})
								.finally(() => {
									this.FinishLoading()
									this.GetAccountData()
								})
						}
					})
			},
			async SaveAccountData(form)
			{
				const vm = this

				vm.StartLoading()

				form.complement		= form.complement ? form.complement :'nenhum'

				return window.api.call('post', '/save-account', {
						account_id: 	vm.account_id,
						...form
					})
					.then(async ({data}) => {

						if(data.response)
						{
							Swal.fire({
								icon: 				'success',
								title: 				'Dados salvos com sucesso.',
								toast: 				true,
								timer: 				3000,
								position: 			'top'
							})

						}else{

							Swal.fire({
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte',
								icon: 	'error'
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			async ResetUserPassword()
			{
				const vm = this

				if(!vm.account.email)
				{
					Swal.fire({
						text:	'Não há e-mail vinculado a esta conta.',
						title:	'Eita!',
						icon:	'error'
					})

					return
				}

				vm.StartLoading()

				return await window.api.call('post', '/send-recovery-password-email', {
					email: vm.account.email
				})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								title:			'E-mail enviado com sucesso.',
								toast:			true,
								position: 		'top',
								timer:			3000,
								icon:			'success',
								showConfirmButton: false,
							})

							return
						}

						Swal.fire({
							text:	'Ocorreu algum erro ao enviar o e-mail.',
							title:	'Eita!',
							icon:	'error'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			ParseDate(date)
			{
				return helpers.ParseDate(date)
			},
			ToggleBlock(block)
			{
				const refs = Object.keys(this.$refs)

				for(let key in refs)
				{
					const tab = refs[key]

					if(tab != 'data')
					{
						if(block)
						{
							this.$refs[tab].classList.add('d-none')
						} else {
							this.$refs[tab].classList.remove('d-none')
						}
					}
				}
			},
			ResetData()
			{
				this.account				= 	null,
				this.providers				=	[],
				this.user_roles				=	[],
				this.organizations_search	= 	''
			}
		}
	}
</script>

<style lang="css" scoped>
#organizations-card
{
	height: 500px;
}

.organizations-item
{
	background: var(--dark-theme);
	border: 1px solid var(--hr);
	border-radius: 10px;
}

.organizations-item:hover
{
	background: var(--primary);
}

.organizations-item a:hover
{
	color: #fff !important;
}

.item-arrow
{
	border: 1px solid var(--hr);
	border-radius: 10px;
}

.swal-modal .swal-text {
    text-align: left !important;
}

</style>