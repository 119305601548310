<template>
    <div class="d-flex flex-column align-items-center">
        <div class="col-12 d-flex justify-content-center">
            <h2>Editar a autor</h2>
        </div>
        <AuthorForm 
            v-model="author"
            :mode="'edit'"
            @save="UpdateAuthor"
            @delete="DeleteAuthor"
        />
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'

import AuthorForm from './components/Form'

export default {
    name: 'PanelAuthor',
    components: {
        AuthorForm
    },
    props: {
        author_id: {
            type:       [String, Number],
            default:    null
        }
    },
    data () {
        return {
            author: {
                nome:               '',
                cargo:              '',
                avatar:             null
            }
        }
    },
    computed: {
        
    },
    watch: {
        author_id: {
            immediate: true,
            async handler(val)
            {
                await this.GetAuthor()
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async UpdateAuthor(val)
        {
            this.author = val

            await this.SaveAuthor()
        },
        async GetAuthor()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/faq/get-author', {
                author_id: this.author_id
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.author = data.author

                        return
                    }

                    Swal.fire({
                        text:       data.message,
                        icon:       'error',
                        title:      'Eita!'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        SaveAuthor()
        {
            const vm = this

            Swal.fire({
                text:                   'Realmente deseja alterar as informações do autor?',
                title:                  'Atenção!',
                icon:                   'question',
                showCancelButton:       true,
                confirmButtonText:      'Sim, alterar',
                cancelButtonText:       'Não, cancelar'
            })
            .then(async (result) => {
                if(result.isConfirmed)
                {
                    vm.StartLoading()

                    const formData = new FormData()

                    Object.keys(vm.author).map((key, index) => {
                        if(key != 'avatar' && vm.author[key])
                        {
                            formData.append(key, vm.author[key])
                        }
                    })

                    if(vm.author.avatar)
                    {
                        formData.append('avatar', vm.author.avatar, 'avatar.jpg')
                    }

                    formData.append('faq_author_id', vm.author.id)
        
                    return await window.api.call('post', '/faq/save-author', formData)
                        .then(({data}) => {
                            if(data.response)
                            {
                                Swal.fire({
                                    title:              'Autor alterado com sucesso!',
                                    icon:               'success',
                                    toast:              true,
                                    position:           'top',
                                    timer:              3000,
                                    timerProgressBar:   true,
                                    showConfirmButton:  false,
                                })

                                this.$router.push({ name: 'ManageAuthors' })
                                
                                return
                            }

                            Swal.fire({
                                text:   'Ocorreu um erro ao atualizar o autor.',
                                title:  'Eita!',
                                icon:   'error',
                            })
                        })
                        .finally(() => {
                            vm.FinishLoading()
                        })
                }
            })
        },
        DeleteAuthor()
        {
            const vm = this
            
            Swal.fire({
                title:      'Atenção!',
                text:       'Deseja realmente excluir o autor?',
                icon:       'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, excluir',
                cancelButtonText:  'Não, cancelar'
            })
            .then(async (result) => {
                if(result.isConfirmed)
                {
                    vm.StartLoading()

                    return await window.api.call('post', '/faq/delete-author', {
                        faq_author_id: vm.author_id
                    })
                        .then(({data}) => {
                            if(data.response)
                            {
                                Swal.fire({
                                    title:              'Autor excluído com sucesso!',
                                    icon:               'success',
                                    toast:              true,
                                    position:           'top',
                                    timer:              3000,
                                    timerProgressBar:   true,
                                    showConfirmButton:  false,
                                })

                                vm.$router.push({ name: 'ManageAuthors' })

                                return
                            }

                            Swal.fire({
                                text:   'Ocorreu um erro ao excluir o autor.',
                                title:  'Eita!',
                                icon:   'error',
                            })
                        })
                        .finally(() => {
                            vm.FinishLoading()
                        })
                }
            })
        }
    }
}
</script>

<style>

</style>