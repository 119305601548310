<template>
    <div class="col-12">
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="col-12 d-flex justify-content-center">
                <h2 class="">Editar o usuário</h2>
            </div>
            <UserForm 
                v-model="user"
                :mode="'edit'"
                @save="UpdateUser"
                @delete="DeleteUser"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import UserForm from './components/Form'

import Swal from 'sweetalert2'

export default {
    name: 'PanelUser',
    components: {
        UserForm
    },
    props: {
        user_id:        {
            type:       [String, Number],
            default:    ''
        }
    },
    data () {
        return {
            user:   {
                name:           '',
                username:       '',
                password:       '',
                avatar:         '',
                change_password: false
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async UpdateUser(val)
        {
            this.user = val

            await this.SaveUser()
        },
        async GetUser()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/get-user', {
                user_id: vm.user_id
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.user = { ...data.user }

                        vm.user.change_password = false
                        vm.user.password = ''

                        return
                    }

                    Swal.fire({
                        text:       data.message,
                        icon:       'error',
                        title:      'Eita!'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        SaveUser()
        {
            const vm = this

            Swal.fire({
                text:                   'Realmente deseja alterar as informações do usuário?',
                title:                  'Atenção!',
                icon:                   'question',
                showCancelButton:       true,
                confirmButtonText:      'Sim, alterar',
                cancelButtonText:       'Não, cancelar'
            })
            .then(async (result) => {
                if(result.isConfirmed)
                {
                    vm.StartLoading()

                    const formData = new FormData()

                    Object.keys(vm.user).map((key, index) => {
                        if(key != 'avatar' && vm.user[key])
                        {
                            formData.append(key, vm.user[key])
                        }
                    })

                    if(vm.user.avatar)
                    {
                        formData.append('avatar', vm.user.avatar)
                    }

                    formData.append('user_id', vm.user_id)
        
                    return await window.api.call('post', '/save-user', formData)
                        .then(({data}) => {
                            if(data.response)
                            {
                                Swal.fire({
                                    title:              'Usuário alterado com sucesso!',
                                    icon:               'success',
                                    toast:              true,
                                    position:           'top',
                                    timer:              3000,
                                    timerProgressBar:   true,
                                    showConfirmButton:  false,
                                })

                                this.$router.push({ name: 'Users' })
                                
                                return
                            }

                            Swal.fire({
                                text:   'Ocorreu um erro ao atualizar o usuário.',
                                title:  'Eita!',
                                icon:   'error',
                            })
                        })
                        .finally(() => {
                            vm.FinishLoading()
                        })
                }
            })
        },
        DeleteUser()
        {
            const vm = this
            
            Swal.fire({
                title:      'Atenção!',
                text:       'Deseja realmente excluir o usuário?',
                icon:       'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, excluir',
                cancelButtonText:  'Não, cancelar'
            })
            .then(async (result) => {
                if(result.isConfirmed)
                {
                    vm.StartLoading()

                    return await window.api.call('post', '/delete-user', {
                        user_id: vm.user_id
                    })
                        .then(({data}) => {
                            if(data.response)
                            {
                                Swal.fire({
                                    title:              'Usuário excluído com sucesso!',
                                    icon:               'success',
                                    toast:              true,
                                    position:           'top',
                                    timer:              3000,
                                    timerProgressBar:   true,
                                    showConfirmButton:  false,
                                })

                                vm.$router.push({ name: 'Users' })

                                return
                            }

                            Swal.fire({
                                text:   data.message,
                                title:  'Eita!',
                                icon:   'error',
                            })
                        })
                        .finally(() => {
                            vm.FinishLoading()
                        })
                }
            })
        }
    },
    beforeMount()
    {
        this.GetUser()
    }

}
</script>

<style>

</style>