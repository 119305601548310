<template>
	<transition name="fast-fade" mode="out-in">
	    <div 
	    	class="overlay-loading overlay-dark print-d-none"
	    	v-if="loading"
	    >
	    	<div class="overlay-container">
		    	<div class="row">
		    		<div class="spinner-border text-theme" role="status"></div>
				</div>
			</div>
	    </div>
	</transition>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {

		name: 'Loading',

		data () {
			return {
				loading: 	 false
			}
		},
		computed: {
			...mapGetters('system', [
				'requests'
			]),
		},
		watch: {
			requests(val)
			{
				this.loading 		= val > 0
			}
		}
	}
</script>

<style lang="css" scoped>
	.overlay-loading.overlay-dark
	{
	    background-color: #1f2128d9;
	}
</style>