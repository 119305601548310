<template>
    <div>
        <div class="container-large mt-5">
            <div class="row pb-5 justify-content-center">
                <div class="mb-4 mt-2 col-6 col-md-4 col-lg-3 col-xl-2">
                    <router-link 
                        class="box w-100 icons-card primary-hover"
                        :to="{ name: 'ManageArticles' }"
                    >
                        <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                            <div class="form-row h-100">
                                <div class="col-12 align-self-center">
                                    <i class="far fa-book-open font-32 mt-3 text-theme"></i>
                                </div>
                                <div class="col-12 align-self-start">
                                    <h5 class="m-0 font-16">
                                        Gerenciar Artigos
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                
                <div class="mb-4 mt-2 col-6 col-md-4 col-lg-3 col-xl-2">
                    <router-link 
                        class="box w-100 icons-card primary-hover"
                        :to="{ name: 'ManageSections' }"
                    >
                        <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                            <div class="form-row h-100">
                                <div class="col-12 align-self-center">
                                    <i class="far fa-bookmark font-32 mt-3 text-theme"></i>
                                </div>
                                <div class="col-12 align-self-start">
                                    <h5 class="m-0 font-16">
                                        Gerenciar Seções
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="mb-4 mt-2 col-6 col-md-4 col-lg-3 col-xl-2">
                    <router-link 
                        class="box w-100 icons-card primary-hover"
                        :to="{ name: 'ManageCategories' }"
                    >
                        <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                            <div class="form-row h-100">
                                <div class="col-12 align-self-center">
                                    <i class="far fa-th-list font-32 mt-3 text-theme"></i>
                                </div>
                                <div class="col-12 align-self-start">
                                    <h5 class="m-0 font-16">
                                        Gerenciar Categorias
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="mb-4 mt-2 col-6 col-md-4 col-lg-3 col-xl-2">
                    <router-link 
                        class="box w-100 icons-card primary-hover"
                        :to="{ name: 'ManageAuthors' }"
                    >
                        <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                            <div class="form-row h-100">
                                <div class="col-12 align-self-center">
                                    <i class="far fa-user-tie font-32 mt-3 text-theme"></i>
                                </div>
                                <div class="col-12 align-self-start">
                                    <h5 class="m-0 font-16">
                                        Gerenciar Autores
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="mb-4 mt-2 col-6 col-md-4 col-lg-3 col-xl-2">
                    <router-link 
                        class="box w-100 icons-card primary-hover"
                        :to="{ name: 'ManageContacts' }"
                    >
                        <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                            <div class="form-row h-100">
                                <div class="col-12 align-self-center">
                                    <i class="far fa-phone font-32 mt-3 text-theme"></i>
                                </div>
                                <div class="col-12 align-self-start">
                                    <h5 class="m-0 font-16">
                                        Contatos
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'FAQPanel',
    methods: {
        ...mapActions('system', [
            'GetFaqItems'
        ])
    },
    beforeMount()
    {
        // this.GetFaqItems()
    }
}
</script>

<style>

</style>