import router from '../../routes'

const GetAuthAdmin = ({ commit, state, dispatch }, payload) => {
	return window.api.call('get', '/get-auth-admin')
        .then( async ({data}) => {
        	if(data.response)
        	{
	            commit('UPDATE_ADMIN', data.admin)
				localStorage.setItem('admin', JSON.stringify(data.admin))

				window.Echo.RunGlobal()
        	}
        })

}

const Login = ({ commit, dispatch }, payload) => {
	return new Promise((resolve, reject) => {
		window.axios.post('/login', {
			username: payload.username,
			password: payload.password
		})
		.then(({data}) => {
			if(data.response) {
				commit('UPDATE_ADMIN', data.admin)
				commit('UPDATE_TOKEN', data.token)

				localStorage.setItem('token', data.token)
				localStorage.setItem('admin', JSON.stringify(data.admin))
								
				window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token

				Event.$emit('admin-login');
			}

			resolve(data)
		})
		.catch(({response}) => {
			reject(response)
		})
	})
}

const Logout = async ({ commit, state, dispatch }) => {

	if(state.token)
	{
		await window.axios.post('/logout', {
				token: state.token
			})
			.finally(() => {
				dispatch('LogoutContent')
			})
	}

	router.push({ name: 'Login' })
}

const LogoutContent = ({ commit, state }) => {
	commit('UPDATE_ADMIN', null)
	commit('UPDATE_TOKEN', null)

	localStorage.removeItem('token')
	localStorage.removeItem('account')
}

const ToggleTheme = ({ commit, state }) => {
	const obj = {
		...state.admin,
	}

	obj.theme = obj.theme == 'dark' ? 'light' : 'dark'

	commit('UPDATE_ADMIN', obj)
	localStorage.setItem('admin', JSON.stringify(obj))

	return window.api.call('post', '/toggle-user-theme')
        .then( async ({data}) => {

        })
}

export {
	GetAuthAdmin,
	Login,
	Logout,
	LogoutContent,
	ToggleTheme
}