<template>
    <div class="container">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-12 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<h4 class="font-24 font-md-32 m-0">
						Cadastrar plano
					</h4>
				</div>
                
                <PlanForm 
                    v-model="plan"
                    :mode="'new'"
                    @save="SavePlan"
                />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

import PlanForm from './components/Form'

import Swal from 'sweetalert2'

export default {
    name: 'NewPlan',
    components: {
        PlanForm
    },
    data() {
        return {
            plan:   {
                name:               "",
                recurring_accesses: null,
                public:             true,
                interval:           'months',
                total_price:        0,
                interval_count:     1,
                billing_cycles:     12,
                max_subscriptions:  0,
                description:        "",
                benefits:       [
                    { text: "", have: true }
                ]
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async SavePlan(param)
        {
            const vm = this

            if(!param.plan) return

            vm.plan = param.plan

            vm.StartLoading()

            const new_description = JSON.stringify({
                description: vm.plan.description,
                benefits: vm.plan.benefits
            })

            return await window.api.call('post', '/gateway/store-plan', {
                name:               vm.plan.name,
                public:             vm.plan.public,
                interval:           vm.plan.interval,
                interval_count:     vm.plan.interval_count,
                billing_cycles:     vm.plan.billing_cycles,
                max_subscriptions:  vm.plan.max_subscriptions,
                description:        new_description,
                recurring_accesses: parseFloat(vm.plan.recurring_accesses),
                price:              parseFloat(vm.plan.total_price),
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            title:      'Plano cadastrado com sucesso!',
                            toast:      true,
                            position:   'top',
                            icon:       'success',
                            timer:      5000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'Plans' })

                        return
                    }

                    Swal.fire({
                        text:       'Houve um erro ao criar o plano!',
                        icon:       'error',
                        title:      'Ops...'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
    }
}
</script>

<style lang="css" scoped>
</style>