<template>
	<div class="col-12 d-flex flex-column align-items-center">
		<div class="col-8 d-flex flex-column align-items-center mt-5">
			<div class="form-group row col-6 d-flex align-items-center">
				<label for="name" class="col-3 font-18">Nome: </label>
				<div class="col-9 p-0">
					<input 
						type="text"
						class="form-control col-12 py-3"
						:class="{ 'is-invalid': $v.account.name.$error }"
						v-model="$v.account.name.$model"
						>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="form-group row col-6 d-flex align-items-center">
				<label for="name" class="col-3 font-18">Token: </label>
				<div class="col-9 p-0">
					<input 
						type="text"
						class="form-control col-12 py-3"
						:class="{ 'is-invalid': $v.account.integration_token.$error }"
						v-model="$v.account.integration_token.$model"
						disabled
						>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div> 
			</div>
			<div class="form-group row col-6 d-flex align-items-center">
				<label for="name" class="col-3 font-18">Status: </label>
				<div class="col-auto p-0 d-flex"> 

					<div class="form-check col-auto">
						<input 
							class="form-check-input "
							name="status"
							type="radio"
							id="ativo"
							:value="true" 
							v-model="account.status">
						<label for="ativo" class="mr-4">Ativo</label>
					</div>
					<div class="form-check col-auto">
						<input 
							class="form-check-input "
							name="status"
							type="radio"
							id="inativo"
							:value="false" 
							v-model="account.status">
						<label for="inativo">Inativo</label>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
 
					<div class="text-danger" v-if="!$v.account.status.required">
						<small>Obrigatório</small>
					</div>
				</div> 
			</div>
			<div class="form-group row col-6 d-flex my-5 justify-content-end">
				 
				<button class="btn btn-primary"
					@click="SaveUser">
					salvar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
	name: 'IntegrationForm',
	props: {
		value:      {
			type:       Object,
			default:    () => {}
		}, 
		integration_account: 	{ 
			type: 		Object,
			default: 	()	=> {}
		}
	},
	data () {
		return {
			account: null
		}
	}, 
	watch: {
		integration_account: {
            immediate: true,
            deep:      true,
            handler(val) { 
                this.account             = { ...val }
            } 
        }
	},
	validations: {
		account: {
			name: {
				required,
				minLength: minLength(5)
			},
			integration_token: {
				required
			},
			status: {
				required
			}
		}
	}, 
	methods: { 
		SaveUser()
		{
			this.$v.account.$touch()

			if(!this.$v.account.$error)
			{
				this.$emit('save', this.account)
			}
		}  
	},
	beforeMount() {
		if ( this.integration_account ) this.account = this.integration_account
	}

}
</script>

<style lang="css" scoped>
</style>