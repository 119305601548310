<template> 
	<div class="col-12">
		<div class="d-flex flex-column align-items-center justify-content-center">
			<div class="col-12 d-flex justify-content-center">
				<h2>Editar integraçao</h2>
			</div>


			<IntegrationForm 
				v-model="account"
				:integration_account="account"
				:mode="'edit'"
				@save="UpdateAccount"
			/>

			<IntegrationPersons
				:integration_account="account" 
			/>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'

import { mapActions } from 'vuex'

import IntegrationForm from './components/Form.vue'
import IntegrationPersons from './components/Persons.vue'

export default {
	name: 'EditIntegration',
	components: {
		IntegrationForm,
		IntegrationPersons
	},
	data() {
		return { 
			account: {
				name: null,
				integration_token: this.ParseRandomCode(32),
				persons: []
			}
		}
	},
	props: {
		account_id: {
			type: [String, Number ],
			default: ''
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		UpdateAccount(account) {
			this.account = account

			this.SaveAccount()
		},
		SaveAccount() {
			 
			const vm = this

			vm.StartLoading()

			window.api.call('post', 'integration-account', this.account)
				.then(({data}) => { 
					
					if (!data.response) {
						
						Swal.fire({
							title:		'Eita!',
							text:		'Houve um erro: ' + data.message ?? null,
							icon:		'error'
						})

					}

					this.$router.push({
						name: 'Integration'
					})

				})
				
                .finally(() => {
                    this.FinishLoading()
                })

		},
		GetAccount() {
 
			const vm = this
			vm.StartLoading()

			window.api.call('post', 'get-integration-account', {
				account_id: this.account_id
			})
				.then(({data}) => {

					if (!data.response) {

						Swal.fire({
							title: 'Ops!',
							text: 'Houve um erro ao consultar essa conta: ' + data.message,
							icon: 'error'
						})
						return;
					}

					this.account = data.account
				})
                .finally(() => {
                    this.FinishLoading()
                })

		},
		ParseRandomCode(length) {
			var result           = [];
			var characters       = 'abcdefghijklmopqrstuvwxyz0123456789abcdefghijklmopqrstuvwxyz0123456789?=#.';
			var charactersLength = characters.length;
			for ( var i = 0; i < length; i++ ) {
				result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
			}
			return result.join('');
		}
	}, 
	beforeMount() {
		this.GetAccount()
	}
	
}
</script>

<style>

</style>