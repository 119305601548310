<template>
	<form @submit.prevent="Validate" class="pb-5">
		<div class="row">
			<div class="col-12 px-0 d-flex align-items-center">
				<div class="col-6 col-md-8 form-group">
					<label>Tipo</label>
					<br/>
					<div class="form-group">
						<div class="btn-group-checkbox d-inline-block mt-4 mb-3 my-md-0">
							<label for="fisica">
								<input 
									type="radio"
									id="fisica"
									:value="1" 
									v-model="form.account_type"
								>
								<div class="btn btn-light-primary btn-sm">
									Fisica
								</div>
							</label>
							<label for="juridica">
								<input 
									type="radio"
									id="juridica"
									:value="2" 
									v-model="form.account_type"
								>
								<div class="btn btn-light-primary btn-sm">
									Juridica
								</div>
							</label>
						</div>
		            </div>
				</div>
				<div class="col-6 col-md-4">
					<div class="form-group">
						<label for="">Remember Token</label>
						<input	
							type="text"
							class="form-control"
							v-model="form.remember_token"
							@click="RememberTokenLinkToClipboard(form.remember_token)"
						>
					</div>
				</div>
			</div>
		</div>
		<transition name="fast-fade" mode="out-in">
			<div class="row" v-if="form.account_type == 2" key="1">
				<div class="col-12 col-md-6">
					<div class="form-group">
						<label>Razão Social</label>
						<input
							type="text"
							class="form-control"
							:class="{ 'is-invalid': $v.form.name.$error}"
							v-model="$v.form.name.$model"
							ref="name"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="form-group">
						<label>Nome Fantasia</label>
						<input
							type="text"
							class="form-control"
							v-model="form.social_name"
						>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="form-group">
						<label>CNPJ</label>
						<input
							type="tel"
							class="form-control"
							:class="{ 'is-invalid': $v.form.document_number.$error}"
							v-model="$v.form.document_number.$model"
							v-mask="['##.###.###/####-##']"
							ref="document_number"
						>
				        <div class="invalid-feedback">
				          	CNPJ Invalido
				        </div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="form-group">
						<label>Inscr. Municipal</label>
						<input
							type="text"
							class="form-control"
							placeholder="isento"
							v-model="form.municipal_registration"
						>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="form-group">
						<label>Inscr. Estadual</label>
						<input
							type="text"
							class="form-control"
							placeholder="isento"
							v-model="form.state_registration"
						>
					</div>
				</div>
			</div>
			<div class="row" v-if="form.account_type === 1" key="2">
				<div class="col-12 col-md-8">
					<div class="form-group">
						<label>Nome</label>
						<input
							type="text"
							class="form-control"
							:class="{ 'is-invalid': $v.form.name.$error}"
							v-model="$v.form.name.$model"
							ref="name"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<div class="form-group">
						<label>CPF</label>
						<input
							type="tel"
							class="form-control"
							:class="{ 'is-invalid': $v.form.document_number.$error}"
							v-model="$v.form.document_number.$model"
							v-mask="['###.###.###-##']"
							ref="document_number"
						>
				        <div class="invalid-feedback">
				          	CPF Invalido
				        </div>
					</div>
				</div>
			</div>
		</transition>
		<div class="row">
			<div class="col-12 col-md-3">
				<div class="form-group">
					<label>CEP</label>
					<input
						type="tel"
						class="form-control"
						:class="{ 'is-invalid': $v.form.zipcode.$error }"
						v-model="$v.form.zipcode.$model"
						v-mask="['#####-###']"
						v-on:keyup.enter="SearchZipCode()"
			    		@blur="SearchZipCode()"
						ref="zipcode"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-7">
				<div class="form-group">
					<label>Logradouro</label>
					<input
						type="text"
						class="form-control"
						:class="{ 'is-invalid': $v.form.address.$error }"
						v-model="$v.form.address.$model"
						ref="address"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-3 col-lg-2">
				<div class="form-group">
					<label>Numero</label>
					<input
						type="number"
						class="form-control"
						:class="{ 'is-invalid': $v.form.number.$error }"
						v-model="$v.form.number.$model"
						ref="number"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="form-group">
					<label>Complemento</label>
					<input
						type="text"
						class="form-control"
						v-model="form.complement"
					>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="form-group">
					<label>Bairro</label>
					<input
						type="text"
						class="form-control"
						:class="{ 'is-invalid': $v.form.district.$error }"
						v-model="$v.form.district.$model"
						ref="district"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8">
				<div class="form-group">
					<label>Cidade</label>
					<input
						type="text"
						class="form-control"
						:class="{ 'is-invalid': $v.form.city.$error }"
						v-model="$v.form.city.$model"
						ref="city"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label>Estado</label>
					<select
						type="text"
						class="form-control"
						:class="{ 'is-invalid': $v.form.state.$error }"
						v-model="$v.form.state.$model"
						ref="state"
					>
						<option value="">Selecione...</option>
						<option 
							v-for="state in states"
							:key="state.initials"
							:value="state.initials"
						>
							{{state.name}}
						</option>
					</select>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label>Email</label>
					<input
						type="email"
						class="form-control"
						:class="{ 'is-invalid': $v.form.email.$error }"
						v-model="$v.form.email.$model"
						ref="email"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label>Telefone</label>
					<input
						type="tel"
						class="form-control"
						:class="{ 'is-invalid': $v.form.phone_1.$error }"
						v-model="$v.form.phone_1.$model"
						v-mask="['(##) ####-####', '(##) #####-####']"
						ref="phone_1"
					>
					<div class="invalid-feedback">
						Obrigatório
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label>Telefone 2</label>
					<input
						type="tel"
						class="form-control"
						v-model="form.phone_2"
						v-mask="['(##) ####-####', '(##) #####-####']"
					>
				</div>
			</div>
			<div class="col-12 text-right d-flex justify-content-end mt-4">
				<button
					type="submit"
					class="btn btn-primary px-5"
				>
					salvar
				</button>
			</div>
		</div>
	</form>
</template>

<script>
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()
	const _ 			= require('lodash')

	import { required, minLength, sameAs, requiredIf } from 'vuelidate/lib/validators'
	const ValidateDocument 	= (value, vm) => {
	
		if(value)
		{
			switch(vm.account_type)
			{
				case 2:
					return helpers.ValidaCNPJ(value)
				
				case 1:
					return helpers.ValidaCPF(value)
				
			}
		}

		return true
	}

	import Swal from 'sweetalert2'
	export default {

		name: 'Form',
		props: {
			account_id: {
				type:		[Number, String],
				default:	null,
			},
			account: {
				type: 		Object,
				default: 	null,
				required: 	false
			},
			is_registered: {
				type:		Boolean,
				default:	false,
			},
			is_organization: {
				type:		Boolean,
				default:	false,
			}
		},
		data () {
			return {
				form: 		{
					account_type: 			1, // 1 - CPF OU 2 - CNPJ
					name: 					'',
					social_name: 			'',
					document_number:		'',
					state_registration: 	'',
					municipal_registration: '',
					address: 				'',
					number: 				'',
					district: 				'',
					complement: 			'',
					city: 					'',
					state: 					'',
					zipcode: 				'',
					email: 					'',
					phone_1: 				'',
					phone_2: 				'',
					remember_token:			''
				},
				states: 	window.statesBR
			}
		},
		validations: {
			form: 		{
				name: 					{
					required
				},
				document_number:		{
					ValidateDocument
				},
				email:					{
					required
				},
				phone_1:				{
					requiredIf: requiredIf(function () {
						return this.is_organization
					})
				},
				address:				{
					requiredIf: requiredIf(function () {
						return this.is_organization
					})
				},
				number:					{
					requiredIf: requiredIf(function () {
						return this.is_organization
					})
				},
				district:				{
					requiredIf: requiredIf(function () {
						return this.is_organization
					})
				},
				zipcode:				{
					requiredIf: requiredIf(function () {
						return this.is_organization
					}),
					minLength: 9
				},
				city:					{
					requiredIf: requiredIf(function () {
						return this.is_organization
					})
				},
				state:					{
					requiredIf: requiredIf(function () {
						return this.is_organization
					})
				}
			}
		},
		watch: {
			account_id:{
				immediate:	true,
				handler(val)
				{
					if(val)
					{
						this.form.id = val
					}
				}
			},
			account: {
				immediate: true,
				handler(val)
				{
					if(!_.isEmpty(val))
					{
						this.form.account_type 				= val.account_type
						this.form.name 						= val.name
						this.form.social_name 				= val.social_name
						this.form.document_number			= val.document_number
						this.form.state_registration 		= val.state_registration
						this.form.municipal_registration 	= val.municipal_registration
						this.form.address 					= val.address
						this.form.number 					= val.number
						this.form.district 					= val.district
						this.form.complement 				= val.complement
						this.form.city 						= val.city
						this.form.state 					= val.state
						this.form.zipcode 					= val.zipcode
						this.form.email 					= val.email
						this.form.phone_1 					= val.phone_1
						this.form.phone_2 					= val.phone_2
						this.form.remember_token			= val.remember_token
					}
				},
				deep: true
			},
			'form.account_type':
			{
				handler(val)
				{
					this.form.social_name 				= '' 
					this.form.document_number			= ''
					this.form.state_registration 		= ''
					this.form.municipal_registration 	= ''
				}
			}
		},
		methods: {
			async Validate()
			{
				this.$v.form.$touch()

				if(!this.$v.form.$invalid)
				{
					this.$emit('submit', this.form)
				} else {
					window.helpers.focusInvalid(this.$v.form, this)
				}
			},
			async SaveCostumer(form)
			{
				const vm = this

				if(!vm.is_registered)
				{
					vm.$parent.StartLoading()

					const organization = form

					// const phones = [
					// 	{
					// 		phone_type:	'mobile',
					// 		number:		vm.ParsePhone(organization.phone_1)
					// 	},
					// ]

					// if(organization.phone_2)
					// {
					// 	phones.push(
					// 		{
					// 			phone_type:	'mobile',
					// 			number:		vm.ParsePhone(organization.phone_2)
					// 		}
					// 	)
					// }

					const phones = vm.ParsePhone(form.phone_1)

					const customer = {
						name:			organization.name,
						email:			organization.email,
						registry_code:	organization.document_number,
						phone:			phones,
						address:		{
							street:				organization.address,
							number:				organization.number,
							additional_details: organization.complement ?? "nenhum",
							zipcode:			organization.zipcode,
							neighborhood:		organization.district,
							city:				organization.city,
							state:				organization.state
						}
					}

					return await window.api.call('post', '/gateway/store-customer', {
						organization_id: parseInt(vm.organization_id),
						customer
					})
						.then(async ({data}) => {
							if(!data.response)
							{
								Swal.fire({
									title:	'Ops...',
									text:	'Erro ao completar o cadastro!',
									icon:	'error'
								})
								
								return
							}
						})
						.finally(() => {
							vm.$parent.FinishLoading()
						})
				}
			},
			async SearchZipCode()
			{
				const vm 		= this
				const zipcode 	= this.$v.form.zipcode.$model

				if(zipcode)
				{
					if(zipcode.length == 9)
					{	
						vm.$parent.StartLoading()

						helpers.searchZipCode(zipcode)
							.then(( res ) => {

								vm.form.address  		= res.address
								vm.form.district     	= res.district
								vm.form.complement 		= res.complement
								vm.form.city   			= res.city
								vm.form.state			= res.state
							})
							.finally(() => {
								vm.$parent.FinishLoading()
							})				
					}
				}
			},
			ParsePhone(phone)
			{
				if(phone)
				{
					let new_phone = phone
					new_phone = new_phone.replace('(', '')
					new_phone = new_phone.replace(')', '')
					new_phone = new_phone.replace(' ', '')
					new_phone = new_phone.replace('-', '')

					return '55' + new_phone
				}
				return ''
			},
			async RememberTokenLinkToClipboard(token) {
				await navigator.clipboard.writeText(`${process.env.VUE_APP_LANDING_PAGE_URL}/cadastro/token/${token}`);
			}
		}
	}
</script>

<style lang="css" scoped>
</style>