<template>
    <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import helpers from '../../helpers'
import Swal from 'sweetalert2'

export default {
    name: 'OrganizationDelete',
    props: {
        organization_id: {
            type: [String, Number],
            required: true,
            default: null
        }
    },
    data() {
         return {

         }
    },
    methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
        DeleteOrganization()
        {
            this.StartLoading()

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Deletar organização!',
                text: "Tem certeza que deseja deletar está organização? Não será possível reverter esta ação!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, deletar organização!',
                cancelButtonText: 'Não, cancelar!',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        return window.api.call('get', `/delete-organization/${this.organization_id}`)
                            .then(({data}) => {
                                if (data.response) {
                                    Swal.fire({
                                        title: 'Organização excluída!',
                                        icon: 'success'
                                    }).then(() => {
                                        this.$router.push({ name: 'Organizations' })
                                    })
                                } else if (data.status === 404) {
                                    Swal.fire({
                                        title: 'Organização não encontrada!',
                                        icon: 'error'
                                    }).then(() => {
                                        this.$router.push({ name: 'Organizations' })
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Houve um erro, verifique as requisições',
                                        icon: 'error'
                                    }).then(() => {
                                        this.$router.push({ name: 'Organizations' })
                                    })
                                }
                            })
                            .finally(() => {
                                this.FinishLoading()
                            })
                    } else {
                        this.$router.push({ name: 'Organizations' })
                    }
                })
                .finally(() => {
                    this.FinishLoading()
                })
        }
    },
    beforeMount() 
    {
       this.DeleteOrganization()
    }
}
</script>

<style lang="css" scoped>
</style>