<template>
    <div class="d-flex flex-column align-items-center">
        <div class="col-12 d-flex justify-content-center">
            <h2>Editar a categoria</h2>
        </div>
        <CategoryForm 
            v-model="category"
            :mode="'edit'"
            @save="UpdateCategory"
            @delete="DeleteCategory"
        />
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'

import CategoryForm from './components/Form'

export default {
    name: 'PanelCategory',
    components: {
        CategoryForm
    },
    props: {
        category_id: {
            type:       [String, Number],
            default:    null
        }
    },
    data () {
        return {
            category: {
                descricao:          '',
                icone:              '',
                slug:               '',
            }
        }
    },
    computed: {
        
    },
    watch: {
        category_id: {
            immediate: true,
            async handler(val)
            {
                await this.GetCategory()
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async UpdateCategory(val)
        {
            this.category = val

            await this.SaveCategory()
        },
        async GetCategory()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/faq/get-category', {
                category_id: this.category_id
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.category = data.category
                        return
                    }

                    Swal.fire({
                        text:       data.message,
                        icon:       'error',
                        title:      'Eita!'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        SaveCategory()
        {
            const vm = this

            Swal.fire({
                text:                   'Realmente deseja substituir as informações da categoria?',
                title:                  'Atenção!',
                icon:                   'question',
                showCancelButton:       true,
                confirmButtonText:      'Sim, substituir',
                cancelButtonText:       'Não, cancelar'
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        vm.StartLoading()
            
                        return await window.api.call('post', '/faq/save-category', {
                            faq_category_id: vm.category.id,
                            ...vm.category
                        })
                            .then(({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        title:              'Categoria salvo com sucesso!',
                                        icon:               'success',
                                        toast:              true,
                                        position:           'top',
                                        timer:              3000,
                                        timerProgressBar:   true,
                                        showConfirmButton:  false,
                                    })

                                    this.$router.push({ name: 'ManageCategories' })
                                    
                                    return
                                }

                                Swal.fire({
                                    text:   'Ocorreu um erro ao atualizar a categoria.',
                                    title:  'Eita!',
                                    icon:   'error',
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        },
        DeleteCategory()
        {
            const vm = this

            Swal.fire({
                text:                   'Deseja realmente excluir a categoria?',
                title:                  'Atenção!',
                icon:                   'warning',
                showCancelButton:       true,
                confirmButtonText:      'Sim, excluir',
                cancelButtonText:       'Não, cancelar'
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        vm.StartLoading()
            
                        return await window.api.call('post', '/faq/delete-category', {
                            faq_category_id: vm.category.id
                        })
                            .then(({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        title:              'Categoria excluida com sucesso!',
                                        icon:               'success',
                                        toast:              true,
                                        position:           'top',
                                        timer:              3000,
                                        timerProgressBar:   true,
                                        showConfirmButton:  false,
                                    })

                                    this.$router.push({ name: 'ManageCategories' })
                                    
                                    return
                                }

                                Swal.fire({
                                    text:   'Ocorreu um erro ao excluir a categoria.',
                                    title:  'Eita!',
                                    icon:   'error',
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        }
    }
}
</script>

<style>

</style>