<template> 
	<div class="col-12">
		<div class="d-flex flex-column align-items-center justify-content-center">
			<div class="col-12 d-flex justify-content-center">
				<h2>Criar nova integraçao</h2>
			</div>
			<IntegrationForm 
				v-model="account"
				:integration_account="account"
				:mode="'new'"
				@save="UpdateAccount"
			/>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'

import { mapActions } from 'vuex'

import IntegrationForm from './components/Form.vue'

export default {
	name: 'NewIntegration',
	components: {
		IntegrationForm
	},
	data() {
		return { 
			account: {
				name: '',
				integration_token: this.ParseRandomCode(32),
				persons: []
			}
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		UpdateAccount(account) {
			this.account = account

			this.SaveAccount()
		},
		SaveAccount() {
			 
			const vm = this

			vm.StartLoading()

			window.api.call('post', 'integration-account', this.account)
				.then(({data}) => { 
					
					if (!data.response) {
						
						Swal.fire({
							title:		'Eita!',
							text:		'Houve um erro: ' + data.message ?? null,
							icon:		'error'
						})

					}

					this.$router.push({
						name: 'Integration'
					})

				})
				
                .finally(() => {
                    this.FinishLoading()
                })

		},
		ParseRandomCode(length) {
			var result           = [];
			var characters       = 'abcdefghijklmopqrstuvwxyz0123456789abcdefghijklmopqrstuvwxyz0123456789?!#.';
			var charactersLength = characters.length;
			for ( var i = 0; i < length; i++ ) {
				result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
			}
			return result.join('');
		}
	}
	
}
</script>

<style>

</style>