const admin 		= (state) => {
	return state.admin
}

const token 		= (state) => {
	return state.token
}

const check 		= (state) => {
	return !! state.token;
}

export {
	admin,
	token,
	check
}