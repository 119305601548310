<template>
	<div class="autocomplete">
		<div class="form-group">
			<label v-if="label">
				{{ label }}
			</label>
			<div class="input-group">
				<input 
					type="text" 
					class="form-control"
					style="border-right: 0" 
					:class="{ 'is-invalid': validate.bool }"
					:placeholder="placeholder"
				    @input="OnChange"
				    @focus="isOpen = true && search.toString().length"
	    			v-model="search"
	    			@keydown.down="OnArrowDown"
	    			@keydown.up="OnArrowUp"
	    			@keydown.enter="OnEnter"
	    			:disabled="disabled"
				>
  				<div class="input-group-append">
    				<button
    					type="button"
    					class="btn-cancel"
    					:disabled="IsEmpty(selected) || disabled"
    					@click="ClearSelected"
    				>
    					<i class="fal fa-times"></i>
    				</button>
  				</div>
				<div class="invalid-feedback">
	          		{{ validate.text }}
	        	</div>
	        </div>

			<transition-group 
				name="fast-fade" 
				tag="ul" 
				class="autocomplete-results shadow" 
				v-show="isOpen"
			>
			    <li
			      	class="autocomplete-result"
			      	v-if="isLoading"
			      	key="loading"
			    >
			      	Carregando...
			    </li>
			    <li
			    	v-else-if="results.length < 1"
			    	class="autocomplete-result"
			    	@click="CloseResults"
			    	key="nodata"
			    >
			    	<span>
			    		Não há resultados para "{{search}}"
			    	</span>
			    </li>
		      	<li 
		      	 	v-else
		      		class="autocomplete-result" 
		      		v-for="(result, index) in results" 
		      		:key="`result_${index}`"
		      		:class="{ 'is-active': index === arrowCounter }"
		      		@click="SetResult(result)"
		      	>
			      	<slot name="item" :item="result">
			      		{{ itemText ? result[itemText] : result }}
			      	</slot>
		      	</li>
				<li 
					class="autocomplete-result bg-warning"
					v-if="!isLoading && allowCreate"
					key="new_item"
					@click="CreateNew"
				>
			    	<span>
			    		<div class="form-row flex-nowrap align-items-center">
			    			<div class="col-auto">
					    		<div class="badge badge-success">
					    			<i class="material-icons" style="vertical-align: middle;">add</i>	
					    		</div>
					    	</div>
					    	<div class="col">
			    				<strong>Clique aqui</strong> para criar "{{search}}"
			    			</div>
					    </div>
			    	</span>
				</li>
		    </transition-group>
		</div>
	</div>
</template>

<script>
	const _ 		= require('lodash')

	export default {
		name: 'Autocomplete',
		props: {
			label: 			{
				type: 			String,
				default: 		''
			},
			placeholder: 	{
				type: 			String,
				default: 		''
			},
			allowCreate: 	{
				type: 			Boolean,
				default: 		false
			},
			items: 			{
				type: 			Array,
				required: 		false,
				default: 		() => []
			},
			itemValue: 		{
				type: 			String,
				required: 		false,
				default: 		'id'
			},
			itemText: 		{
				type: 			String,
				required: 		false,
				default: 		'name'
			},
			itemType: 		{
				type: 			String,
				required: 		false,
				default: 		'object'
			},
			isAsync: 		{
		        type: 			Boolean,
		        required: 		false,
		        default: 		false
	      	},
	      	validate: 		{
		        type: 			Object,
		        required: 		false,
		        default: 		() => ({
		        	bool: 			false,
		        	text: 			''
		        })
	      	},
	      	CustomFilter: 	{
	      		type: 			Function,
	      		required: 		false
	      	},
	      	value: 			{
	      		type: 			[Object, String]
	      	},
	      	disabled: 		{
	      		type: 			Boolean,
	      		default: 		false
	      	}
		},
		data () {
			return {
				search: 		'',
				selected: 		{},
				results: 		[],
				isOpen: 		false,
				isLoading: 		false,
				arrowCounter: 	-1
			}
		},
		computed: {

		},
		watch: {
	      	items(value, oldValue) 
	      	{
		        if(value.length !== oldValue.length)
		        {
		          	this.results 			= value
		          	this.isLoading 			= false
		        }
	      	},
	      	value:{
	      		immediate: 	true,
	      		handler()
	      		{
	      			this.FindObj()

	      		},
	      		deep: true
	      	},
	      	search(val)
	      	{
	      		if(!val.toString().length)
	      		{
	      			this.isOpen 		= false
	      		}
	      	}
	    },
		methods: {
			OnChange()
			{	
				if(typeof this.search === 'object')
				{
					this.selected 		= this.search

					this.$emit('input', this.selected)
				}

				if (this.isAsync) 
				{
					this.isLoading 		= true

				}else{

					this.FilterResults()
					this.isOpen 		= true	
				}
			},
			FilterResults()
			{
				if(typeof this.CustomFilter !== 'undefined')
				{
					this.results 		= this.items.filter( item => this.CustomFilter(item, this.search))

				}else{

					this.results 		= this.items.filter( item => {

						let result 				= this.itemText ? item[this.itemText] : item

						return result.toLowerCase().indexOf(this.search.toLowerCase()) > -1
					})
				}
				
			},
			SetResult(result) 
			{
				this.selected 		= result

				this.$emit('input', this.selected)

		        this.search 		= this.itemText ? this.selected[this.itemText] : this.selected
		        this.isOpen 		= false
	      	},
  	      	OnArrowDown() 
  	      	{
				if (this.arrowCounter < this.results.length) 
				{
					this.arrowCounter 		= this.arrowCounter + 1
				}
			},
			OnArrowUp() 
			{
				if (this.arrowCounter > 0) 
				{
					this.arrowCounter 		= this.arrowCounter - 1
				}
			},
			OnEnter() 
			{
				this.selected 			= this.results[this.arrowCounter]
				
				if(!_.isEmpty(this.selected))
				{
					this.$emit('input', this.selected)

					this.search 			= this.itemText ? this.selected[this.itemText] : this.selected
			
					this.isOpen 			= false
			
					this.arrowCounter 		= -1
				}
			},
			CloseResults()
			{
				this.isOpen 	= false
			},
			CreateNew()
			{
				if(_.isEmpty(this.selected))
				{
					this.$emit('create-command', this.search)
				}

				this.isOpen 	= false
			},
		    HandleClickOutside(e) 
		    {
				if (!this.$el.contains(e.target)) 
				{
					this.isOpen 			= false
					this.arrowCounter 		= -1
				}
			},
			FindObj()
			{
				if(this.value)
				{
					let value 		= this.itemValue ? this.value[this.itemValue] : this.value

					if(value)
					{
						let find 				= this.items.find( item => (this.itemValue ? item[this.itemValue] : item ) == value)

		      			if(find)
		      			{
		      				this.selected 			= find
		      				this.$emit('input', this.selected)

		      				this.search 			= this.itemText ? find[this.itemText] : find
							this.isOpen 			= false
							this.arrowCounter 		= -1
							
		      			}else{

		      				this.selected 			= {}
							this.search 			= ''
							this.arrowCounter 		= -1
							this.isOpen 			= false
		      			}
		      			
					}else{

	      				this.selected 			= {}
						this.search 			= ''
						this.arrowCounter 		= -1
						this.isOpen 			= false
	      			}

				}else{

      				this.selected 			= {}
					this.search 			= ''
					this.arrowCounter 		= -1
					this.isOpen 			= false
      			}
      			
			},
			ClearSelected()
			{
				switch(this.itemType)
				{
					case 'string':
						this.selected 			= ''
					break

					case 'object': 
						this.selected 			= {}
					break
				}
				
				this.search 			= ''
				this.arrowCounter 		= -1
				this.isOpen 			= false

				this.$emit('input', this.selected)
			},
			IsEmpty(obj)
			{
				switch(this.itemType)
				{
					case 'string':
						return typeof obj !== 'string'

					case 'object': 
						return typeof obj !== 'object' || _.isEmpty(obj)
			
				}
			}
		},
		mounted() 
		{
			document.addEventListener('click', this.HandleClickOutside)

			if(this.value)
			{
				this.FindObj()
			}
		},
		unmounted() 
		{
			document.removeEventListener('click', this.HandleClickOutside)
		}
	}

</script>

<style lang="css" scoped>

	.autocomplete 
	{
		position: relative;
	}

	.form-control:focus ~ .input-group-append .btn-cancel
	{
	    outline: 0;
	    border-color: var(--light-dark);
	    box-shadow: 0 0 0
	}

	.autocomplete button
	{
		min-height: 0 !important;
		min-width: 0 !important;
	}

	.autocomplete button i
	{
		font-size: 1.5rem;
	}

	.autocomplete-results 
	{
		padding: 0;
		margin: 5px 0 0 0;
		border: 1px solid #eeeeee;
	    border-radius: 8px;
		max-height: calc(100vh - 30px);
		overflow: auto;
	    position: absolute;
	    background-color: #fff;
	    color: #000;
	    width: 100%;
	    z-index: 100;
		transition: all .2s ease-in-out;
	}

	.autocomplete-result 
	{
		list-style: none;
		text-align: left;
		padding: .3rem .7rem;
		cursor: pointer;
		overflow-x: hidden;
		transition: all .1s ease-in-out;
	}

	.autocomplete-result:hover 
	{
		background-color: #007bff;
		color: white;
	}

	.autocomplete-result.is-active,
	.autocomplete-result:hover 
	{
		background-color: #007bff;
		color: white;
	}

	.autocomplete-result .chip
	{
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: center;
		flex-wrap: nowrap;
	}

	.autocomplete-result .chip .avatar
	{
		height: 38px;
		width: 38px;
		border-radius: 50%;
	}

	.autocomplete-result .chip .avatar img
	{
		height: 100%;
		width: 100%;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #ccc;
	}

	.autocomplete-result .chip .name
	{
		text-transform: uppercase;
		font-weight: 500;
		font-size: .865rem;
		padding: .5rem;
	}

	.autocomplete .btn-cancel
	{	
		transition: all 0.2s;
		border-top-right-radius: 8px;
	    border-bottom-right-radius: 8px;
	    background-color: transparent;
	    border: 2px solid var(--light-input);
	    border-left: 0;
	    padding: 0 1rem;
	    color: red;
	}

	.autocomplete .btn-cancel:disabled
	{
		color: #ccc;
	}
</style>