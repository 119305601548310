<template>
	<div class="row">
		<div class="col-12">
			<p class="text-center">
				Você pode configurar aqui, logotipo e rodapé do <b>laudo</b>.
			</p>
		</div>
		<div class="col-12">
			<div class="form-group">
				<label>Logotipo</label>
				<div class="overlay-component">
					<div class="overlay-btn">
						<input 
							class="file-input" 
							type="file" 
							accept="image/*" 
							ref="inputFile" 
							@change="SaveLogo($event)"
						/>
						<i class="fad fa-file-upload"></i>
					</div>
					<img :src="temp_logo">
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="form-group">
				<label>Rodapé linha #1</label>
				<input type="text" class="form-control" v-model="footer.sup">
			</div>
		</div>
		<div class="col-12">
			<div class="form-group">
				<label>Rodapé linha #2</label>
				<input type="text" class="form-control" v-model="footer.inf">
			</div>
		</div>
		<div class="col-12 text-right">
			<button type="button" class="btn btn-primary px-5" @click="SaveFooter">
				salvar
			</button>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Advanced',
		props: 	{
			organization_id: 	{
				type: 		[Number, String],
				default: 	null,
				required: 	true
			},
			logo: 			{
				type: 		String,
				default: 	null
			},
			footer_sup: 		{
				type: 		String,
				default: 	null
			},
			footer_inf: 		{
				type: 		String,
				default: 	null
			}
		},
		data () {
			return {
				footer: {
					inf: 		'',
					sup: 		''
				},
				temp_logo: 	null
			}
		},
		watch: {
			footer_sup: {
				handler(val)
				{
					this.footer.sup 	= val
				},
				immediate: true
			},
			footer_inf: {
				handler(val)
				{
					this.footer.inf 	= val
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetAccountLogo()
			{
				const no_logo 	= require('../../../assets/img/sem-logo.png')

				return this.logo || no_logo
			},
			SaveLogo(e)
			{
				this.StartLoading()

				const logo 			= e.target.files[0]

				const formData 		= new FormData()
				formData.append('logo', logo, 'logo.jpg')
				formData.append('organization_id', this.organization_id)

				window.api.call('post', '/save-organization-logo', formData)
					.then(({data}) => {
						if(data.response)
						{
							this.temp_logo 		= data.organization.logo_url

						}else{ 

							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			SaveFooter()
			{
				this.StartLoading()

				return window.api.call('post', '/save-footer', {
						organization_id: 		this.organization_id,
						footer_sup: 			this.footer.sup,
						footer_inf: 			this.footer.inf
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 		'success',
								title: 		'Rodapé salvo com sucesso.',
								toast: 		true,
								position: 	'top',
								timer: 		3000
							})

						}else{ 

							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao concluir, contate o suporte.'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			}
		},
		beforeMount()
		{
			this.temp_logo 		= this.GetAccountLogo()
		}

	}
</script>

<style lang="css" scoped>

	.overlay-component
	{
		border: 1px solid var(--light-input);
		margin-bottom: 1rem;
		border-radius: 8px;
		position: relative;
		background-color: #fff !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		max-height: 200px;
	}

	.overlay-component:hover .overlay-btn
	{
		opacity: 1;
	}

	.overlay-component .overlay-btn i
	{
		padding: 0 46.8%;
		font-size: 4rem;
		color: #fff;
	}

	.overlay-component img
	{
		width: 100%;
		height: auto;
		object-fit: cover;
		border-radius: 8px;
	}

	.overlay-component .overlay-btn
	{
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 9;
		display: flex;
		flex-direction: row;
		background-color: #333c;
		align-items: center;
		border-radius: 8px;
		border: 1px solid var(--light-input);
		opacity: 0;
		transition: all .2s ease-in-out;
	}

	.overlay-component .overlay-btn input
	{
		position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
	}

</style>