const UPDATE_ADMIN 			= (state, payload) => {
	state.admin 				= payload ? {...payload} : {}
}

const UPDATE_TOKEN 			= (state, payload) => {
	state.token 				= payload
}

export {
	UPDATE_ADMIN,
	UPDATE_TOKEN
}