<template>
    <div id="article-form" class="col-12 form my-5 d-flex flex-column align-items-center">
            <div class="col-12 col-md-10 col-lg-8 col-xl-6 header d-flex flex-column align-items-center">
                <div class="form-group row col px-0 d-flex align-items-center">
                    <label class="font-18 col-2 px-0" for="title">Título: </label>
                    <input 
                        id="title"
                        type="text"
                        class="form-control col-10 my-1 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.titulo.$error }"
                        v-model="$v.form.titulo.$model">
                </div>
                <div class="form-group row col px-0 d-flex align-items-center">
                    <label class="font-18 col-2 px-0" for="slug">Slug: </label>
                    <input 
                        id="slug"
                        type="text"
                        class="form-control col-10 my-1 py-3 font-16"
                        :value="slugged_title"
                        disabled>
                </div>
                <div class="form-group row col px-0 d-flex align-items-center">
                    <label class="font-18 col-2 px-0" for="category">Categoria: </label>
                    <select name="category" id="category"
                        class="form-control col-10 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.faq_category_id.$error }"
                        v-model="$v.form.faq_category_id.$model">
                        <option :value="null" selected disabled>Selecione alguma categoria</option>
                        <option v-for="category in all_categories"
                            :value="category.id"
                            :key="category.id">{{ category.descricao }}</option>
                    </select>
                </div>
                <div class="form-group row col px-0 d-flex align-items-center">
                    <label class="font-18 col-2 px-0" for="section">Seção: </label>
                    <select name="section" id="section"
                        class="form-control col-10 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.faq_section_id.$error }"
                        v-model="$v.form.faq_section_id.$model"
                        :disabled="!form.faq_category_id">
                        <option v-for="section in filtered_sections"
                            :value="section.id"
                            :key="section.id">{{ section.descricao }}</option>
                    </select>
                </div>
                <div class="form-group row col px-0 d-flex align-items-center">
                    <label class="font-18 col-2 px-0" for="author">Autor: </label>
                    <select name="author" id="author"
                        class="form-control col-10 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.faq_author_id.$error }"
                        v-model="$v.form.faq_author_id.$model">
                        <option v-for="author in all_authors"
                            :value="author.id"
                            :key="author.id">{{ author.nome }}</option>
                    </select>
                </div>
                <div class="form-group row col px-0 d-flex align-items-center">
                    <label class="font-18 col-2 px-0" for="status">Stauts: </label>
                    <select name="status" id="status"
                        class="form-control col-10 py-3 font-16"
                        v-model="form.status">
                        <option v-for="status in all_status"
                            :value="status.id"
                            :key="status.id">{{ status.text }}</option>
                    </select>
                </div>
                <div class="form-group row col px-0 d-flex align-items-start mt-2 mb-4">
                    <label for="text" class="font-18 col-2 px-0 mb-3">Texto: </label>
                    <quill-editor
                        class="col px-0"
                        ref="myQuillEditor"
                        v-model="form.texto"
                        :options="{}"
                    >
                    </quill-editor>
                </div>
                <div class="form-group row col-6 d-flex my-5"
                    :class="mode === 'edit' ? 'justify-content-between' : 'justify-content-end'">
                    <button class="btn btn-danger"
                        v-if="mode === 'edit'"
                        @click="DeleteArticle">
                        excluir
                    </button>
                    <button class="btn btn-primary"
                        @click="SaveArticle">
                        salvar
                    </button>
                </div>
            </div>
        </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import { required } from 'vuelidate/lib/validators'

import moment from 'moment'

export default {
    name: "ArticleForm",
    props:  {
        value:  {
            type:       Object,
            default:    () => {}
        },
        mode:   {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            form: {
                titulo:             '',
                faq_section_id:     null,
                faq_category_id:    null,
                faq_author_id:      null,
                slug:               '',
                texto:              '',
                status:             1,
                agendamento:        ''
            },
            all_status:     [
                { id: 1, text: 'Ativo' },
                { id: 2, text: 'Rascunho' },
                { id: 3, text: 'Inativo' },
            ]
        }
    },
    validations: {
        form: {
            titulo: {
                required
            },
            faq_section_id: {
                required
            },
            faq_category_id: {
                required
            },
            faq_author_id: {
                required
            },
        }
    },
    computed: {
        ...mapGetters('system', [
            'all_categories', 'all_sections', 'all_authors'
        ]),
        filtered_sections()
        {
            return this.all_sections.filter(s => s.faq_category_id === this.form.faq_category_id)
        },
        slugged_title()
        {
            if(this.form.titulo)
            {
                return window.helpers.Slugify(this.form.titulo)
            }

            return ''
        },
        agendamento()
        {
            return moment().format('DD/MM/YYYY')
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val)
            {
                this.form = { ...val }
            }
        }
    },
    methods: {
        SaveArticle()
        {
            this.$v.form.$touch()

            if(!this.$v.form.$error)
            {
                this.form.agendamento = this.agendamento
                this.form.slug = this.slugged_title
                this.$emit('save', this.form)
            }
        },
        DeleteArticle()
        {
            this.$emit('delete')
        }
    },
    beforeMount()
    {
        
    }
}
</script>

<style lang="css">
#article-form {
    color: #000;
}

.ql-container.ql-snow {
    max-height: 432px !important;
    overflow: auto !important;
}
</style>