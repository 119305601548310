const ADD 				= (state) => {
	state.request_loading++
}

const REM 				= (state) => {
	state.request_loading--
}

const UPDATE_MODULES 	= (state, payload) => {
	state.modules	= payload ? [...payload] : []
}

const UPDATE_MODALITIES 	= (state, payload) => {
	state.modalities	= payload ? [...payload] : []
}

const UPDATE_ALL_ARTICLES	= (state, payload) => {
	state.all_articles	= payload ? [...payload] : []
}

const UPDATE_ALL_AUTHORS	= (state, payload) => {
	state.all_authors	= payload ? [...payload] : []
}

const UPDATE_ALL_CATEGORIES	= (state, payload) => {
	state.all_categories	= payload ? [...payload] : []
}

const UPDATE_ALL_SECTIONS	= (state, payload) => {
	state.all_sections	= payload ? [...payload] : []
}

const ADD_ONLINE_ACCOUNT	= (state, payload) => {
	state.online_accounts.push(payload);
}

const REM_ONLINE_ACCOUNT	= (state, payload) => {
	state.online_accounts = state.online_accounts.filter(u => u.id != payload.id);
}

const RESET_ONLINE_ACCOUNTS = (state, payload) => {
	state.online_accounts = payload ? [...payload] : []
}

export {
	ADD,
	REM,
	UPDATE_MODULES,
	UPDATE_MODALITIES,
	UPDATE_ALL_ARTICLES,
	UPDATE_ALL_AUTHORS,
	UPDATE_ALL_CATEGORIES,
	UPDATE_ALL_SECTIONS,
	ADD_ONLINE_ACCOUNT,
	REM_ONLINE_ACCOUNT,
	RESET_ONLINE_ACCOUNTS
}