<template>
    <div class="col-12">
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="col-12 d-flex justify-content-center">
                <h2 class="">Criar uma nova seção</h2>
            </div>
            <SectionForm 
                v-model="section"
                :mode="'new'"
                @save="UpdateSection"
            />
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import SectionForm from './components/Form'

import Swal from 'sweetalert2'

export default {
    name: "NewSection",
    components: {
        SectionForm
    },
    data () {
        return {
            section: {
                descricao:          '',
                faq_category_id:    null,
                slug:               '',
            }
        }
    },
    computed: {
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading', 'GetFaqItems'
        ]),
        async UpdateSection(val)
        {
            this.section = val

            await this.SaveSection()
        },
        async SaveSection()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/faq/save-section', {
                ...vm.section
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            icon:       'success',
                            title:      'Seção salva com sucesso!',
                            toast:      true,
                            position:   'top',
                            timer:      4000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'ManageSections' })

                        return
                    }

                    Swal.fire({
                        icon:       'error',
                        title:      'Erro ao salvar o autor',
                        toast:      true,
                        position:   'top',
                        showConfirmButton: false,
                        timer:      3000,
                        timerProgressBar: true
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        }
    },
    beforeMount() {

    }
    
}
</script>

<style>

</style>