<template>
	<div class="pb-5">
		<transition name="fade" mode="out-in">
			<router-view/>
		</transition>
	</div>
</template>

<script>
	export default {

		name: 'EmptyParentComponent',
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>

</style>