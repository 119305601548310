import Vuex from './vuex'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import Swal from 'sweetalert2'

NProgress.configure({
    showSpinner: false
});

const router = new VueRouter({
    mode:   'history',
    routes: [
        {
            path: '*',
            redirect: {
                name: 'Login'
            }
        },
        {
            name:   'Login',
            path:   '/auth/login',
            component: require('./components/login/Index.vue').default,
            meta: {
                title: 'Login'
            }
        },
        {
			path: '/',
			component: require('./components/layout/Index.vue').default,
			children: [
				{
					path: '/',
					component: require('./components/layout/EmptyParentComponent.vue').default,
					children: [
						{
							name: 'Home',
							path: 'home',
							component: require('./components/home/Index.vue').default,
							default: true,
							meta: {
                                middlewareAuth: true,
								title:          'Home',
							}
						},
                        {
                            name: 'OnlineAccounts',
                            path: 'contas-online',
                            props: true,
                            component: require('./components/online-accounts/OnlineAccounts.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Usuários online',
                            }
                        },
                        // Accounts
                        {
                            name: 'Accounts',
                            path: 'contas',
                            props: true,
                            component: require('./components/accounts/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Todas os usuários',
                            }
                        },
                        {
                            name: 'ShowAccountProfile',
                            path: 'contas/:account_id',
                            props: true,
                            component: require('./components/accounts/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Todas os usuários',
                            }
                        },
                        // Organizations
                        {
                            name: 'Organizations',
                            path: 'organizacoes',
                            props: true,
                            component: require('./components/accounts/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Todas as organizações',
                            }
                        },
                        {
                            name: 'ShowOrganizationProfile',
                            path: 'organizacoes/:organization_id',
                            props: true,
                            component: require('./components/accounts/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Todas as organizações',
                            }
                        },
                        {
                            name: 'OrganizationDelete',
                            path: 'organizacoes/delete-organization/:organization_id',
                            props: true,
                            component: require('./components/accounts/Delete.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Deletar Organização',
                            }
                        },
                        {
                            name: 'CreateTestOrganization',
                            path: 'organizacoes/create-test-organization/:account_id',
                            props: true,
                            component: require('./components/accounts/CreateTest.vue').default,
                            meta: {
                                middlewareAuth: true,
                                title:          'Criar Organização de teste',
                            }
                        },
                        // Users
                        {
                            name: 'Users',
                            path: 'usuarios',
                            component: require('./components/users/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Todos os usuários',
                            }
                        },
                        {
                            name: 'NewUser',
                            path: 'usuarios/novo',
                            component: require('./components/users/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Cadastrar usuário',
                            }
                        },
                        {
                            name: 'EditUser',
                            path: 'usuarios/:user_id',
                            props: true,
                            component: require('./components/users/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar usuário',
                            }
                        },
                        // Profile
                        {
                            name: 'Profile',
                            path: 'usuario/:user_id/perfil',
                            props: true,
                            component: require('./components/users/Profile.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Perfil do Usuário',
                            }
                        },
                        // Template
                        // {
                        //     name: 'TemplateManagement',
                        //     path: 'gerenciamento-template',
                        //     component: require('./components/template-management/Index.vue').default,
                        //     default: true,
                        //     meta: {
                        //         middlewareAuth: true,
                        //         title:          'Gerenciamento de Templates',
                        //     }
                        // },
                        // Plans
                        {
                            name: 'Plans',
                            path: 'planos',
                            component: require('./components/plans/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Planos',
                            }
                        },
                        {
                            name: 'NewPlan',
                            path: 'planos/novo-plano',
                            component: require('./components/plans/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Novo Plano',
                            }
                        },
                        {
                            name: 'EditPlan',
                            path: 'planos/editar-plano/:plan_id',
                            props: true,
                            component: require('./components/plans/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar plano',
                            }
                        },
                        {
                            name: 'FAQPanel',
                            path: 'faq',
                            component: require('./components/faq/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'FAQ'
                            },
                        },
                        // FAQ-Articles
                        {
                            name: 'ManageArticles',
                            path: '/faq/artigos',
                            component: require('./components/faq/articles/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Artigos'
                            }
                        },
                        {
                            name: 'NewArticle',
                            path: '/faq/artigos/novo',
                            component: require('./components/faq/articles/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Novo Artigo'
                            }
                        },
                        {
                            name: 'EditArticle',
                            path: '/faq/artigos/:article_id',
                            props: true,
                            component: require('./components/faq/articles/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar Artigo'
                            }
                        },
                        // FAQ-Sections
                        {
                            name: 'ManageSections',
                            path: '/faq/secoes',
                            component: require('./components/faq/sections/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Seções'
                            }
                        },
                        {
                            name: 'NewSection',
                            path: '/faq/secoes/novo',
                            component: require('./components/faq/sections/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Nova Seção'
                            }
                        },
                        {
                            name: 'EditSection',
                            path: '/faq/secoes/:section_id',
                            props: true,
                            component: require('./components/faq/sections/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar Seção'
                            }
                        },
                        // FAQ-Categories
                        {
                            name: 'ManageCategories',
                            path: '/faq/categorias',
                            component: require('./components/faq/categories/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Categorias'
                            }
                        },
                        {
                            name: 'NewCategory',
                            path: '/faq/categorias/novo',
                            component: require('./components/faq/categories/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Nova Categoria'
                            }
                        },
                        {
                            name: 'EditCategory',
                            path: '/faq/categorias/:category_id',
                            props: true,
                            component: require('./components/faq/categories/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar Categoria'
                            }
                        },
                        // FAQ-Authors
                        {
                            name: 'ManageAuthors',
                            path: '/autores',
                            component: require('./components/faq/authors/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Autores'
                            }
                        },
                        {
                            name: 'NewAuthor',
                            path: '/faq/autores/novo',
                            component: require('./components/faq/authors/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Novo Autor'
                            }
                        },
                        {
                            name: 'EditAuthor',
                            path: '/faq/autores/:author_id',
                            props: true,
                            component: require('./components/faq/authors/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar Autor'
                            }
                        },
                        {
                            name: 'ManageContacts',
                            path: 'contatos',
                            component: require('./components/faq/contacts/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Contatos'
                            }
                        },
                        // Cupons
                        {
                            name: 'Coupons',
                            path: 'cupons',
                            component: require('./components/coupons/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Cupons',
                            }
                        },
                        {
                            name: 'NewCoupon',
                            path: 'novo-cupom',
                            component: require('./components/coupons/components/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Novo Cupom',
                            }
                        },
                        {
                            name: 'EditCoupon',
                            path: 'editar-cupom/:coupon_id',
                            props: true,
                            component: require('./components/coupons/components/Panel.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Editar cupom',
                            }
                        },
                        {
                            name: 'Menus',
                            path: 'menus',
                            component: require('./components/menus/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Menus',
                            }
                        },
						{
							path: '',
							redirect: {
								name: 'Home'
							}
						},
						// Template
						{
							name: 'MoveTemplate',
							path: 'movimentar-template',
							component: require('./components/move-template/Index.vue').default,
							default: true,
							meta: {
								middlewareAuth: true,
								title:          'Movimentar template',
							}
						},
                        // Integration
                        {
                            name: 'Integration',
                            path: 'integracao',
                            component: require('./components/integration/Index.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Integração',
							}
						},
                        {
                            name: 'EditIntegration',
                            path: 'integracao/:account_id/editar',
                            component: require('./components/integration/Edit.vue').default,
							props: true,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Integração',
							} 
						},
                        {
                            name: 'NewIntegration',
                            path: 'integracao/novo',
                            component: require('./components/integration/New.vue').default,
                            default: true,
                            meta: {
                                middlewareAuth: true,
                                title:          'Integração',
							}
						}
                            
					]
				},
                {
                    path: '',
                    redirect: {
                        name: 'Home'
                    }
                }
			]
		}
    ]
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.middlewareAuth)) 
    {
        NProgress.start()

        if (!new Vuex().getters['auth/check']) 
        {
            next({
                name: 'Login'
            });

            return;
        }
    }

    next();
})

router.afterEach((to) => {
    document.title = 'IM ERP | ' + to.meta.title

    NProgress.done()
})

export default router;