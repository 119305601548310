<template>
    <div class="d-flex flex-column align-items-center">
		<div class="col-10 flex-column align-items-center">
			<div class="col-12 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<h4 class="font-24 font-md-32 m-0">
						Editar um plano
					</h4>
				</div>
                <PlanForm 
                    v-model="plan"
                    :mode="'edit'"
                    @save="SavePlan"
                    @delete="DeletePlan"
                />
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'

import PlanForm from './components/Form'

import { mapActions } from 'vuex'

export default {
    name: 'PanelPlan',
    components: {
        PlanForm
    },
    props: {
        plan_id:    {
            type:       [Number, String],
            default:    null,
            required:   true
        }
    },
    data() {
        return {
            plan:   {
                name:               "",
                recurring_accesses: null,
                public:             true,
                interval:           'months',
                total_price:        0,
                interval_count:     1,
                billing_cycles:     12,
                max_subscriptions:  0,
                description:        "",
                benefits:       [
                    { text: "", have: true }
                ]
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async GetPlan()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/gateway/get-plan', {
                plan_id:    vm.plan_id,
                erp:        true 
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.plan             = { ...data.plan }
                        vm.plan.description = JSON.parse(data.plan.description).description
                        vm.plan.benefits    = JSON.parse(data.plan.description).benefits
                        vm.plan.price       = data.plan.total_price

                        return
                    }

                    Swal.fire({
                        text:       'Houve um erro na hora de carregar as informações do plano!',
                        icon:       'error',
                        title:      'Ops...'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async SavePlan(param)
        {
            const vm = this

            vm.StartLoading()

            if(!param.plan) return

            vm.plan = param.plan
            
            const new_description = JSON.stringify({
                description: vm.plan.description,
                benefits: vm.plan.benefits
            })

            return await window.api.call('post', '/gateway/update-plan', {
                plan_id:            vm.plan_id,
                name:               vm.plan.name,
                public:             vm.plan.public,
                interval:           vm.plan.interval,
                interval_count:     vm.plan.interval_count,
                billing_cycles:     vm.plan.billing_cycles,
                max_subscriptions:  vm.plan.max_subscriptions,
                description:        new_description,
                recurring_accesses: parseFloat(vm.plan.recurring_accesses),
                price:              parseFloat(vm.plan.total_price),
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            text:       'Plano cadastrado com sucesso!',
                            title:      ' ',
                            toast:      true,
                            position:   'top',
                            icon:       'success',
                            timer:      5000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'Plans' })

                        return
                    }

                    Swal.fire({
                        text:       'Houve um erro ao editar o plano!',
                        icon:       'error',
                        title:      'Ops...'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async DeletePlan()
        {
            Swal.fire({
                title:              'Excluir plano?',
                text:               'Deseja realmente excluir este plano?',
                confirmButtonColor: '#f64e60',
                confirmButtonText:  'Sim, deletar!',
                cancelButtonText:   'Não, cancelar',
                showCancelButton:   true,
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        const vm = this

                        vm.StartLoading()

                        return await window.api.call('post', '/gateway/delete-plan', {
                            plan_id:    vm.plan_id
                        })
                            .then(({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        icon:       'success',
                                        title:      'Plano excluído com sucesso!',
                                        toast:      true,
                                        position:   'top',
                                        timer:      5000,
                                        timerProgressBar: true
                                    })

                                    vm.$router.push({ name: 'Plans' })

                                    return
                                }

                                Swal.fire({
                                    icon:       'error',
                                    title:      'Ops...',
                                    text:       'Houve um erro ao deletar o plano!',
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        },
        async AddBenefit(fieldType)
        {
            await fieldType.push({ description: "", have: false })

            const divs = this.$refs.benefits_content.childNodes

            divs.forEach((el, i) => {
                const children = el.childNodes
                if(i > 1)
                {
                    const a = [...children].forEach((c) => {
                        if(c.classList && c.classList.contains("off"))
                        {
                            c.classList.remove('off')
                            c.classList.add('on')
                        }
                    })
                }
            })
        },
        RemoveBenefit(index, fieldType) {
            fieldType.splice(index, 1);
        },
    },
    beforeMount()
    {
        this.GetPlan()
    }
}
</script>

<style lang="css" scoped>

textarea.description
{
    resize: none;
}

.new-benefit
{
    font-weight: 700;
}

a
{
    cursor: pointer;
}

a.trash-button.off
{
    display: none;
}

a.trash-button.on
{
    position: absolute;
    right: -1.5rem;
    top: 0.9rem;
}

i.fa.fa-times {
    font-size: 23px;
    font-weight: 400 !important;
    line-height: 1rem;
}


</style>