<template>
  	<div class="col-12 pb-5 d-flex flex-column align-items-center justify-content-center">
		<div v-if="filtered_online_accounts.length > 0" class="col-12 col-sm-10 col-md-8 col-lg-6 px-0 d-flex flex-column align-items-center online_acocunt_list">
			<div class="col-12 px-0 d-flex flex-column align-items-center">
				<p class="font-24">
					Total de usuários online: <b>{{ filtered_online_accounts.length }}</b>
				</p>
				<div class="col-12 col-md-10 col-lg-8 d-flex justify-content-between">
					<span class="font-24">
						Médicos: <b>{{ doctors.length }}</b>
					</span>
					<span class="font-24">
						Não Médicos: <b>{{ not_doctors.length }}</b>
					</span>
				</div>
			</div>
			<div 
				class="col-12 px-sm-0"
				v-for="account in filtered_online_accounts"
				:key="`${account.type}-${account.id}`"
			>
				<div v-if="account.name" class="col-12 px-0 d-flex justify-content-between align-items-center">
					<div class="col-11 px-0 font-weight-bold"
							v-tippy="{ arrow: true, content: `ID:${account.id}`, followCursor: true }">
						<i class="fa fal"
							:class="{
								'fa-stethoscope': account.is_doctor,
								'fa-clipboard': !account.is_doctor,
							}"
						></i>
						<span class="ml-2">
							{{ account.name.toUpperCase() }}
						</span>
					</div>
					<div class="col-1 px-0">
						<div class="d-flex justify-content-between">
							<router-link class="btn btn-light-primary btn-sm"
								:to="{ name: 'ShowAccountProfile', params: { account_id: account.id } }"
							>
								<i class="fa fa-chevron-right"></i>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			Nenhum usuário online.
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
const _ = require('lodash')

export default {
	name: 'OnlineAccounts',
	props: {

	},
	computed: {
		...mapGetters('system', [
			'online_accounts'
		]),
		filtered_online_accounts() {
			return _.orderBy(this.online_accounts.filter(acc => acc.type === 'account'), 'name');
		},
		doctors() {
			return this.filtered_online_accounts.filter(acc => acc.is_doctor)
		},
		not_doctors() {
			return this.filtered_online_accounts.filter(acc => !acc.is_doctor)
		}
	}
}
</script>

<style lang="css" scoped>
.status-indicator {
	margin: 0 25px;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	align-self: center;
}

.status-indicator:before {
	content: '';
	border: 1px solid #8e8e8e;
	background-color: #8e8e8e;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 5px;
	left: 0px;
	border-radius: 50%;
}

.status-indicator.online:before {
	border-color: #20c964;
	background-color: #20c964;
	box-shadow: 0px 0px 7px 2px #20c964;
}

@media (min-width: 1200px) {
	.online_acocunt_list {
		width: 650px !important;
	}
}
</style>