<template>
	<div>
		<Loading/>
		<Navbar/>
		<transition name="fade" mode="out-in">
			<router-view/>
		</transition>
	</div>
</template>

<script>
	import Loading from './Loading'
	import Navbar from './Navbar'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',
		components: 	{
			Loading,
			Navbar,
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'check', 'admin'
			])
		},
		watch: {
			'admin.theme': {
				handler(val) {
					if(val == 'dark') {
						this.AddDarkTheme()
					} else {
						this.RemoveDarkTheme()
					}
				}
			}
		},
		methods: {
			...mapActions('auth', [
				'GetAuthAdmin'
			]),
			AddDarkTheme() 
			{
				let darkThemeLinkEl 		= document.createElement("link");
				darkThemeLinkEl.rel 		= 'stylesheet';
				darkThemeLinkEl.href 		= '/css/dark.css';
				darkThemeLinkEl.id 			= 'dark-theme-style';

				let docHead 				= document.querySelector("head");

				docHead.append(darkThemeLinkEl);
			},
			RemoveDarkTheme() {
				let darkThemeLinkEl 	= document.querySelector("#dark-theme-style");
				
				if(darkThemeLinkEl) {
					let parentNode 			= darkThemeLinkEl.parentNode;

					parentNode.removeChild(darkThemeLinkEl);
				}
			}
		},
		mounted()
		{
			if(this.check)
			{
				this.GetAuthAdmin()

				if(this.admin.theme == 'dark') {
					this.AddDarkTheme()
				}
			}
		}
	}
</script>

<style lang="css" scoped>
</style>