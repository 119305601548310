import Vue from 'vue'

window._ 			= require('lodash')

window.axios        = require('axios');

window.axios.defaults.headers.common['X-Requested-With']   = 'XMLHttpRequest';

window.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/erp`

const authToken = localStorage.getItem('token');

if (authToken) 
{
    window.axios.defaults.headers.common['Authorization']     = 'Bearer ' + authToken;
}

try {
    window.Popper 	= require('popper.js');
    window.$ 		= window.jQuery = require('jquery');

    require('bootstrap');

} catch (e) {

	console.log('error: ' + e.toString())
}


window.Event   = new Vue

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//HELPERS JS
import Helpers from './helpers'
window.helpers 		= new Helpers()

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

//EDITOR QUILL
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, {
	modules: {
	  toolbar: [
		  ['bold', 'italic', 'underline', 'strike'],
		  ['blockquote', 'code-block'],
		  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
		  [{ 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
		  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		  [{ 'color': [] }, { 'background': [] }],
		  ['clean'],
		  ['link', 'image','video']
	  ]
	},
	placeholder: 'Insira uma descrição'
})

import money from 'v-money'
Vue.use(money, {
	precision: 	2,
	prefix: 	'R$ ',
	decimal: 	',',
	thousands: 	'.'
})

import './assets/sass/styles.scss'


import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy);

Vue.component("tippy", TippyComponent);


require('./global-components')
require('./global-variables')
require('./directives')