<template>
	<div class="container-large mt-5">
		<div class="row pb-5 justify-content-start">
            <div 
                v-for="menu in menu_options"
                :key="menu.role"
                class="mb-4 mt-2 col-6 col-md-4 col-lg-3 col-xl-2"
            >
                <router-link 
                    class="box w-100 icons-card primary-hover"
                    :to="{ name: menu.route, params: {
                        ...menu.params
                    }}"
                >
                    <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                        <div class="form-row h-100">
                            <div class="col-12 align-self-center">
                                <i :class="`${menu.icon} font-32 mt-3 text-theme`"></i>
                            </div>
                            <div class="col-12 align-self-start">
                                <h5 class="m-0 font-16">
                                    {{ menu.text }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',

		data () {
			return {

			}
		},
		computed: {
			...mapGetters('system', [
				'menu_options'
			])
		}
	}
</script>

<style lang="css" scoped>
</style>