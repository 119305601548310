<template>
    <div class="col-12 form my-5 d-flex flex-column align-items-center">
            <div class="col-8 header d-flex flex-column align-items-center">
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-2" for="nome">Nome: </label>
                    <input 
                        id="nome"
                        type="text"
                        class="form-control col-10 my-1 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.nome.$error }"
                        v-model="$v.form.nome.$model">
                </div>
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-2" for="cargo">Cargo: </label>
                    <input 
                        id="cargo"
                        type="text"
                        class="form-control col-10 my-1 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.cargo.$error }"
                        v-model="$v.form.cargo.$model">
                </div>
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-2" for="input-avatar">Avatar: </label>
                    <button 
                        class="btn btn-light-primary mr-5"
                        @click="UploadAvatar"
                    >
                        selecionar
                    </button>
                    <input 
                        type="file"
                        id="input-avatar"
                        accept=".jpg, .jpeg, .png"
                        @change="UploadAvatar"
                        hidden>
                    <template v-if="shown_avatar">
                        <img 
                            id="img-avatar"
                            :src="shown_avatar" 
                            class="w-150 rounded-lg"
                        >
                    </template>
                </div>
                <div class="form-group row col-6 d-flex my-5"
                    :class="mode == 'edit' ? 'justify-content-between' : 'justify-content-end'">
                    <button class="btn btn-danger"
                        v-if="mode == 'edit'"
                        @click="DeleteAuthor">
                        excluir
                    </button>

                    <button class="btn btn-primary"
                        @click="SaveAuthor">
                        salvar
                    </button>
                </div>
            </div>
        </div>
</template>

<script>

import Swal from 'sweetalert2'

import { mapActions, mapGetters } from 'vuex'

import { required } from 'vuelidate/lib/validators'

export default {
    name: "AuthorForm",
    props:  {
        value:  {
            type:       Object,
            default:    () => {}
        },
        mode:   {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            form: {
                nome:           '',
                cargo:          '',
                avatar:         null,
            }
        }
    },
    validations: {
        form: {
            nome: {
                required
            },
            cargo: {
                required
            }
        }
    },
    computed: {
        shown_avatar() {
            if(this.form.avatar)
            {
                if(this.form.avatar instanceof File)
                {
                    return URL.createObjectURL(this.form.avatar)
                }

                return this.form.avatar
            }

            return ''
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val)
            {
                this.form = { ...val }
            }
        }
    },
    methods: {
        SaveAuthor()
        {
            this.$v.form.$touch()

            if(!this.$v.form.$error)
            {
                this.$emit('save', this.form)
            }
        },
        DeleteAuthor()
        {
            this.$emit('delete')
        },
        UploadAvatar(event) 
        {
            if(event.target.files)
            {
                this.form.avatar 	= event.target.files[0]
            }

            document.querySelector('#input-avatar').click()

        },
    },
    beforeMount()
    {
        
    }
}
</script>

<style lang="css" scoped>
#img-avatar
{
    padding: .3rem;
    border: 1px solid var(--primary);
    border-radius: 1rem;
}

.w-150 {
	width: 150px;
}
</style>