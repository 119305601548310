<template>
    <div class="container">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-12 mb-4">
				<div class="d-block mx-auto py-md-2 text-md-center max-500">
					<h4 class="font-24 font-md-32 m-0">
						Cadastrar cupom
					</h4>
				</div>
                
                <PlanForm 
                    v-model="coupon"
                    mode="new"
                    @save="SaveCoupon"
                />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

import PlanForm from './Form'

import Swal from 'sweetalert2'

import moment from 'moment'

export default {
    name: 'New',
    components: {
        PlanForm
    },
    data() {
        return { 
            coupon:   {
                
                name:           "",
                description:    "",
                code:           this.ParseRandomCode(8),
                only_user:      null,
                max_usages:     1,
                cycles:         1,
                discount_type:  "percentage",
                percentage:     10,
                amount:         5,
                due_at:         moment().add(10, 'day').format('yyyy-MM-DThh:mm'),
                not_due:        false,
                exclusive_user: null

            },
            customers: ['teste', 'teasa']
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async SaveCoupon(param)
        {
            const vm = this

            if(!param.coupon) return

            vm.coupon = param.coupon

            vm.StartLoading()

            return await window.api.call('post', '/gateway/store-coupon', {
                ...vm.coupon
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            title:      'Cupom cadastrado com sucesso!',
                            toast:      true,
                            position:   'top',
                            icon:       'success',
                            timer:      5000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'Coupons' })

                        return
                    }

                    Swal.fire({
                        text:       'Houve um erro ao criar o cupom!',
                        icon:       'error',
                        title:      'Ops...'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        ParseRandomCode(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
            }
            return result.join('');
        }
    }
}
</script>

<style lang="css" scoped>
</style>