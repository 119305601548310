<template>
    <div class="d-flex flex-column align-items-center">
        <div class="col-12 d-flex justify-content-center">
            <h2>Editar o artigo</h2>
        </div>
        <ArticleForm 
            v-model="article"
            :mode="'edit'"
            @save="UpdateArticle"
            @delete="DeleteArticle"
        />
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'

import ArticleForm from './components/Form'

export default {
    name: 'PanelArticle',
    components: {
        ArticleForm
    },
    props: {
        article_id: {
            type:       [String, Number],
            default:    null
        }
    },
    data () {
        return {
            article: {
                titulo:             '',
                faq_section_id:     null,
                faq_category_id:    null,
                faq_author_id:      null,
                slug:               '',
                texto:              '',
                status:             1,
                agendamento:        ''
            }
        }
    },
    computed: {
        
    },
    watch: {
        article_id: {
            immediate: true,
            async handler(val)
            {
                await this.GetArticle()
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async UpdateArticle(val)
        {
            this.article = val

            await this.SaveArticle()
        },
        async GetArticle()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/faq/get-article', {
                article_id: this.article_id
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.article = data.article
                        return
                    }

                    Swal.fire({
                        text:       data.message,
                        icon:       'error',
                        title:      'Eita!'
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        SaveArticle()
        {
            const vm = this

            Swal.fire({
                text:                   'Realmente deseja substituir as informações do artigo?',
                title:                  'Atenção!',
                icon:                   'question',
                confirmButtonText:      'Sim, substituir',
                showCancelButton:       true,
                cancelButtonText:       'Não, cancelar'
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        vm.StartLoading()
            
                        return await window.api.call('post', '/faq/save-article', {
                            faq_article_id: vm.article.id,
                            ...vm.article
                        })
                            .then(({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        title:              'Artigo salvo com sucesso!',
                                        icon:               'success',
                                        toast:              true,
                                        position:           'top',
                                        timer:              3000,
                                        timerProgressBar:   true,
                                        showConfirmButton:  false,
                                    })

                                    this.$router.push({ name: 'ManageArticles' })
                                    
                                    return
                                }

                                Swal.fire({
                                    text:   'Ocorreu um erro ao atualizar o artigo.',
                                    title:  'Eita!',
                                    icon:   'error',
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        },
        DeleteArticle()
        {
            const vm = this

            Swal.fire({
                text:                   'Deseja realmente excluir o artigo?',
                title:                  'Atenção!',
                icon:                   'warning',
                showCancelButton:       true,
                confirmButtonText:      'Sim, excluir',
                cancelButtonText:       'Não, cancelar'
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        vm.StartLoading()
            
                        return await window.api.call('post', '/faq/delete-article', {
                            faq_article_id: vm.article.id
                        })
                            .then(({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        title:              'Artigo excluido com sucesso!',
                                        icon:               'success',
                                        toast:              true,
                                        position:           'top',
                                        timer:              3000,
                                        timerProgressBar:   true,
                                        showConfirmButton:  false,
                                    })

                                    this.$router.push({ name: 'ManageArticles' })
                                    
                                    return
                                }

                                Swal.fire({
                                    text:   'Ocorreu um erro ao excluir o artigo.',
                                    title:  'Eita!',
                                    icon:   'error',
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        }
    }
}
</script>

<style>

</style>