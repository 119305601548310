<template>
    <div class="col-12 form my-5 d-flex flex-column align-items-center">
            <div class="col-8 header d-flex flex-column align-items-center">
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-2" for="title">Descricao: </label>
                    <input 
                        id="title"
                        type="text"
                        class="form-control col-10 my-1 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.descricao.$error }"
                        v-model="$v.form.descricao.$model">
                </div>
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-2" for="slug">Slug: </label>
                    <input 
                        id="slug"
                        type="text"
                        class="form-control col-10 my-1 py-3 font-16"
                        :value="slugged_description"
                        disabled>
                </div>
                <div class="form-group row col-6 d-flex align-items-center">
                    <label class="font-18 col-2" for="category">Categoria: </label>
                    <select name="category" id="category"
                        class="form-control col-10 py-3 font-16"
                        :class="{ 'is-invalid': $v.form.faq_category_id.$error }"
                        v-model="$v.form.faq_category_id.$model">
                        <option :value="null" selected disabled>Selecione alguma categoria</option>
                        <option v-for="category in all_categories"
                            :value="category.id"
                            :key="category.id">{{ category.descricao }}</option>
                    </select>
                </div>
                <div class="form-group row col-6 d-flex my-5"
                    :class="mode === 'edit' ? 'justify-content-between' : 'justify-content-end'">
                    <button class="btn btn-danger"
                        v-if="mode === 'edit'"
                        @click="DeleteSection">
                        excluir
                    </button>
                    <button class="btn btn-primary"
                        @click="SaveSection">
                        salvar
                    </button>
                </div>
            </div>
        </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import { required } from 'vuelidate/lib/validators'

import moment from 'moment'

export default {
    name: "SectionForm",
    props:  {
        value:  {
            type:       Object,
            default:    () => {}
        },
        mode:   {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            form: {
                descricao:          '',
                faq_category_id:    null,
                slug:               '',
            }
        }
    },
    validations: {
        form: {
            descricao: {
                required
            },
            faq_category_id: {
                required
            }
        }
    },
    computed: {
        ...mapGetters('system', [
            'all_categories'
        ]),
        slugged_description()
        {
            if(this.form.descricao)
            {
                return window.helpers.Slugify(this.form.descricao)
            }

            return ''
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val)
            {
                this.form = { ...val }
            }
        }
    },
    methods: {
        SaveSection()
        {
            this.$v.form.$touch()

            if(!this.$v.form.$error)
            {
                this.form.slug = this.slugged_description
                this.$emit('save', this.form)
            }
        },
        DeleteSection()
        {
            this.$emit('delete')
        }
    },
    beforeMount()
    {
        
    }
}
</script>

<style>

</style>