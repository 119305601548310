<template>
    <div>
        <transition name="fade" mode="out-in">
            <div class="col-12 d-flex justify-content-between">

                <div class="col-10 d-flex flex-column align-items-center">
                    <div class="form col-8 d-flex flex-column mt-5"
                        ref="form">
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="name" class="font-20 col-4">Nome:</label>
                                <input 
                                    type="text"
                                    id="name"
                                    ref="name"
                                    class="form-control col-8"
                                    :class="{ 'is-invalid': $v.coupon.name.$error }"
                                    v-model="$v.coupon.name.$model"
                                >
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="description" class="font-20 col-4">Descrição:</label>
                                <textarea 
                                    id="description"
                                    ref="description"
                                    rows="3"
                                    class="form-control col-8"
                                    v-model="coupon.description"
                                ></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="percentage" class="font-20 col-4">Desconto:</label>
                                <div class="input-group col-8 p-0">
                                    <div class="input-group-prepend p-0 d-flex">
                                        <button class="btn"
                                            :class="coupon.discount_type == 'percentage' ? 'btn-info' : 'btn-warning'"
                                                @click="coupon.discount_type = (coupon.discount_type == 'percentage' ? 'amount' : 'percentage')">
                                                <i class="fa"
                                                    :class="coupon.discount_type == 'percentage' ? 'fa-percentage' : 'fa-dollar-sign'"></i>
                                        </button>
                                    </div>
                                    <input 
                                        type="text"
                                        class="form-control col-12"
                                        maxlength="8"
                                        min="0"
                                        :class="{ 'is-invalid': $v.coupon.amount.$error }"
                                        v-if="coupon.discount_type == 'amount'"
                                        v-model="$v.coupon.amount.$model"
                                    >
                                    <input 
                                        type="text"
                                        class="form-control col-12"   
                                        maxlength="6"
                                        id="percentage"
                                        :class="{ 'is-invalid': $v.coupon.percentage.$error }"
                                        v-else
                                        v-model="$v.coupon.percentage.$model"
                                    >
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="code" class="font-20 col-4">Código de uso:</label>
                                <div class="input-group col-8 p-0">
                                    <div class="input-group-prepend p-0 d-flex">
                                        <button
                                            class="btn btn-light"
                                            @click="ParseRandomCode(8)"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                    </div>
                                    <input 
                                        type="text"
                                        id="code"
                                        ref="code"
                                        class="form-control col-12"
                                        v-model="$v.coupon.code.$model"
                                        :class="{'is-invalid': $v.coupon.code.$error}"
                                        v-mask="['XXXXXXXXXXXXXXXXXXXX']"
                                    >
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="only_user" class="font-20 col-4">Exclusividade:</label>
                                <select
                                    class="form-control" 
                                    name="only_user" 
                                    id="only_user"
                                    @change="OnlyUserSelect($event)"
                                    v-model="coupon.exclusive_user"
                                    :class="{ 'is-invalid': $v.coupon.only_user.$error }"
                                >
                                    <option :value="null" selected>Nenhum</option>
                                    <option 
                                        v-for="customer in customers"
                                        :key="customer.id"
                                        :value="customer.id"
                                    >
                                        (#{{ customer.id }}) {{ customer.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row" v-if="!coupon.only_user">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="max_usages" class="font-20 col-4">Limite de uso</label>
                                <input 
                                    type="number"
                                    id="max_usages"
                                    ref="max_usages"
                                    min="0"
                                    class="form-control col-4"
                                    v-model="coupon.max_usages"
                                > 
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="cycles" class="font-20 col-4">Ciclos</label>
                                <input 
                                    type="number"
                                    id="cycles"
                                    ref="cycles"
                                    min="0"
                                    class="form-control col-4"
                                    v-model="coupon.cycles"
                                > 
                            </div>
                        </div>
                        

                        <div class="row">
                            <div class="form-group m-2 d-flex align-items-center col-12">
                                <label for="due_at" class="font-20 col-4">Vencimento</label>
                                <input 
                                    type="datetime-local"
                                    id="due_at"
                                    ref="due_at"
                                    rows="3"
                                    class="form-control"
                                    v-model="$v.coupon.due_at.$model"
                                    :class="{ 'is-invalid': $v.coupon.due_at.$error }"
                                    :disabled="coupon.not_due"
                                > 
                                <input
                                    class="ml-2 d-flex" 
                                    type="checkbox" 
                                    name="dont_due" 
                                    id="dont_due"
                                    v-model="coupon.not_due"
                                    @click="ChangeNotDue($event)"
                                >
                                <label for="dont_due">Sem validade</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group mt-5 ml-3 mr-5 d-flex align-items-center col-12"
                                :class="deletable ? 'justify-content-between' : 'justify-content-end'">
                                <button
                                    v-if="deletable"
                                    class="btn btn-danger"
                                    @click="DeleteCoupon">deletar
                                </button>
                                <button
                                    class="btn btn-primary"
                                    @click="mode == 'edit' ? SaveCoupon('update') : SaveCoupon('save')"
                                >
                                {{ mode == "edit" ? 'atualizar' : 'cadastrar' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- COUPON INFO -->
                <div class="plan-example col-4">
                    <div class="card-plan mt-5">
                        <div class="card-plan-content">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="text-center text-uppercase">INFORMAÇÃO</h4>
                                </div>
                                <div class="col-12 px-4">
                                    <p class="text-lef">
                                        Um cupom submete descontos sob faturas dos clientes assim que usados, crie com cuidado.
                                    </p>
                                    
                                    <p class="text-lef">
                                        <strong>Desconto:</strong> É em quantidade ou porcentagem sob o valor total da fatura. Se o desconto for maior que a prória fatura ela será zerada<br>
                                        <strong>Código de uso:</strong> É usado pelo cliente no momento da assinatura <br>
                                        <strong>Exclusividade:</strong> Escolha uma organização que terá direito ao cupom <br>
                                        <strong>Limite de uso:</strong> Se nenhum exclusividade for feita, o cupom poderá ser usado {{ coupon.max_usages }} por qualquer organização <br>
                                        <strong>Ciclos:</strong> Quantidade de próximas faturas que o desconto estará presente. <br>
                                        <strong>Vencimento:</strong> Quando o cupom deixará de funcionar (data futura). Ou ele pode ser infinito
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import Swal from 'sweetalert2'

import moment from 'moment'

import { mapActions } from 'vuex'

import { required, decimal, minValue, maxValue, minLength, maxLength } from 'vuelidate/lib/validators'

const validFutureDate = (value) => {
    if (value == null) return true
    return moment(value).diff(moment(), 'minutes') > 0
} 

export default {
    name: 'Form',
    props:  {
        value:      {
            type:       Object,
            default:    () => {}
        },
        deletable:  {
            type:       Boolean,
            default:    false
        },
        mode:       {
            type:       String,
            default:    ''
        }
    },
    data() {
        return {
            coupon:   {

                name:           "",
                description:    "",
                code:           "",
                only_user:      null,
                max_usages:     1,
                cycles:         1,
                discount_type:  "percentage",
                percentage:     5.0,
                amount:         10,
                due_at:         moment().add(10, 'day').format('yyyy-MM-DDThh:mm'),
                not_due:        false,
                exclusive_user: null

            },
            customers: []
        }
    },
    validations: {
        coupon: 		{
            name: 		        {
                required
            },
            only_user:          {
                
            }, amount:          {
                required,
                decimal,
                minValue: minValue(0.1),
            }, percentage:      {
                required,
                decimal,
                minValue: minValue(0.1),
                maxValue: maxValue(100)
            }, code:            {
                required,
                maxLenght: maxLength(20),
                minLenght: minLength(3)
            }, due_at:          {
                validFutureDate
            }
		}
    },
    watch:  {
        value: {
            immediate: true,
            deep:      true,
            handler(val) {

                this.coupon             = { ...val }

                if ( val.due_at ) {
                    this.coupon.due_at  = moment(val.due_at).format('yyyy-MM-DDThh:mm')
                } else { 
                    this.coupon.not_due        = true
                }

                if ( ! val.max_usages ) this.coupon.max_usages = 0
                if ( ! val.cycles )     this.coupon.cycles = 0
                if ( ! val.only_user )  this.coupon.exclusive_user = null                
            
            } 
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async SaveCoupon(type)
        {
            this.$v.coupon.$touch()

            if(!this.$v.coupon.$error)
            {
                this.$emit(type, {
                    coupon: this.coupon
                })
            } else { 
                window.helpers.focusInvalid(this.$v.coupon, this)
            }
        },
        async DeleteCoupon()
        {
            this.$emit('delete', {
                    coupon: this.coupon
                })
        },
        async GetAllCustomers()
        {
            const vm = this

            vm.StartLoading()

            return window.api.call('get', '/get-all-organizations')
                .then(({data}) => {
                    if(data.response)
                    {
                        this.customers 	= data.accounts
                    }
                })
                .finally(() => {
                    this.FinishLoading()
                })
        },
        
        RemoveBenefit(index, fieldType) {
            fieldType.splice(index, 1);
        },
        ParsePrice(price)
        {
            return `${price}`.replace('.', ',')
        },
        ChangeNotDue(event) {
            if ( ! this.coupon.not_due ) { 
                this.coupon.due_at = null
            } else { 
                this.coupon.due_at = moment().add(10, 'day').format('yyyy-MM-DDThh:mm')
            }
        },
        OnlyUserSelect(event) {
            if ( this.coupon.exclusive_user != '') {
                this.coupon.only_user = this.coupon.exclusive_user
            } else { 
                this.coupon.only_user = null
            }
        },
        ParseRandomCode(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
            }
            this.coupon.code = result.join('')
        }
    },
    beforeMount() {
        this.coupon = { ...this.value }
        this.GetAllCustomers()
    }
}
</script>

<style lang="css" scoped>

textarea.description
{
    resize: none;
}

.new-benefit
{
    font-weight: 700;
}

a
{
    cursor: pointer;
}

a.trash-button.off
{
    display: none;
}

a.trash-button.on
{
    position: absolute;
    right: -1.5rem;
    top: 0.9rem;
}

i.fa.fa-times {
    font-size: 23px;
    font-weight: 400 !important;
    line-height: 1rem;
}

/* a.btn.btn-light.previous-button {
    position: absolute;
    top: -50px;
    left: -100px;
} */

</style>