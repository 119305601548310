<template>
    <div class="col-12">
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="col-12 d-flex justify-content-center">
                <h2 class="">Cadastrar um novo usuário</h2>
            </div>
            <UserForm 
                v-model="user"
                :mode="'new'"
                @save="UpdateUser"
            />
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import UserForm from './components/Form'
export default {
    name: 'NewUser',
    components: {
        UserForm
    },
    data () {
        return {
            user:   {
                name:           '',
                username:       '',
                password:       '',
                avatar:         '',
                change_password:    false
            }
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async UpdateUser(user)
        {
            this.user = user

            await this.SaveUser()
        },
        async SaveUser()
        {
            const vm = this

            vm.StartLoading()

            const formData = new FormData()

            Object.keys(vm.user).map((key, index) => {
                if(key != 'avatar' && vm.user[key])
                {
                    formData.append(key, vm.user[key])
                }
            })

            if(vm.user.avatar)
            {
                formData.append('avatar', vm.user.avatar)
            }

            return await window.api.call('post', '/save-user', formData)
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            icon:       'success',
                            title:      'Usuário salvo com sucesso!',
                            toast:      true,
                            position:   'top',
                            timer:      4000,
                            timerProgressBar: true
                        })

                        vm.$router.push({ name: 'Users' })

                        return
                    }

                    Swal.fire({
                        icon:       'error',
                        title:      'Erro ao salvar o usuário.',
                        toast:      true,
                        position:   'top',
                        showConfirmButton: false,
                        timer:      3000,
                        timerProgressBar: true
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        }
    }
}
</script>

<style>

</style>