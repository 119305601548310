<template>
    <div class="col-12 d-flex flex-column align-items-center">
        <div class="col-8 header d-flex flex-column align-items-center mt-5">
            <div class="form-group row col-6 d-flex align-items-center">
                <label for="name" class="col-3 font-18">Nome: </label>
                <div class="col-9 p-0">
                    <input 
                        type="text"
                        class="form-control col-12 py-3"
                        :class="{ 'is-invalid': $v.form.name.$error }"
                        v-model="$v.form.name.$model"
                        >
                    <div class="invalid-feedback">
                        Obrigatório
                    </div>
                </div>
            </div>
            <div class="form-group row col-6 d-flex align-items-center">
                <label for="name" class="col-3 font-18">CPF: </label>
                <div class="col-9 p-0">
                    <input 
                        type="text"
                        class="form-control col-12 py-3"
                        :class="{ 'is-invalid': $v.form.username.$error }"
                        v-model="$v.form.username.$model"
                        v-mask="['###.###.###-##']"
                        >
                    <div class="invalid-feedback">
                        Obrigatório
                    </div>
                </div>
            </div>
            <div class="form-group row col-6 d-flex align-items-center">
                <label for="name" class="col-3 font-18">{{ new_password ? 'Nova s' : 'S'}}enha: </label>
                <div class="col-9 p-0"  
                    v-if="new_password || mode === 'new'">
                    <input
                        type="password"
                        class="form-control col-12 py-3"
                        :class="{ 'is-invalid': $v.form.password.$error }"
                        v-model="$v.form.password.$model"
                        >
                    <div class="invalid-feedback">
                        Obrigatório e no mínimo 6 caractéres
                    </div>
                </div>
                <div class="col-9 p-0"
                    v-if="mode !== 'new'">
                    <a href="" class="text-primary"
                        v-if="!new_password"
                        @click.prevent="form.change_password = true">
                        <u>alterar senha</u>
                    </a>
                </div>
            </div>
            <div class="form-group row col-6 d-flex align-items-center">
                <label class="font-18 col-3" for="input-avatar">Avatar: </label>
                <button 
                    class="btn btn-light-primary mr-5"
                    @click="UploadAvatar"
                >
                    selecionar
                </button>
                <input 
                    type="file"
                    id="input-avatar"
                    accept=".jpg, .jpeg, .png"
                    @change="UploadAvatar"
                    hidden>
                <template v-if="shown_avatar">
                    <img 
                        id="img-avatar"
                        :src="shown_avatar" 
                        class="max-150 rounded-lg"
                    >
                </template>
            </div>
            <div class="form-group row col-6 d-flex my-5"
                :class="mode == 'edit' ? 'justify-content-between' : 'justify-content-end'">
                <button class="btn btn-danger"
                    v-if="mode == 'edit'"
                    @click="DeleteUser">
                    excluir
                </button>

                <button class="btn btn-primary"
                    @click="SaveUser">
                    salvar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'UserForm',
    props: {
        value:      {
            type:       Object,
            default:    () => {}
        },
        mode:       {
            type:       String,
            default:    ''
        }
    },
    data () {
        return {
            form: {
                name:               '',
                username:           '',
                password:           '',
                avatar:             '',
                change_password:    false
            },
        }
    },
    computed: {
        shown_avatar()
        {
            if(this.form.avatar)
            {
                if(this.form.avatar instanceof File)
                {
                    return URL.createObjectURL(this.form.avatar)
                }

                return this.form.avatar
            }

            return ''
        },
        new_password()
        {
            if(this.mode == 'edit' && this.form.change_password)
            {
                return true
            }
            
            return false
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            username: {
                required
            },
            password: {
                required: requiredIf(function(form) {
                    return this.new_password && form.change_password
                }),
                minLength: minLength(6)
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val)
            {
                this.form = { ...val }

                if(this.mode === 'new')
                {
                    // this.new_password = true
                }
            }
        }
    },
    methods: {
        SaveUser()
        {
            this.$v.form.$touch()

            if(!this.$v.form.$error)
            {
                this.$emit('save', this.form)
            }
        },
        DeleteUser()
        {
            this.$emit('delete')
        },
        UploadAvatar(event) 
        {
            if(event.target.files)
            {
                this.form.avatar 	= event.target.files[0]
            }

            document.querySelector('#input-avatar').click()

        }
    }

}
</script>

<style lang="css" scoped>
</style>