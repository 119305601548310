<template>
	<div>
		<div class="row"
			v-if="permission">
			<div class="col-12">
				<p class="text-center">
					Você pode convidar outros profissionais que utilizem o Instant para fazer parte desta organização.
				</p>
			</div>
			<div class="col-md">
				<div class="form-group">
					<input 
						type="tel"
						v-model="$v.invite_document.$model"
						v-mask="['###.###.###-##']"
						class="form-control"
						:class="{ 'is-invalid': $v.invite_document.$error }"
						placeholder="Convidar profissionais do Instant por CPF"
						ref="invite_document"
					>
					<div class="invalid-feedback" v-if="!$v.invite_document.required">
			          	Obrigatório
			        </div>
			        <div class="invalid-feedback" v-else-if="!$v.invite_document.CPFValidation">
			          	CPF Invalido
			        </div>
				</div>								
			</div>
			<div class="col-md-auto pl-md-0">
				<button 	
					class="btn btn-primary btn-block px-5"
					@click="SearchDocNumber"
				>
					Convidar
				</button>
			</div>
		</div>
		<template v-if="providers.length > 0">
			<div 
				class="border mb-2 p-4 mt-3 rounded-lg"
				v-for="provider in filtered_providers"
				:key="provider.id"
			>
				<div class="row justify-content-between align-items-center">
					<div class="col">
						<span 
							class="status-indicator"
							:class="{ 'online': provider.online }"
						>
							{{ provider.provider.name.toUpperCase() }}
						</span>
						
						<small class="d-block opacity-50 mt-3 mt-md-0">
							Template: {{ provider.workgroup_schema.toUpperCase() }}
						</small>
						<div v-html="GenerateProfiles(provider.provider)"/>
					</div>
					<div class="col-12 col-md-auto my-3 my-md-0">
						<div class="d-flex justify-content-between">
							
							
						</div>
					</div>
					<div class="col-12 col-md-auto">
						<div class="d-flex justify-content-between">
							<button
								v-if="permission"
								class="btn btn-light-warning btn-sm px-3 mr-3"
								:disabled="!provider.online"
								@click="ForceLogout(provider.account_provider_id)"
							>
								desconectar
							</button>
							<button 
								v-if="permission"
								class="btn btn-light-primary btn-sm px-3 mr-3"
								@click="OpenPermissions(provider.provider)"
							>
								permissões
							</button>
							<button 
								v-if="permission"
								class="btn btn-light-danger btn-sm px-3 mr-3">
								<i class="far fa-ban"></i>
							</button>
							<button 
								:content="`Ir até a conta de ${provider.provider.name}`" v-tippy="{ arrow: true }"
								class="btn btn-light-info btn-sm px-3"
								@click="Navigate(provider.provider.id)">
								<i class="far fa-chevron-right px-1"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		    <div 
		    	class="modal fade" 
		    	id="modal-profiles"
		    >
		        <div class="modal-dialog modal-dialog-centered">
		            <div class="modal-content">
		            	<div class="modal-header px-5 py-4">
				    		<h4 class="font-20 m-0">{{!invite_account ? 'Gerenciar' : 'Definir'}} permissões</h4>
				    	</div>
			            <div class="modal-body">
			                <div class="row p-4" v-if="selected_provider || invite_account">
			                	<div class="col-12">
			                		<h5 class="text-center">{{ selected_provider ? selected_provider.name : invite_account.name }}</h5>
			                	</div>
			                    <div 
			                    	class="col-12"
			                    	v-for="profile in profiles"
			                    	:key="profile.id"
			                    >
			                    	<div class="border rounded-lg py-3 px-4 mt-3">
			                    		<div class="form-row flex-nowrap">
			                    			<div class="col-9">
			                    				{{ profile.name }}
			                    			</div>
			                    			<div class="col-3">
			                    				<div class="d-inline-block form-group mr-4 mb-0 form-check">
													<input 
														type="checkbox" 
														:id="`switch${profile.id}`" 
														:checked="HasThisProfile(profile)"
														:value="profile.id"
														v-model="selected_profiles"
													>
													<label :for="`switch${profile.id}`">
														{{HasThisProfile(profile) ? 'ATIVO' : 'INATIVO'}}
													</label>
												</div>
			                    			</div>
			                    		</div>
			                    	</div>
			                    </div> 
			                </div>
			            	<div class="d-flex justify-content-between p-4">
				                <button
				                	type="button" 
				                	class="btn btn-light p-3 px-4" 
				                	@click="ClosePermissions" 
				                >	
				            		cancelar
				            	</button>
				            	<button 
				                	type="button" 
				                	class="btn btn-primary p-3 px-4" 
				                	@click="!invite_account ? SavePermission() : SetPermissions()" 
				                >	
				            		salvar
				            	</button>
				            </div>					  
			            </div>
		            </div>
		        </div>
		    </div>
		</template>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators'
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()
	
	const _ = require('lodash')

	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	const CPFValidation = (value, vm) => helpers.ValidaCPF(value)
	export default {

		name: 'Employees',
		props: {
			providers: 			{
				type: 				Array,
				default: 			() => []
			},
			organization_id: 	{
				type: 				[String, Number],
				default: 			null
			},
			permission:			{
				type:				Boolean,
				default:			false
			}
		},
		data () {
			return {
				invite_document: 	'',
				invite_account: 	null,
				selected_provider: 	null,
				profiles: 			[],
				selected_profiles: 	[]
			}
		},
		validations: {
			invite_document: 	{
				required,
				CPFValidation
			}
		},
		computed: {
			...mapGetters('auth', [
				'online_accounts'
			]),
			filtered_providers()
			{
				let providers = this.providers.map( p => {

					// p.online 		= false

					// let find_account 	= this.online_accounts.find( oa => oa.id == p.account_provider_id)

					// if(find_account)
					// {
					// 	p.online 		= true
					// }

					return p
				})

				providers = _.orderBy(providers, 'provider.name', 'asc')

				return providers.sort(x => x.online ? -1 : 1)
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			GenerateProfiles(data)
			{
				const profiles 	= data.provider_profiles
				let html 		= ''

				if(profiles.length > 0)
				{
					for(let i in profiles)
					{
						if(profiles[i].account_requester_id === parseInt(this.organization_id))
						{
							html += `<span class="badge badge-light mr-3">${profiles[i].profile.name}</span>`
						}
					}
				}
				return html
			},
			ForceLogout(account_id)
			{
				this.StartLoading()

				return window.api.call('post','/force-logout', {
						account_id: account_id
					})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
		  						title: 		'Usuário deslogado com sucesso.',
		  						toast: 		true,
		  						timer: 		3000,
		  						position: 	'top',
		  						icon: 		'success'
							})

						}else{

							Swal.fire({
		  						title: 	'Eita!',
		  						text: 	'Houve um erro ao deslogar este usuário, contate o suporte.',
		  						icon: 	'error'
							})
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			LoadProfiles()
			{
				this.StartLoading()

				return window.api.call('post', '/get-profiles', {
						account_id: 	this.organization_id,
						origin:			'erp'
					})
					.then(({data}) => {
						if(data.response)
						{
							this.profiles 	= data.profiles
						}
					})	
					.finally(() => {
						this.FinishLoading()
					})
			},
			SearchDocNumber()
	  		{
	  			const vm 		= this
	  			vm.$v.invite_document.$touch()

	  			if(!vm.$v.invite_document.$invalid)
	  			{
			  		this.$parent.StartLoading()

		  			window.api.call('post', '/verify-provider', {
			  				document_number: 		vm.invite_document,
			  				account_requester_id: 	vm.organization_id
				  		})
				  		.then(async ({data}) => {

				  			if(data.response)
				  			{
				  				vm.invite_account	= data.account

								await Swal.fire({
									title: 'Confirmar convite',
									text: `Deseja convidar ${vm.invite_account.name} a fazer parte desta organização ?`,
									icon: 'info',
									showCancelButton: true,
									confirmButtonColor: '#009e92',
									cancelButtonColor: '#d33',
									confirmButtonText: 'Enviar',
									cancelButtonText: 'Cancelar',
									showLoaderOnConfirm: true,
								  	allowOutsideClick: () => !Swal.isLoading()
								}).then((result) => {
									if(result.isConfirmed)
									{
										vm.OpenSetPermissions()
									}else{
										vm.invite_account = null
									}
								})

				  			}else{

			  					Swal.fire({
			  						title: 	'Opss...',
			  						text: 	'Este CPF não possui conta no Instant ou já esta cadastrado nesta organização.',
			  						icon: 	'error'
								})
				  				
				  			}
				  		})
				  		.finally(() => {
			  				this.$parent.FinishLoading()
			  			})
	  			} else {
					window.helpers.focusInvalid(this.$v.invite_document, this)
				}
		  	},
		  	HasThisProfile(profile)
		  	{
		  		const has 	= this.selected_profiles.find( sp => sp == profile.id )

		  		return has ? true : false
		  	},
		  	async OpenPermissions(provider)
		  	{
		  		this.selected_profiles 	= []

		  		if(provider.provider_profiles && provider.provider_profiles.length > 0)
		  		{
		  			provider.provider_profiles.forEach( profile => {
			  			if(profile.account_requester_id === parseInt(this.organization_id))
			  			{
			  				this.selected_profiles.push(profile.profile_id)
			  			}
			  		})		  		
		  		}		  		

		  		await this.LoadProfiles()

		  		this.selected_provider 	= provider

		  		window.jQuery('#modal-profiles').modal('show')
		  	},
		  	SavePermission()
		  	{	
		  		this.StartLoading()

		  		return window.api.call('post', '/manage-employee-profiles', {
		  				provider_id: 		this.selected_provider.id,
		  				account_id: 		this.organization_id,
		  				profiles: 			this.selected_profiles
		  			})
		  			.then(async ({data}) => {
		  				if(data.response)
		  				{
		  					Swal.fire({
		  						icon: 		'success',
		  						title: 		'Perfis do usuário alterados com sucesso',
		  						toast: 		true,
		  						position: 	'top',
		  						timer: 		3000
		  					})

		  					await Promise.all([
		  							this.$parent.GetAccountData()
		  						])
		  						.finally(() => {
		  							this.ClosePermissions()
		  						})
		  				}else{

		  					Swal.fire({
		  						icon: 	'error',
		  						title: 	'Opss...',
		  						text: 	'Houve um erro ao concluir, contate o suporte'
		  					})
		  				}
		  			})
		  			.finally(() => {
		  				this.FinishLoading()
		  			})
		  	},
		  	ClosePermissions()
		  	{
		  		this.selected_profiles 	= []
		  		this.selected_provider 	= null
		  		this.invite_account 	= null

		  		window.jQuery('#modal-profiles').modal('hide')
		  	},
			async SetPermissions()
			{
				const vm = this

				vm.StartLoading()

				return window.api.call('post','/new-workgroup',{
					account_provider_id: 	this.invite_account.id,
					account_requester_id: 	this.organization_id,
				})
				.then(async ({data}) => {

					if(data.response)
					{
						vm.selected_provider 	= data.workgroup.provider

						window.api.call('post', '/manage-employee-profiles', {
							provider_id: 		data.workgroup.account_provider_id,
							account_id: 		data.workgroup.account_requester_id,
							profiles: 			vm.selected_profiles
						})
						.then(async ({data}) => {
							if(data.response)
							{
								Swal.fire({ 
									title: 	'Convite enviado com sucesso',
									text: 	'Solicite o aceite deste ao colaborador.',
									icon: 	'success'
								})
								
								await Promise.all([
										this.$parent.GetAccountData()
									])
							}else{

								Swal.fire({
									icon: 	'error',
									title: 	'Opss...',
									text: 	'Houve um erro ao concluir, contate o suporte'
								})
							}

							vm.selected_provider 	= null
							vm.selected_profiles	= []
							vm.invite_account 		= null

							window.jQuery('#modal-profiles').modal('hide')
						})

						vm.$emit('new', data.workgroup)
					}
					
					return data
				})
				.finally(() => {
					vm.FinishLoading()
				})
			},
			async OpenSetPermissions()
			{
				await this.LoadProfiles()

				window.jQuery('#modal-profiles').modal('show')
			},
			Navigate(account_id)
			{
				this.$router.push({ name: 'ShowAccountProfile', params: {
					account_id
				}})
			}
		}
	}
</script>

<style lang="css" scoped>
	.status-indicator
	{
		margin: 0 15px;
	    font-size: 16px;
	    font-weight: 500;
	    text-transform: uppercase;
	    align-self: center;
	}

	.status-indicator:before
	{
	    content: '';
	    border: 1px solid #8e8e8e;
	    background-color: #8e8e8e;
	    position: absolute;
	    width: 15px;
	    height: 15px;
	    top: -5px;
	    left: 5px;
	    border-radius: 50%;
	}

	.status-indicator.online:before
	{
		border-color: #20c964;
	    background-color: #20c964;
	    box-shadow: 0px 0px 7px 2px #20c964;
	}
</style>