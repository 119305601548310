<template>
	<div></div>
</template>

<script>
	export default {

		name: 'Profile',

		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>